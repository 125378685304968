import './Hood.css';
import './HoodColor.css'

const Hood = ({ config, height="700px" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="100%" height={height} version="1.1" style={{ shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}}
		viewBox="0 0 20000 20000">
		<defs>
			<pattern id="Pattern" patternUnits="userSpaceOnUse" x="0" y="0" width="1000" height="1000" viewBox="0 0 800 800">
		     <polygon fill="#212121" points="0,0 1000,0 1000,1000 0,1000 "/>
		     <g>
		      <path fill="#4A4848" d="M890 934c-36,0 -72,0 -108,0 -18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33l2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0c17,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -15,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M890 667c-36,0 -72,0 -108,0 -18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33 36,0 72,0 108,0 17,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -15,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M890 400c-36,0 -72,0 -108,0 -18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33 36,0 72,0 108,0 17,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -15,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M890 134c-36,0 -72,0 -108,0 -18,0 -32,14 -32,33 0,0 0,0 0,0 0,18 14,32 32,32 36,0 72,0 108,0 17,0 32,-14 32,-32 0,0 0,0 0,0 0,-19 -15,-33 -32,-33z"/>
		     </g>
		     <g>
		      <path fill="#4A4848" d="M622 934c-36,0 -72,0 -108,0 -17,0 -32,15 -32,33 0,0 0,0 0,0 0,18 15,33 32,33l2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0c18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M622 667c-36,0 -72,0 -108,0 -17,0 -32,15 -32,33 0,0 0,0 0,0 0,18 15,33 32,33 36,0 72,0 108,0 18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M622 400c-36,0 -72,0 -108,0 -17,0 -32,15 -32,33 0,0 0,0 0,0 0,18 15,33 32,33 36,0 72,0 108,0 18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M622 134c-36,0 -72,0 -108,0 -17,0 -32,14 -32,33 0,0 0,0 0,0 0,18 15,32 32,32 36,0 72,0 108,0 18,0 32,-14 32,-32 0,0 0,0 0,0 0,-19 -14,-33 -32,-33z"/>
		     </g>
		     <g>
		      <path fill="#4A4848" d="M354 934c-35,0 -71,0 -107,0 -18,0 -33,15 -33,33 0,0 0,0 0,0 0,18 15,33 33,33l1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0c18,0 33,-15 33,-33 0,0 0,0 0,0 0,-18 -15,-33 -33,-33z"/>
		      <path fill="#4A4848" d="M354 667c-36,0 -71,0 -107,0 -18,0 -33,15 -33,33 0,0 0,0 0,0 0,18 15,33 33,33 36,0 71,0 107,0 18,0 33,-15 33,-33 0,0 0,0 0,0 0,-18 -15,-33 -33,-33z"/>
		      <path fill="#4A4848" d="M354 400c-36,0 -71,0 -107,0 -18,0 -33,15 -33,33 0,0 0,0 0,0 0,18 15,33 33,33 36,0 71,0 107,0 18,0 33,-15 33,-33 0,0 0,0 0,0 0,-18 -15,-33 -33,-33z"/>
		      <path fill="#4A4848" d="M354 134c-36,0 -71,0 -107,0 -18,0 -33,14 -33,33 0,0 0,0 0,0 0,18 15,32 33,32 36,0 71,0 107,0 18,0 33,-14 33,-32 0,0 0,0 0,0 0,-19 -15,-33 -33,-33z"/>
		     </g>
		     <g>
		      <path fill="#4A4848" d="M87 934l-87 0 0 66 1 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0c18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M87 667l-87 0 0 66 87 0c18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M87 400l-87 0 0 66 87 0c18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M87 134l-87 0 0 65 87 0c18,0 32,-14 32,-32 0,0 0,0 0,0 0,-19 -14,-33 -32,-33z"/>
		     </g>
		     <g>
		      <path fill="#4A4848" d="M1000 800l-84 0c-18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33l84 0 0 -66z"/>
		      <path fill="#4A4848" d="M1000 533l-84 0c-18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33l84 0 0 -66z"/>
		      <path fill="#4A4848" d="M1000 267l-84 0c-18,0 -32,14 -32,32 0,0 0,0 0,0 0,19 14,33 32,33l84 0 0 -65z"/>
		      <path fill="#4A4848" d="M1000 0l0 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0c-18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33l84 0 0 -66z"/>
		     </g>
		     <g>
		      <path fill="#4A4848" d="M756 800c-36,0 -72,0 -108,0 -18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33 36,0 72,0 108,0 18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M756 533c-36,0 -72,0 -108,0 -18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33 36,0 72,0 108,0 18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M756 267c-36,0 -72,0 -108,0 -18,0 -32,14 -32,32 0,0 0,0 0,0 0,19 14,33 32,33 36,0 72,0 108,0 18,0 32,-14 32,-33 0,0 0,0 0,0 0,-18 -14,-32 -32,-32z"/>
		      <path fill="#4A4848" d="M756 0l-2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0c-18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33 36,0 72,0 108,0 18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		     </g>
		     <g>
		      <path fill="#4A4848" d="M488 800c-36,0 -72,0 -107,0 -18,0 -33,15 -33,33 0,0 0,0 0,0 0,18 15,33 33,33 35,0 71,0 107,0 18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M488 533c-36,0 -72,0 -107,0 -18,0 -33,15 -33,33 0,0 0,0 0,0 0,18 15,33 33,33 35,0 71,0 107,0 18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M488 267c-36,0 -72,0 -107,0 -18,0 -33,14 -33,32 0,0 0,0 0,0 0,19 15,33 33,33 35,0 71,0 107,0 18,0 32,-14 32,-33 0,0 0,0 0,0 0,-18 -14,-32 -32,-32z"/>
		      <path fill="#4A4848" d="M488 0l-1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0c-18,0 -33,15 -33,33 0,0 0,0 0,0 0,18 15,33 33,33 35,0 71,0 107,0 18,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -14,-33 -32,-33z"/>
		     </g>
		     <g>
		      <path fill="#4A4848" d="M221 800c-36,0 -72,0 -108,0 -18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33 36,0 72,0 108,0 17,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -15,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M221 533c-36,0 -72,0 -108,0 -18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33 36,0 72,0 108,0 17,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -15,-33 -32,-33z"/>
		      <path fill="#4A4848" d="M221 267c-36,0 -72,0 -108,0 -18,0 -32,14 -32,32 0,0 0,0 0,0 0,19 14,33 32,33 36,0 72,0 108,0 17,0 32,-14 32,-33 0,0 0,0 0,0 0,-18 -15,-32 -32,-32z"/>
		      <path fill="#4A4848" d="M221 0l-2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0 -2 0 -1 0 -2 0c-18,0 -32,15 -32,33 0,0 0,0 0,0 0,18 14,33 32,33 36,0 72,0 108,0 17,0 32,-15 32,-33 0,0 0,0 0,0 0,-18 -15,-33 -32,-33z"/>
		     </g>
		     <g>
		      <g>
		       <path fill="#4A4848" d="M867 890c0,-36 0,-73 0,-109 0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33 0,36 0,73 0,109 0,19 14,33 32,33 0,0 0,0 0,0 18,0 32,-14 32,-33z"/>
		       <path fill="#4A4848" d="M600 890c0,-36 0,-73 0,-109 0,-18 -15,-33 -33,-33 0,0 0,0 0,0 -17,0 -32,15 -32,33 0,36 0,73 0,109 0,19 15,33 32,33 0,0 0,0 0,0 18,0 33,-14 33,-33z"/>
		       <path fill="#4A4848" d="M332 890c0,-36 0,-73 0,-109 0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33 0,36 0,73 0,109 0,19 14,33 32,33 0,0 0,0 0,0 18,0 32,-14 32,-33z"/>
		       <path fill="#4A4848" d="M64 891c0,-37 0,-74 0,-110 0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33l0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 2c0,18 14,32 32,32 0,0 0,0 0,0 18,0 32,-14 32,-32z"/>
		      </g>
		      <g>
		       <path fill="#4A4848" d="M867 624c0,-37 0,-73 0,-110 0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33 0,37 0,73 0,110 0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		       <path fill="#4A4848" d="M600 624c0,-37 0,-73 0,-110 0,-18 -15,-33 -33,-33 0,0 0,0 0,0 -17,0 -32,15 -32,33 0,37 0,73 0,110 0,18 15,33 32,33 0,0 0,0 0,0 18,0 33,-15 33,-33z"/>
		       <path fill="#4A4848" d="M332 624c0,-37 0,-73 0,-110 0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33 0,37 0,73 0,110 0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		       <path fill="#4A4848" d="M64 624c0,-37 0,-73 0,-110 0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33l0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2c0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		      </g>
		      <g>
		       <path fill="#4A4848" d="M867 356c0,-36 0,-73 0,-110 0,-18 -14,-32 -32,-32 0,0 0,0 0,0 -18,0 -32,14 -32,32 0,37 0,74 0,110 0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		       <path fill="#4A4848" d="M600 356c0,-36 0,-73 0,-110 0,-18 -15,-32 -33,-32 0,0 0,0 0,0 -17,0 -32,14 -32,32 0,37 0,74 0,110 0,18 15,33 32,33 0,0 0,0 0,0 18,0 33,-15 33,-33z"/>
		       <path fill="#4A4848" d="M332 356c0,-36 0,-73 0,-110 0,-18 -14,-32 -32,-32 0,0 0,0 0,0 -18,0 -32,14 -32,32 0,37 0,74 0,110 0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		       <path fill="#4A4848" d="M64 356c0,-36 0,-73 0,-110 0,-18 -14,-32 -32,-32 0,0 0,0 0,0 -18,0 -32,14 -32,32l0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2c0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		      </g>
		      <g>
		       <path fill="#4A4848" d="M867 90l0 -90 -64 0 0 90c0,18 14,32 32,32 0,0 0,0 0,0 18,0 32,-14 32,-32z"/>
		       <path fill="#4A4848" d="M600 90l0 -90 -65 0 0 90c0,18 15,32 32,32 0,0 0,0 0,0 18,0 33,-14 33,-32z"/>
		       <path fill="#4A4848" d="M332 90l0 -90 -64 0 0 90c0,18 14,32 32,32 0,0 0,0 0,0 18,0 32,-14 32,-32z"/>
		       <path fill="#4A4848" d="M64 90l0 -90 -64 0 0 0 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2 0 1 0 2 0 2 0 1 0 2 0 2 0 2c0,18 14,32 32,32 0,0 0,0 0,0 18,0 32,-14 32,-32z"/>
		      </g>
		     </g>
		     <g>
		      <g>
		       <path fill="#4A4848" d="M1000 906c-4,-14 -16,-24 -31,-24 0,0 0,0 0,0 -18,0 -32,15 -32,33l0 85 63 0 0 -94z"/>
		       <path fill="#4A4848" d="M734 1000l0 -85c0,-18 -15,-33 -33,-33 0,0 0,0 0,0 -17,0 -32,15 -32,33l0 85 65 0z"/>
		       <path fill="#4A4848" d="M466 1000l0 -85c0,-18 -15,-33 -32,-33 0,0 0,0 0,0 -18,0 -33,15 -33,33l0 85 65 0z"/>
		       <path fill="#4A4848" d="M198 1000l0 -85c0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33l0 85 64 0z"/>
		      </g>
		      <g>
		       <path fill="#4A4848" d="M1000 639c-4,-14 -16,-24 -31,-24 0,0 0,0 0,0 -18,0 -32,15 -32,33 0,37 0,73 0,110 0,18 14,33 32,33 0,0 0,0 0,0 15,0 27,-10 31,-24l0 -128z"/>
		       <path fill="#4A4848" d="M734 758c0,-37 0,-73 0,-110 0,-18 -15,-33 -33,-33 0,0 0,0 0,0 -17,0 -32,15 -32,33 0,37 0,73 0,110 0,18 15,33 32,33 0,0 0,0 0,0 18,0 33,-15 33,-33z"/>
		       <path fill="#4A4848" d="M466 758c0,-37 0,-73 0,-110 0,-18 -15,-33 -32,-33 0,0 0,0 0,0 -18,0 -33,15 -33,33 0,37 0,73 0,110 0,18 15,33 33,33 0,0 0,0 0,0 17,0 32,-15 32,-33z"/>
		       <path fill="#4A4848" d="M198 758c0,-37 0,-73 0,-110 0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33 0,37 0,73 0,110 0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		      </g>
		      <g>
		       <path fill="#4A4848" d="M1000 371c-4,-13 -16,-23 -31,-23 0,0 0,0 0,0 -18,0 -32,14 -32,33 0,36 0,73 0,109 0,18 14,33 32,33 0,0 0,0 0,0 15,0 27,-10 31,-23l0 -129z"/>
		       <path fill="#4A4848" d="M734 490c0,-36 0,-73 0,-109 0,-19 -15,-33 -33,-33 0,0 0,0 0,0 -17,0 -32,14 -32,33 0,36 0,73 0,109 0,18 15,33 32,33 0,0 0,0 0,0 18,0 33,-15 33,-33z"/>
		       <path fill="#4A4848" d="M466 490c0,-36 0,-73 0,-109 0,-19 -15,-33 -32,-33 0,0 0,0 0,0 -18,0 -33,14 -33,33 0,36 0,73 0,109 0,18 15,33 33,33 0,0 0,0 0,0 17,0 32,-15 32,-33z"/>
		       <path fill="#4A4848" d="M198 490c0,-36 0,-73 0,-109 0,-19 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,14 -32,33 0,36 0,73 0,109 0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		      </g>
		      <g>
		       <path fill="#4A4848" d="M1000 105c-4,-14 -16,-24 -31,-24 0,0 0,0 0,0 -18,0 -32,15 -32,33 0,37 0,73 0,110 0,18 14,33 32,33 0,0 0,0 0,0 15,0 27,-10 31,-24l0 -128z"/>
		       <path fill="#4A4848" d="M734 224c0,-37 0,-73 0,-110 0,-18 -15,-33 -33,-33 0,0 0,0 0,0 -17,0 -32,15 -32,33 0,37 0,73 0,110 0,18 15,33 32,33 0,0 0,0 0,0 18,0 33,-15 33,-33z"/>
		       <path fill="#4A4848" d="M466 224c0,-37 0,-73 0,-110 0,-18 -15,-33 -32,-33 0,0 0,0 0,0 -18,0 -33,15 -33,33 0,37 0,73 0,110 0,18 15,33 33,33 0,0 0,0 0,0 17,0 32,-15 32,-33z"/>
		       <path fill="#4A4848" d="M198 224c0,-37 0,-73 0,-110 0,-18 -14,-33 -32,-33 0,0 0,0 0,0 -18,0 -32,15 -32,33 0,37 0,73 0,110 0,18 14,33 32,33 0,0 0,0 0,0 18,0 32,-15 32,-33z"/>
		      </g>
		     </g>
		    </pattern>
		    <pattern id="Pattern1" patternUnits="userSpaceOnUse" x="0" y="0" width="10000" height="10000" viewBox="538 517 10000 10000">
			  <g>
			   <rect fill="#332C2B" x="538" y="517" width="10000" height="10000"/>
			   <path fill="#332C2B" d="M4614 2650c167,766 -1152,1293 -521,1628 631,336 572,988 -25,1051 -596,64 -1096,-213 -1119,418 -23,631 -892,1234 -1184,545 -293,-688 246,-845 694,-804 447,40 659,-342 430,-787 -230,-446 -327,-810 212,-891 539,-81 599,-243 352,-851 -246,-608 880,-1455 1161,-309z"/>
			   <path fill="#332C2B" d="M6446 8795c568,-410 992,-254 1308,-503 315,-249 195,-579 40,-752 -155,-174 -17,-353 -23,-440 -6,-87 -247,-180 -493,-29 -247,150 -143,351 -591,382 -1894,129 -808,1753 -241,1342z"/>
			   <path fill="#8C7D5C" d="M3000 1696c1126,-4 873,-1006 450,-1179l-1377 0c0,0 5,63 53,128 18,24 42,49 74,71 117,81 185,404 -191,499 -116,30 -213,45 -298,53 -189,18 -319,3 -477,40 -172,29 -762,313 -447,810 100,157 710,429 1352,-386 120,-153 533,-177 861,-36z"/>
			   <path fill="#8C7D5C" d="M4452 2279c-407,-542 -1211,157 -999,680 247,608 187,770 -352,851 -539,81 -442,445 -212,891 229,445 17,827 -430,787 -30,-3 -59,-5 -89,-5 -167,-5 -339,19 -469,90 -200,109 -302,329 -136,719 292,689 1161,86 1184,-545 23,-631 523,-354 1119,-418 597,-63 656,-715 25,-1051 -483,-257 177,-625 438,-1128 80,-154 122,-321 83,-500 -39,-161 -95,-282 -162,-371z"/>
			   <path fill="#7789A4" d="M6060 3219c0,0 359,465 4,818 112,-11 205,21 262,114 79,127 60,534 -244,761 0,0 17,813 -645,1021 156,-24 340,-77 557,-167 530,-222 917,-111 1127,85 -21,-253 136,-313 371,-394 -126,-219 -149,-572 361,-798 141,-62 195,-145 188,-242 -17,-257 -458,-614 -853,-950 -545,-463 -155,-868 -126,-955 29,-87 195,-440 -287,-550 -481,-110 -765,650 -765,650 159,170 198,412 50,607z"/>
			   <path fill="#8C7D5C" d="M6345 6571c-250,-104 -453,-295 -609,-342 -344,-105 -929,243 -528,659 34,35 61,69 83,102 792,510 1054,-419 1054,-419z"/>
			   <path fill="#8C7D5C" d="M5994 5766c530,-222 917,-111 1127,85 -21,-253 136,-313 371,-394 -126,-219 -149,-572 361,-798 141,-62 195,-145 188,-242 -17,-257 -458,-614 -853,-950 -545,-463 -155,-868 -126,-955 29,-87 195,-440 -287,-550 -481,-110 -765,650 -765,650 159,170 198,412 50,607 0,0 359,465 4,818 112,-11 205,21 262,114 79,127 60,534 -244,761 0,0 17,813 -645,1021 156,-24 340,-77 557,-167z"/>
			   <path fill="#8C7D5C" d="M1428 7322c122,-203 -80,-447 -80,-447 0,0 -333,-500 -810,-413l0 2630c433,-122 318,-540 198,-813 -8,-18 -16,-36 -23,-52 -127,-269 32,-437 91,-459 -107,40 456,-164 624,-446z"/>
			   <path fill="#8C7D5C" d="M9509 7244c-283,-3 -757,634 -418,819 464,-64 160,289 550,451 390,162 506,301 494,562 0,0 44,107 135,97 67,-71 164,-107 268,-81l0 -2630c-246,13 -421,165 -446,188 0,0 1,0 1,0 -115,106 -197,273 -202,360 -6,87 -98,237 -382,234z"/>
			   <path fill="#8C7D5C" d="M3450 10517c-160,-135 -452,-246 -744,-310 -169,-36 -339,-57 -483,-57 -396,0 -149,367 -149,367l1376 0z"/>
			   <path fill="#8C7D5C" d="M6158 10207c132,103 70,310 70,310l2063 0c-11,-121 77,-125 114,-157 107,-92 175,-184 102,-301 -35,-57 -104,-119 -218,-190 -131,81 -288,120 -508,60 -7,-1 -13,-3 -19,-5 0,0 -321,88 -447,218 -127,130 -293,29 -516,-69 -224,-99 -602,-10 -641,134z"/>
			   <path fill="#8C7D5C" d="M7503 1672c370,246 1135,-304 831,-619 -304,-315 -39,-536 -39,-536l-2067 0c0,0 47,158 -68,251 60,113 386,138 590,59 215,-82 518,-66 546,128 29,193 -163,471 207,717z"/>
			   <path fill="#584139" d="M2191 5342c-143,-6 -404,79 -440,-152 -36,-232 80,-282 545,-489 464,-207 77,-651 -190,-535 -266,115 -510,318 -779,202 -270,-116 -115,-445 -6,-544 -63,-133 -826,-104 -539,591 287,694 -244,792 -244,792l0 1255c477,-87 810,413 810,413 100,-110 107,-290 58,-400 -126,-290 12,-938 495,-902 130,-71 302,-95 469,-90 0,0 -36,-135 -179,-141z"/>
			   <path fill="#584139" d="M9954 5321c-416,-17 -826,789 -393,1131 337,-5 505,181 531,198 25,-23 200,-175 446,-188l0 -1254c0,0 -169,131 -584,113z"/>
			   <path fill="#584139" d="M6446 8795c-316,229 -793,-174 -826,-597 -680,60 -885,243 -1005,407 -368,581 -161,1030 -663,1256 -501,226 -1304,166 -1304,166 22,106 58,180 58,180 292,64 584,175 744,310l2778 0c0,0 62,-207 -70,-310 -52,-41 -134,-66 -262,-56 -453,35 -683,192 -712,-144 -29,-335 477,-336 666,-351 189,-15 1081,88 808,-993 -68,36 -139,79 -212,132z"/>
			   <path fill="#584139" d="M3000 1696c313,135 549,420 382,888 -75,209 -333,434 -677,214 -344,-219 -613,-503 -957,-277 -333,218 75,52 413,665 10,17 587,638 1259,-365 27,-40 4,47 0,0 -43,-496 660,-1038 1032,-542 542,32 805,-19 963,-167 158,-148 225,-391 56,-628 -169,-237 -562,-37 -582,-385 -20,-347 307,-387 433,-387 126,0 181,118 591,121 121,1 198,-26 247,-65 115,-93 68,-251 68,-251l-2778 0c423,173 676,1175 -450,1179z"/>
			   <path fill="#785F40" d="M4093 4278c631,336 572,988 -25,1051 -596,64 -1096,-213 -1119,418 -23,631 -892,1234 -1184,545 -166,-390 -64,-610 136,-719 -483,-36 -621,612 -495,902 49,110 42,290 -58,400 0,0 202,244 80,447 -168,282 -731,486 -624,446 263,-98 550,134 569,154 -1,-19 127,-252 377,-378 136,-68 488,-65 488,-65 0,-1 0,-1 0,-1 -280,-373 -292,-775 459,-711 401,34 568,539 744,992 153,394 314,747 643,715 708,-70 224,-470 -57,-671 -55,-39 -95,-97 -121,-163 -97,-247 23,-604 444,-450 46,17 95,40 147,69 613,340 1037,95 794,-269 -22,-33 -49,-67 -83,-102 -401,-416 184,-764 528,-659 156,47 359,238 609,342 64,26 131,47 200,59 259,42 507,34 704,-239 48,-66 62,-152 45,-242 -19,-102 -77,-209 -173,-298 -210,-196 -597,-307 -1127,-85 -217,90 -401,143 -557,167 -1019,159 -782,-915 29,-868 277,17 477,-49 616,-153 304,-227 323,-634 244,-761 -57,-93 -150,-125 -262,-114 -112,10 -243,62 -380,137 -275,151 -786,-474 -34,-700 197,-59 330,-150 410,-255 148,-195 109,-437 -50,-607 -80,-84 -189,-151 -320,-186 -534,-138 -1159,724 -1159,724 -261,503 -921,871 -438,1128z"/>
			   <path fill="#785F40" d="M1471 9540c-174,60 -258,-20 -440,-200 -10,-9 -20,-19 -30,-29 -203,-200 -463,-219 -463,-219l0 1425 1536 0c0,0 -247,-367 149,-367 144,0 314,21 483,57 0,0 -36,-74 -58,-180 -32,-156 -32,-383 162,-543 326,-268 812,12 984,-156 69,-68 79,-212 16,-361 -70,-164 -227,-335 -488,-420 -89,-29 -191,-47 -305,-52 -318,-13 -472,77 -541,193 -95,160 -26,367 7,418 58,86 -106,321 -341,228 -235,-92 -223,-691 -390,-709 -166,-17 -387,122 -260,310 126,188 163,541 -21,605z"/>
			   <path fill="#785F40" d="M7754 8292c-130,102 -279,136 -448,169 -130,358 96,485 260,554 108,-281 498,-226 559,-226 60,0 455,70 547,-167 88,-228 255,-489 419,-559 -339,-185 135,-822 418,-819 284,3 376,-147 382,-234 5,-87 87,-254 202,-360 0,0 -1,0 -1,0 -26,-17 -194,-203 -531,-198 -117,2 -254,26 -413,89 -622,246 -556,677 -493,810 63,133 49,229 -255,162 -304,-66 -507,175 -507,175 88,178 100,415 -139,604z"/>
			   <path fill="#785F40" d="M9233 9941c-602,-110 -709,73 -726,118 73,117 5,209 -102,301 -37,32 -125,36 -114,157l2247 0 0 -1425c-104,-26 -201,10 -268,81 -104,110 -135,306 -8,481 224,311 94,573 -106,623 -201,49 -241,-212 -923,-336z"/>
			   <path fill="#785F40" d="M8089 3571c341,62 778,-63 808,-440 43,-525 -28,-804 241,-937 270,-133 373,307 579,370 207,64 321,-115 339,-549 17,-434 482,-421 482,-421l0 -1077 -2243 0c0,0 -46,191 350,369 396,178 505,776 195,984 -310,208 -945,99 -1269,668 -262,459 87,955 518,1033z"/>
			   <path fill="#785F40" d="M1711 1268c115,-54 78,-169 49,-215 -29,-46 -281,-148 -158,-353 124,-205 524,-55 524,-55 -48,-65 -53,-128 -53,-128l-1535 0 0 1078c209,-353 507,-321 696,-287 158,-37 288,-22 477,-40z"/>
			   <path fill="#D6BE87" d="M8089 3571c-431,-78 -780,-574 -518,-1033 324,-569 959,-460 1269,-668 310,-208 201,-806 -195,-984 -396,-178 -350,-369 -350,-369 0,0 -265,221 39,536 304,315 -461,865 -831,619 -370,-246 -178,-524 -207,-717 -28,-194 -331,-210 -546,-128 -204,79 -530,54 -590,-59 -49,39 -126,66 -247,65 -410,-3 -465,-121 -591,-121 -126,0 -453,40 -433,387 20,348 413,148 582,385 169,237 102,480 -56,628 -158,148 -421,199 -963,167 67,89 123,210 162,371 39,179 -3,346 -83,500 0,0 625,-862 1159,-724 131,35 240,102 320,186 0,0 284,-760 765,-650 482,110 316,463 287,550 -29,87 -419,492 126,955 199,169 350,312 486,417 18,14 -43,-357 415,-313 19,2 19,3 0,0z"/>
			   <path fill="#D6BE87" d="M9154 3365c-235,521 208,710 -44,1011 -253,301 -733,255 -1069,41 7,97 -47,180 -188,242 -510,226 -487,579 -361,798 333,492 1016,500 1268,245 253,-254 39,-613 154,-786 115,-174 581,-382 919,-46 339,336 541,409 705,338l0 -3614c0,0 -253,105 -276,499 -23,393 268,795 -53,1032 -321,238 -820,-280 -1055,240z"/>
			   <path fill="#D6BE87" d="M7771 7100c-6,-87 -247,-180 -493,-29 -247,150 -143,351 -591,382 -271,18 -481,67 -640,136 -334,146 -445,380 -427,609 33,423 510,826 826,597 73,-53 144,-96 212,-132 244,-129 459,-165 648,-202 169,-33 318,-67 448,-169 239,-189 227,-426 139,-604 -27,-56 -62,-107 -99,-148 -155,-174 -17,-353 -23,-440z"/>
			   <path fill="#D6BE87" d="M9091 8063c-164,70 -331,331 -419,559 -92,237 -487,167 -547,167 -61,0 -451,-55 -559,226 0,0 -354,742 196,909l19 5c220,60 377,21 508,-60 212,-130 353,-372 572,-486 356,-186 614,-180 780,-151 166,29 483,104 494,-156 12,-261 -104,-400 -494,-562 -390,-162 -86,-515 -550,-451z"/>
			   <path fill="#D6BE87" d="M1682 4002c252,23 857,-63 513,-752 -11,-22 -22,-44 -34,-64 -338,-613 -746,-447 -413,-665 344,-226 613,58 957,277 344,220 602,-5 677,-214 167,-468 -69,-753 -382,-888 -328,-141 -741,-117 -861,36 -642,815 -1252,543 -1352,386 -315,-497 275,-781 447,-810 -189,-34 -487,-66 -696,287l0 3612c0,0 531,-98 244,-792 -287,-695 476,-724 539,-591 0,0 108,155 361,178z"/>
			   <path fill="#D6BE87" d="M1373 7922c1,1 1,2 0,1 0,0 0,0 0,-1zm865 -443c0,0 -352,-3 -488,65 -250,126 -378,359 -377,378 0,0 -87,287 -360,215 -272,-72 -277,140 -277,142 120,273 235,691 -198,813 0,0 260,19 463,219 10,10 20,20 30,29 0,0 -193,-643 455,-823 648,-179 859,57 990,171 69,-116 223,-206 541,-193 114,5 216,23 305,52 -230,-292 -190,-493 -97,-619 -466,-496 -948,-441 -987,-450l0 1z"/>
			   <path fill="#D6BE87" d="M5650 3474c-752,226 -241,851 34,700 137,-75 268,-127 380,-137 355,-353 -4,-818 -4,-818 -80,105 -213,196 -410,255z"/>
			   <path fill="#AB8C5D" d="M2009 1215c376,-95 308,-418 191,-499 -32,-22 -56,-47 -74,-71 0,0 -400,-150 -524,55 -123,205 129,307 158,353 29,46 66,161 -49,215 85,-8 182,-23 298,-53z"/>
			   <path fill="#AB8C5D" d="M3413 2832c-509,751 -955,579 -1149,443 -64,-44 -100,-85 -103,-89 12,20 23,42 34,64 344,689 -261,775 -513,752 -253,-23 -361,-178 -361,-178 -109,99 -264,428 6,544 269,116 513,-87 779,-202 267,-116 654,328 190,535 -465,207 -581,257 -545,489 36,231 297,146 440,152 143,6 179,141 179,141 30,0 59,2 89,5 447,40 659,-342 430,-787 -230,-446 -327,-810 212,-891 539,-81 599,-243 352,-851 -18,-45 -29,-91 -33,-138 -2,4 -5,7 -7,11z"/>
			   <path fill="#AB8C5D" d="M5466 5065c-811,-47 -1048,1027 -29,868 662,-208 645,-1021 645,-1021 -139,104 -339,170 -616,153z"/>
			   <path fill="#AB8C5D" d="M1373 7922c-19,-20 -306,-252 -569,-154 -59,22 -218,190 -91,459 7,16 15,34 23,52 0,-2 5,-214 277,-142 273,72 360,-215 360,-215z"/>
			   <path fill="#AB8C5D" d="M2476 8688c-131,-114 -342,-350 -990,-171 -648,180 -455,823 -455,823 182,180 266,260 440,200 184,-64 147,-417 21,-605 -127,-188 94,-327 260,-310 167,18 155,617 390,709 235,93 399,-142 341,-228 -33,-51 -102,-258 -7,-418z"/>
			   <path fill="#AB8C5D" d="M3810 8967c63,149 53,293 -16,361 -172,168 -658,-112 -984,156 -194,160 -194,387 -162,543 0,0 803,60 1304,-166 502,-226 295,-675 663,-1256 -25,35 -47,69 -68,101 -97,142 -334,451 -737,261z"/>
			   <path fill="#AB8C5D" d="M3225 7928c85,-117 215,-170 216,-169 -176,-453 -343,-958 -744,-992 -751,-64 -739,338 -459,711 39,9 521,-46 987,450z"/>
			   <path fill="#AB8C5D" d="M4350 7190c-421,-154 -541,203 -444,450 60,52 250,33 267,-127 17,-159 177,-323 177,-323z"/>
			   <path fill="#AB8C5D" d="M6658 8663c273,1081 -619,978 -808,993 -189,15 -695,16 -666,351 29,336 259,179 712,144 128,-10 210,15 262,56 39,-144 417,-233 641,-134 223,98 389,199 516,69 126,-130 447,-218 447,-218 -550,-167 -196,-909 -196,-909 -164,-69 -390,-196 -260,-554 -189,37 -404,73 -648,202z"/>
			   <path fill="#AB8C5D" d="M8861 9383c-219,114 -360,356 -572,486 114,71 183,133 218,190 17,-45 124,-228 726,-118 682,124 722,385 923,336 200,-50 330,-312 106,-623 -127,-175 -96,-371 8,-481 -91,10 -135,-97 -135,-97 -11,260 -328,185 -494,156 -166,-29 -424,-35 -780,151z"/>
			   <path fill="#AB8C5D" d="M8655 7351c-63,-133 -129,-564 493,-810 159,-63 296,-87 413,-89 -433,-342 -23,-1148 393,-1131 415,18 584,-113 584,-113 -164,71 -366,-2 -705,-338 -338,-336 -804,-128 -919,46 -115,173 99,532 -154,786 -252,255 -935,247 -1268,-245 -235,81 -392,141 -371,394 96,89 154,196 173,298 0,0 437,303 78,644 -358,341 -598,146 -893,317 -295,171 -489,335 -432,479 159,-69 369,-118 640,-136 448,-31 344,-232 591,-382 246,-151 487,-58 493,29 6,87 -132,266 23,440 37,41 72,92 99,148 0,0 203,-241 507,-175 304,67 318,-29 255,-162z"/>
			   <path fill="#AB8C5D" d="M8041 4417c336,214 816,260 1069,-41 252,-301 -191,-490 44,-1011 235,-520 734,-2 1055,-240 321,-237 30,-639 53,-1032 23,-394 276,-499 276,-499 0,0 -465,-13 -482,421 -18,434 -132,613 -339,549 -206,-63 -309,-503 -579,-370 -269,133 -198,412 -241,937 -30,377 -467,502 -808,440 -417,-40 -403,263 -411,308 -1,4 -2,6 -4,5 -17,-13 -35,-27 -52,-42 229,211 408,413 419,575z"/>
			   <path fill="#827351" d="M6345 6571c0,0 -262,929 -1054,419 243,364 -181,609 -794,269 -52,-29 -101,-52 -147,-69 0,0 -160,164 -177,323 -17,160 -207,179 -267,127 26,66 66,124 121,163 281,201 765,601 57,671 -329,32 -490,-321 -643,-715 -1,-1 -131,52 -216,169 -93,126 -133,327 97,619 261,85 418,256 488,420 403,190 640,-119 737,-261 21,-32 43,-66 68,-101 120,-164 325,-347 1005,-407 -18,-229 93,-463 427,-609 -57,-144 137,-308 432,-479 295,-171 535,24 893,-317 359,-341 -78,-644 -78,-644 17,90 3,176 -45,242 -197,273 -445,281 -704,239 -69,-12 -136,-33 -200,-59z"/>
			   <rect fill="#332C2B" x="538" y="517" width="10000" height="10000"/>
			   <path fill="#332C2B" d="M4614 2650c167,766 -1152,1293 -521,1628 631,336 572,988 -25,1051 -596,64 -1096,-213 -1119,418 -23,631 -892,1234 -1184,545 -293,-688 246,-845 694,-804 447,40 659,-342 430,-787 -230,-446 -327,-810 212,-891 539,-81 599,-243 352,-851 -246,-608 880,-1455 1161,-309z"/>
			   <path fill="#332C2B" d="M6446 8795c568,-410 992,-254 1308,-503 315,-249 195,-579 40,-752 -155,-174 -17,-353 -23,-440 -6,-87 -247,-180 -493,-29 -247,150 -143,351 -591,382 -1894,129 -808,1753 -241,1342z"/>
			   <path fill="#8C7D5C" d="M3000 1696c1126,-4 873,-1006 450,-1179l-1377 0c0,0 5,63 53,128 18,24 42,49 74,71 117,81 185,404 -191,499 -116,30 -213,45 -298,53 -189,18 -319,3 -477,40 -172,29 -762,313 -447,810 100,157 710,429 1352,-386 120,-153 533,-177 861,-36z"/>
			   <path fill="#8C7D5C" d="M4452 2279c-407,-542 -1211,157 -999,680 247,608 187,770 -352,851 -539,81 -442,445 -212,891 229,445 17,827 -430,787 -30,-3 -59,-5 -89,-5 -167,-5 -339,19 -469,90 -200,109 -302,329 -136,719 292,689 1161,86 1184,-545 23,-631 523,-354 1119,-418 597,-63 656,-715 25,-1051 -483,-257 177,-625 438,-1128 80,-154 122,-321 83,-500 -39,-161 -95,-282 -162,-371z"/>
			   <path fill="#7789A4" d="M6060 3219c0,0 359,465 4,818 112,-11 205,21 262,114 79,127 60,534 -244,761 0,0 17,813 -645,1021 156,-24 340,-77 557,-167 530,-222 917,-111 1127,85 -21,-253 136,-313 371,-394 -126,-219 -149,-572 361,-798 141,-62 195,-145 188,-242 -17,-257 -458,-614 -853,-950 -545,-463 -155,-868 -126,-955 29,-87 195,-440 -287,-550 -481,-110 -765,650 -765,650 159,170 198,412 50,607z"/>
			   <path fill="#8C7D5C" d="M6345 6571c-250,-104 -453,-295 -609,-342 -344,-105 -929,243 -528,659 34,35 61,69 83,102 792,510 1054,-419 1054,-419z"/>
			   <path fill="#8C7D5C" d="M5994 5766c530,-222 917,-111 1127,85 -21,-253 136,-313 371,-394 -126,-219 -149,-572 361,-798 141,-62 195,-145 188,-242 -17,-257 -458,-614 -853,-950 -545,-463 -155,-868 -126,-955 29,-87 195,-440 -287,-550 -481,-110 -765,650 -765,650 159,170 198,412 50,607 0,0 359,465 4,818 112,-11 205,21 262,114 79,127 60,534 -244,761 0,0 17,813 -645,1021 156,-24 340,-77 557,-167z"/>
			   <path fill="#8C7D5C" d="M1428 7322c122,-203 -80,-447 -80,-447 0,0 -333,-500 -810,-413l0 2630c433,-122 318,-540 198,-813 -8,-18 -16,-36 -23,-52 -127,-269 32,-437 91,-459 -107,40 456,-164 624,-446z"/>
			   <path fill="#8C7D5C" d="M9509 7244c-283,-3 -757,634 -418,819 464,-64 160,289 550,451 390,162 506,301 494,562 0,0 44,107 135,97 67,-71 164,-107 268,-81l0 -2630c-246,13 -421,165 -446,188 0,0 1,0 1,0 -115,106 -197,273 -202,360 -6,87 -98,237 -382,234z"/>
			   <path fill="#8C7D5C" d="M3450 10517c-160,-135 -452,-246 -744,-310 -169,-36 -339,-57 -483,-57 -396,0 -149,367 -149,367l1376 0z"/>
			   <path fill="#8C7D5C" d="M6158 10207c132,103 70,310 70,310l2063 0c-11,-121 77,-125 114,-157 107,-92 175,-184 102,-301 -35,-57 -104,-119 -218,-190 -131,81 -288,120 -508,60 -7,-1 -13,-3 -19,-5 0,0 -321,88 -447,218 -127,130 -293,29 -516,-69 -224,-99 -602,-10 -641,134z"/>
			   <path fill="#8C7D5C" d="M7503 1672c370,246 1135,-304 831,-619 -304,-315 -39,-536 -39,-536l-2067 0c0,0 47,158 -68,251 60,113 386,138 590,59 215,-82 518,-66 546,128 29,193 -163,471 207,717z"/>
			   <path fill="#584139" d="M2191 5342c-143,-6 -404,79 -440,-152 -36,-232 80,-282 545,-489 464,-207 77,-651 -190,-535 -266,115 -510,318 -779,202 -270,-116 -115,-445 -6,-544 -63,-133 -826,-104 -539,591 287,694 -244,792 -244,792l0 1255c477,-87 810,413 810,413 100,-110 107,-290 58,-400 -126,-290 12,-938 495,-902 130,-71 302,-95 469,-90 0,0 -36,-135 -179,-141z"/>
			   <path fill="#584139" d="M9954 5321c-416,-17 -826,789 -393,1131 337,-5 505,181 531,198 25,-23 200,-175 446,-188l0 -1254c0,0 -169,131 -584,113z"/>
			   <path fill="#584139" d="M6446 8795c-316,229 -793,-174 -826,-597 -680,60 -885,243 -1005,407 -368,581 -161,1030 -663,1256 -501,226 -1304,166 -1304,166 22,106 58,180 58,180 292,64 584,175 744,310l2778 0c0,0 62,-207 -70,-310 -52,-41 -134,-66 -262,-56 -453,35 -683,192 -712,-144 -29,-335 477,-336 666,-351 189,-15 1081,88 808,-993 -68,36 -139,79 -212,132z"/>
			   <path fill="#584139" d="M3000 1696c313,135 549,420 382,888 -75,209 -333,434 -677,214 -344,-219 -613,-503 -957,-277 -333,218 75,52 413,665 10,17 587,638 1259,-365 27,-40 4,47 0,0 -43,-496 660,-1038 1032,-542 542,32 805,-19 963,-167 158,-148 225,-391 56,-628 -169,-237 -562,-37 -582,-385 -20,-347 307,-387 433,-387 126,0 181,118 591,121 121,1 198,-26 247,-65 115,-93 68,-251 68,-251l-2778 0c423,173 676,1175 -450,1179z"/>
			   <path fill="#785F40" d="M4093 4278c631,336 572,988 -25,1051 -596,64 -1096,-213 -1119,418 -23,631 -892,1234 -1184,545 -166,-390 -64,-610 136,-719 -483,-36 -621,612 -495,902 49,110 42,290 -58,400 0,0 202,244 80,447 -168,282 -731,486 -624,446 263,-98 550,134 569,154 -1,-19 127,-252 377,-378 136,-68 488,-65 488,-65 0,-1 0,-1 0,-1 -280,-373 -292,-775 459,-711 401,34 568,539 744,992 153,394 314,747 643,715 708,-70 224,-470 -57,-671 -55,-39 -95,-97 -121,-163 -97,-247 23,-604 444,-450 46,17 95,40 147,69 613,340 1037,95 794,-269 -22,-33 -49,-67 -83,-102 -401,-416 184,-764 528,-659 156,47 359,238 609,342 64,26 131,47 200,59 259,42 507,34 704,-239 48,-66 62,-152 45,-242 -19,-102 -77,-209 -173,-298 -210,-196 -597,-307 -1127,-85 -217,90 -401,143 -557,167 -1019,159 -782,-915 29,-868 277,17 477,-49 616,-153 304,-227 323,-634 244,-761 -57,-93 -150,-125 -262,-114 -112,10 -243,62 -380,137 -275,151 -786,-474 -34,-700 197,-59 330,-150 410,-255 148,-195 109,-437 -50,-607 -80,-84 -189,-151 -320,-186 -534,-138 -1159,724 -1159,724 -261,503 -921,871 -438,1128z"/>
			   <path fill="#785F40" d="M1471 9540c-174,60 -258,-20 -440,-200 -10,-9 -20,-19 -30,-29 -203,-200 -463,-219 -463,-219l0 1425 1536 0c0,0 -247,-367 149,-367 144,0 314,21 483,57 0,0 -36,-74 -58,-180 -32,-156 -32,-383 162,-543 326,-268 812,12 984,-156 69,-68 79,-212 16,-361 -70,-164 -227,-335 -488,-420 -89,-29 -191,-47 -305,-52 -318,-13 -472,77 -541,193 -95,160 -26,367 7,418 58,86 -106,321 -341,228 -235,-92 -223,-691 -390,-709 -166,-17 -387,122 -260,310 126,188 163,541 -21,605z"/>
			   <path fill="#785F40" d="M7754 8292c-130,102 -279,136 -448,169 -130,358 96,485 260,554 108,-281 498,-226 559,-226 60,0 455,70 547,-167 88,-228 255,-489 419,-559 -339,-185 135,-822 418,-819 284,3 376,-147 382,-234 5,-87 87,-254 202,-360 0,0 -1,0 -1,0 -26,-17 -194,-203 -531,-198 -117,2 -254,26 -413,89 -622,246 -556,677 -493,810 63,133 49,229 -255,162 -304,-66 -507,175 -507,175 88,178 100,415 -139,604z"/>
			   <path fill="#785F40" d="M9233 9941c-602,-110 -709,73 -726,118 73,117 5,209 -102,301 -37,32 -125,36 -114,157l2247 0 0 -1425c-104,-26 -201,10 -268,81 -104,110 -135,306 -8,481 224,311 94,573 -106,623 -201,49 -241,-212 -923,-336z"/>
			   <path fill="#785F40" d="M8089 3571c341,62 778,-63 808,-440 43,-525 -28,-804 241,-937 270,-133 373,307 579,370 207,64 321,-115 339,-549 17,-434 482,-421 482,-421l0 -1077 -2243 0c0,0 -46,191 350,369 396,178 505,776 195,984 -310,208 -945,99 -1269,668 -262,459 87,955 518,1033z"/>
			   <path fill="#785F40" d="M1711 1268c115,-54 78,-169 49,-215 -29,-46 -281,-148 -158,-353 124,-205 524,-55 524,-55 -48,-65 -53,-128 -53,-128l-1535 0 0 1078c209,-353 507,-321 696,-287 158,-37 288,-22 477,-40z"/>
			   <path fill="#D6BE87" d="M8089 3571c-431,-78 -780,-574 -518,-1033 324,-569 959,-460 1269,-668 310,-208 201,-806 -195,-984 -396,-178 -350,-369 -350,-369 0,0 -265,221 39,536 304,315 -461,865 -831,619 -370,-246 -178,-524 -207,-717 -28,-194 -331,-210 -546,-128 -204,79 -530,54 -590,-59 -49,39 -126,66 -247,65 -410,-3 -465,-121 -591,-121 -126,0 -453,40 -433,387 20,348 413,148 582,385 169,237 102,480 -56,628 -158,148 -421,199 -963,167 67,89 123,210 162,371 39,179 -3,346 -83,500 0,0 625,-862 1159,-724 131,35 240,102 320,186 0,0 284,-760 765,-650 482,110 316,463 287,550 -29,87 -419,492 126,955 199,169 350,312 486,417 18,14 -43,-357 415,-313 19,2 19,3 0,0z"/>
			   <path fill="#D6BE87" d="M9154 3365c-235,521 208,710 -44,1011 -253,301 -733,255 -1069,41 7,97 -47,180 -188,242 -510,226 -487,579 -361,798 333,492 1016,500 1268,245 253,-254 39,-613 154,-786 115,-174 581,-382 919,-46 339,336 541,409 705,338l0 -3614c0,0 -253,105 -276,499 -23,393 268,795 -53,1032 -321,238 -820,-280 -1055,240z"/>
			   <path fill="#D6BE87" d="M7771 7100c-6,-87 -247,-180 -493,-29 -247,150 -143,351 -591,382 -271,18 -481,67 -640,136 -334,146 -445,380 -427,609 33,423 510,826 826,597 73,-53 144,-96 212,-132 244,-129 459,-165 648,-202 169,-33 318,-67 448,-169 239,-189 227,-426 139,-604 -27,-56 -62,-107 -99,-148 -155,-174 -17,-353 -23,-440z"/>
			   <path fill="#D6BE87" d="M9091 8063c-164,70 -331,331 -419,559 -92,237 -487,167 -547,167 -61,0 -451,-55 -559,226 0,0 -354,742 196,909l19 5c220,60 377,21 508,-60 212,-130 353,-372 572,-486 356,-186 614,-180 780,-151 166,29 483,104 494,-156 12,-261 -104,-400 -494,-562 -390,-162 -86,-515 -550,-451z"/>
			   <path fill="#D6BE87" d="M1682 4002c252,23 857,-63 513,-752 -11,-22 -22,-44 -34,-64 -338,-613 -746,-447 -413,-665 344,-226 613,58 957,277 344,220 602,-5 677,-214 167,-468 -69,-753 -382,-888 -328,-141 -741,-117 -861,36 -642,815 -1252,543 -1352,386 -315,-497 275,-781 447,-810 -189,-34 -487,-66 -696,287l0 3612c0,0 531,-98 244,-792 -287,-695 476,-724 539,-591 0,0 108,155 361,178z"/>
			   <path fill="#D6BE87" d="M1373 7922c1,1 1,2 0,1 0,0 0,0 0,-1zm865 -443c0,0 -352,-3 -488,65 -250,126 -378,359 -377,378 0,0 -87,287 -360,215 -272,-72 -277,140 -277,142 120,273 235,691 -198,813 0,0 260,19 463,219 10,10 20,20 30,29 0,0 -193,-643 455,-823 648,-179 859,57 990,171 69,-116 223,-206 541,-193 114,5 216,23 305,52 -230,-292 -190,-493 -97,-619 -466,-496 -948,-441 -987,-450l0 1z"/>
			   <path fill="#D6BE87" d="M5650 3474c-752,226 -241,851 34,700 137,-75 268,-127 380,-137 355,-353 -4,-818 -4,-818 -80,105 -213,196 -410,255z"/>
			   <path fill="#AB8C5D" d="M2009 1215c376,-95 308,-418 191,-499 -32,-22 -56,-47 -74,-71 0,0 -400,-150 -524,55 -123,205 129,307 158,353 29,46 66,161 -49,215 85,-8 182,-23 298,-53z"/>
			   <path fill="#AB8C5D" d="M3413 2832c-509,751 -955,579 -1149,443 -64,-44 -100,-85 -103,-89 12,20 23,42 34,64 344,689 -261,775 -513,752 -253,-23 -361,-178 -361,-178 -109,99 -264,428 6,544 269,116 513,-87 779,-202 267,-116 654,328 190,535 -465,207 -581,257 -545,489 36,231 297,146 440,152 143,6 179,141 179,141 30,0 59,2 89,5 447,40 659,-342 430,-787 -230,-446 -327,-810 212,-891 539,-81 599,-243 352,-851 -18,-45 -29,-91 -33,-138 -2,4 -5,7 -7,11z"/>
			   <path fill="#AB8C5D" d="M5466 5065c-811,-47 -1048,1027 -29,868 662,-208 645,-1021 645,-1021 -139,104 -339,170 -616,153z"/>
			   <path fill="#AB8C5D" d="M1373 7922c-19,-20 -306,-252 -569,-154 -59,22 -218,190 -91,459 7,16 15,34 23,52 0,-2 5,-214 277,-142 273,72 360,-215 360,-215z"/>
			   <path fill="#AB8C5D" d="M2476 8688c-131,-114 -342,-350 -990,-171 -648,180 -455,823 -455,823 182,180 266,260 440,200 184,-64 147,-417 21,-605 -127,-188 94,-327 260,-310 167,18 155,617 390,709 235,93 399,-142 341,-228 -33,-51 -102,-258 -7,-418z"/>
			   <path fill="#AB8C5D" d="M3810 8967c63,149 53,293 -16,361 -172,168 -658,-112 -984,156 -194,160 -194,387 -162,543 0,0 803,60 1304,-166 502,-226 295,-675 663,-1256 -25,35 -47,69 -68,101 -97,142 -334,451 -737,261z"/>
			   <path fill="#AB8C5D" d="M3225 7928c85,-117 215,-170 216,-169 -176,-453 -343,-958 -744,-992 -751,-64 -739,338 -459,711 39,9 521,-46 987,450z"/>
			   <path fill="#AB8C5D" d="M4350 7190c-421,-154 -541,203 -444,450 60,52 250,33 267,-127 17,-159 177,-323 177,-323z"/>
			   <path fill="#AB8C5D" d="M6658 8663c273,1081 -619,978 -808,993 -189,15 -695,16 -666,351 29,336 259,179 712,144 128,-10 210,15 262,56 39,-144 417,-233 641,-134 223,98 389,199 516,69 126,-130 447,-218 447,-218 -550,-167 -196,-909 -196,-909 -164,-69 -390,-196 -260,-554 -189,37 -404,73 -648,202z"/>
			   <path fill="#AB8C5D" d="M8861 9383c-219,114 -360,356 -572,486 114,71 183,133 218,190 17,-45 124,-228 726,-118 682,124 722,385 923,336 200,-50 330,-312 106,-623 -127,-175 -96,-371 8,-481 -91,10 -135,-97 -135,-97 -11,260 -328,185 -494,156 -166,-29 -424,-35 -780,151z"/>
			   <path fill="#AB8C5D" d="M8655 7351c-63,-133 -129,-564 493,-810 159,-63 296,-87 413,-89 -433,-342 -23,-1148 393,-1131 415,18 584,-113 584,-113 -164,71 -366,-2 -705,-338 -338,-336 -804,-128 -919,46 -115,173 99,532 -154,786 -252,255 -935,247 -1268,-245 -235,81 -392,141 -371,394 96,89 154,196 173,298 0,0 437,303 78,644 -358,341 -598,146 -893,317 -295,171 -489,335 -432,479 159,-69 369,-118 640,-136 448,-31 344,-232 591,-382 246,-151 487,-58 493,29 6,87 -132,266 23,440 37,41 72,92 99,148 0,0 203,-241 507,-175 304,67 318,-29 255,-162z"/>
			   <path fill="#AB8C5D" d="M8041 4417c336,214 816,260 1069,-41 252,-301 -191,-490 44,-1011 235,-520 734,-2 1055,-240 321,-237 30,-639 53,-1032 23,-394 276,-499 276,-499 0,0 -465,-13 -482,421 -18,434 -132,613 -339,549 -206,-63 -309,-503 -579,-370 -269,133 -198,412 -241,937 -30,377 -467,502 -808,440 -417,-40 -403,263 -411,308 -1,4 -2,6 -4,5 -17,-13 -35,-27 -52,-42 229,211 408,413 419,575z"/>
			   <path fill="#827351" d="M6345 6571c0,0 -262,929 -1054,419 243,364 -181,609 -794,269 -52,-29 -101,-52 -147,-69 0,0 -160,164 -177,323 -17,160 -207,179 -267,127 26,66 66,124 121,163 281,201 765,601 57,671 -329,32 -490,-321 -643,-715 -1,-1 -131,52 -216,169 -93,126 -133,327 97,619 261,85 418,256 488,420 403,190 640,-119 737,-261 21,-32 43,-66 68,-101 120,-164 325,-347 1005,-407 -18,-229 93,-463 427,-609 -57,-144 137,-308 432,-479 295,-171 535,24 893,-317 359,-341 -78,-644 -78,-644 17,90 3,176 -45,242 -197,273 -445,281 -704,239 -69,-12 -136,-33 -200,-59z"/>
			  </g>
			</pattern>
			<pattern id="Pattern2" patternUnits="userSpaceOnUse" x="0" y="0" width="9335" height="9895" viewBox="871 573 9335 9895">
			  <g>
			   <path fill="#878789" d="M4044 5761c136,81 305,132 521,132 349,0 601,-107 778,-245 282,-217 377,-510 381,-571 1,-88 20,-191 -30,-260 -93,-131 -305,79 -357,-263 -2,-12 -4,-24 -4,-35 -819,-303 -919,-1083 -825,-1494 -96,-295 138,-699 396,-766 243,-64 475,-39 753,17 744,-188 275,-881 -65,-984 -340,-102 -173,-462 120,-590 293,-128 350,323 548,612 198,289 560,78 628,-146 67,-224 263,-494 504,-482 240,13 440,109 744,53 305,-56 632,-165 881,72 409,-198 1189,-237 1189,-237l-5268 0c-315,601 -1167,1086 -1658,1217 -81,21 -152,33 -209,34 -408,10 -565,-33 -914,-217 -349,-183 -801,-118 -801,-118 26,86 25,176 2,262 -80,290 -223,341 -486,378l0 272c710,-70 847,-563 2000,-361 265,47 395,116 432,194 56,-20 524,265 490,562 -35,305 -454,747 -1272,289 -143,-80 -884,-423 -1284,-370 -134,18 -230,81 -251,213 -32,202 -115,229 -115,229l0 1366c0,186 1249,288 1545,116 297,-171 571,-158 870,103 227,198 326,760 757,1018z"/>
			   <path fill="#878789" d="M7243 7339c0,0 -508,366 -95,773 146,144 224,288 249,426 46,252 -85,483 -295,650 -326,258 -581,-64 -720,-129 4,71 22,149 89,242 129,180 42,258 -31,314 -73,56 -242,-12 -374,-34 -132,-23 -284,0 -284,112 0,111 174,179 256,332 82,152 -143,325 -360,331 -208,6 -307,-115 -428,-240 0,0 -228,166 -313,352l5269 0 0 -1728c-204,43 -494,397 -464,609 29,211 237,516 -161,649 -399,134 -539,-269 -721,-362 -75,-38 -100,-99 -48,-190 -193,-106 -231,-291 -184,-433 47,-142 344,-295 725,-437 381,-143 322,-212 369,-790 47,-578 -760,-585 -760,-585 -101,113 -290,126 -374,232 -197,249 -448,254 -798,101 -162,-71 -376,-151 -547,-195z"/>
			   <path fill="#878789" d="M4478 8875c510,-305 762,-727 528,-951 -235,-224 -657,-37 -1296,522 -576,50s4 -1224,604 -1690,501 -51,-11 -99,-25 -146,-41 -306,-106 -641,-145 -836,-160 -102,-7 -166,-8 -166,-8l0 1319c0,0 546,76 696,411l1489 0c89,0 148,-88 400,-157 249,-68 735,174 895,-209 -353,65 -333,-93 -521,-331 -205,-261 76,-472 242,-439 30,6 61,12 95,18 0,0 -200,-171 310,-475z"/>
			   <path fill="#878789" d="M8825 4515c431,-359 888,-166 1381,9l0 -1366c-234,345 -610,623 -1008,498 -24,-7 -47,-14 -70,-21 -1,0 -1,0 -1,0 -538,-156 -931,-127 -1167,-109 -246,18 -340,277 -340,277 70,212 119,406 -82,484 -102,40 -183,10 -255,-56 0,0 -85,138 44,316 41,56 79,130 103,210 240,230 873,193 1395,-242z"/>
			   <path fill="#494947" d="M4044 5761c-431,-258 -530,-820 -757,-1018 -299,-261 -573,-274 -870,-103 -296,172 -1545,70 -1545,-116l0 4214c0,0 64,1 166,8 -255,-332 1,-679 80,-719 101,-80 303,-163 666,-231 639,-118 715,-714 293,-963 -13,-7 -25,-14 -38,-21 -402,-211 -687,48 -830,-458 -86,-307 223,-435 460,-609 167,-123 216,-348 592,-310 298,30 615,327 862,515 97,75 183,132 253,150 246,62 643,-12 668,-339z"/>
			   <path fill="#494947" d="M7644 5806c93,207 -21,533 -167,646 -206,158 -308,127 -461,192 -62,27 -95,76 -107,144 105,106 424,125 1174,-99 751,-224 909,-101 944,266 11,117 -17,193 -65,246 0,0 807,7 760,585 0,0 627,580 -152,1059 -458,280 -685,472 -758,601 -52,91 -27,152 48,190 182,93 322,496 721,362 398,-133 190,-438 161,-649 -30,-212 260,-566 464,-609l0 -4213c-75,114 -91,348 -205,658 -206,560 -915,734 -1402,678 -486,-56 -955,-57 -955,-57z"/>
			   <path fill="#494947" d="M5948 6663c-155,-142 -291,-264 -226,-557 41,-185 138,-309 262,-383 0,0 -376,129 -620,-58 259,222 224,634 -291,727 -528,95 -768,182 -765,468 3,285 634,330 253,678 -141,129 -322,158 -511,139 -317,749 -663,736 -1396,743 -732,6 -634,527 -634,527 466,103 1114,3 1690,-501 639,-559 1061,-746 1296,-522 234,224 -18,646 -528,951 -510,304 -310,475 -310,475 151,26 334,37 526,-93 116,-77 316,-434 690,-568 107,-39 231,-70 352,-88 -130,-70 -154,-534 301,-500 457,34 464,431 786,533 323,103 489,28 574,-96 -25,-138 -103,-282 -249,-426 -413,-407 95,-773 95,-773 -143,-37 -255,-48 -281,-4 -41,315 -397,754 -783,484 -397,-278 -259,-487 -187,-574 -183,55 -207,-74 -277,-267 -71,-192 233,-315 233,-315z"/>
			   <path fill="#494947" d="M5823 5246c185,49 436,-188 683,-112 248,76 496,208 771,74 188,-91 203,-282 153,-451 -24,-80 -62,-154 -103,-210 -129,-178 -44,-316 -44,-316 -142,-131 -251,-405 -428,-566 -267,-242 -561,-18 -576,292 -7,149 133,278 176,396 66,184 53,417 -239,449 -254,27 -391,-114 -460,-320 -30,24 -440,-248 -423,37 0,11 2,23 4,35 52,342 264,132 357,263 50,69 31,172 30,260 0,78 13,145 99,169z"/>
			   <path fill="#494947" d="M7857 1992c112,41 160,251 156,399 -3,144 -49,267 -110,377 -101,381 -296,529 149,629 446,99 672,-880 959,-1153 104,-99 249,-140 403,-151 77,-6 156,-4 233,0 195,12 380,47 490,42 23,-1 46,-3 69,-6l0 -1021c-92,-32 -186,-43 -271,-23 -354,80 -525,208 -1161,18 -637,-190 -1165,-299 -1560,-87 -395,213 -374,777 -45,781 95,-134 207,-229 366,-170 243,90 155,303 322,365z"/>
			   <path fill="#494947" d="M1358 1752c23,-86 24,-176 -2,-262 -51,-170 -205,-322 -484,-382l0 1022c263,-37 406,-88 486,-378z"/>
			   <path fill="#B4B4B6" d="M3304 2235c56,122 -113,265 -360,378 -317,145 -1507,-92 -1706,103 400,-53 1141,290 1284,370 818,458 1237,16 1272,-289 34,-297 -434,-582 -490,-562z"/>
			   <path fill="#B4B4B6" d="M3280 1791c491,-131 1343,-616 1658,-1217l-1875 0c261,0 222,405 44,458 -179,53 -404,194 -369,365 35,171 173,267 343,186 158,-75 273,168 199,208z"/>
			   <path fill="#B4B4B6" d="M1785 906c-39,-196 -214,-332 -214,-332l-699 0c0,0 591,80 913,332z"/>
			   <path fill="#878789" d="M1571 574c0,0 175,136 214,332 17,14 34,28 50,43 305,286 510,59 633,-9 123,-69 334,-366 595,-366l-1492 0z"/>
			   <path fill="#B4B4B6" d="M5592 1292c340,103 809,796 65,984 195,40 412,95 670,146 628,125 575,-184 704,-400 44,-75 89,-155 138,-225 -329,-4 -350,-568 45,-781 395,-212 923,-103 1560,87 -9,-123 92,-218 243,-292 -249,-237 -576,-128 -881,-72 -304,56 -504,-40 -744,-53 -241,-12 -437,258 -504,482 -68,224 -430,435 -628,146 -198,-289 -255,-740 -548,-612 -293,128 -460,488 -120,590z"/>
			   <path fill="#B4B4B6" d="M9011 2244c-287,273 -513,1252 -959,1153 -445,-100 -250,-248 -149,-629 -155,275 -413,468 -377,708 16,105 58,218 94,327 0,0 94,-259 340,-277 236,-18 629,-47 1167,109 -26,-11 -419,-275 -23,-641 405,-373 310,-901 310,-901 -154,11 -299,52 -403,151z"/>
			   <path fill="#B4B4B6" d="M5701 3850c16,-226 -40,-478 -365,-511 -48,-5 -101,1 -158,3 -413,17 -609,-128 -670,-317 -94,411 6,1191 825,1494 -17,-285 393,-13 423,-37 -62,-185 -69,-421 -55,-632z"/>
			   <path fill="#B4B4B6" d="M8825 4515c-522,435 -1155,472 -1395,242 50,169 35,360 -153,451 -275,134 -523,2 -771,-74 -247,-76 -498,161 -683,112 -86,-24 -99,-91 -99,-169 -4,61 -99,354 -381,571 7,6 14,11 21,17 244,187 620,58 620,58 174,-103 400,-107 599,-42 292,96 422,-118 832,-44 119,22 191,86 229,169 0,0 469,1 955,57 487,56 1196,-118 1402,-678 114,-310 130,-544 205,-658l0 -3c-493,-175 -950,-368 -1381,-9z"/>
			   <path fill="#B4B4B6" d="M9027 6955c-35,-367 -193,-490 -944,-266 -750,224 -1069,205 -1174,99 -18,99 8,240 49,416 9,39 10,83 4,131 26,-44 138,-33 281,4 171,44 385,124 547,195 350,153 601,148 798,-101 84,-106 273,-119 374,-232 48,-53 76,-129 65,-246z"/>
			   <path fill="#B4B4B6" d="M6014 7219c53,-64 269,-225 27,-469 -31,-31 -62,-59 -93,-87 0,0 -304,123 -233,315 70,193 94,322 277,267 9,-10 16,-19 22,-26z"/>
			   <path fill="#B4B4B6" d="M4050 7677c-321,-32 -664,-203 -871,-253 -328,-81 -510,111 -815,534 -305,423 -563,371 -727,396 -164,25 -563,-47 -598,-159 -15,-49 2,-108 79,-168 -79,40 -335,387 -80,719 195,15 530,54 836,160 47,16 95,30 146,41 0,0 -98,-521 634,-527 733,-7 1079,6 1396,-743z"/>
			   <path fill="#B4B4B6" d="M1820 6195c244,-330 829,-29 1010,-70 181,-41 261,-115 293,-175 -247,-188 -564,-485 -862,-515 -376,-38 -425,187 -592,310 -237,174 -546,302 -460,609 143,506 428,247 830,458 0,0 -462,-288 -219,-617z"/>
			   <path fill="#B4B4B6" d="M6037 8101c-455,-34 -431,430 -301,500 205,-31 403,-22 504,61 176,146 133,258 142,397 139,65 394,387 720,129 210,-167 341,-398 295,-650 -85,124 -251,199 -574,96 -322,-102 -329,-499 -786,-533z"/>
			   <path fill="#B4B4B6" d="M9722 7786c-47,578 12,647 -369,790 -381,142 -678,295 -725,437 -47,142 -9,327 184,433 73,-129 300,-321 758,-601 779,-479 152,-1059 152,-1059z"/>
			   <path fill="#B4B4B6" d="M5234 10100c-129,-131 -302,-140 -771,-22 -41,10 -78,18 -111,24 -160,383 -646,141 -895,209 -252,69 -311,157 -400,157l1880 0c85,-186 313,-352 313,-352 -6,-6 -11,-11 -16,-16z"/>
			   <path fill="#B4B4B6" d="M1568 10468c-150,-335 -696,-411 -696,-411l0 411 696 0z"/>
			   <path fill="#FEFEFE" d="M8774 1103c636,190 807,62 1161,-18 85,-20 179,-9 271,23l0 -534c0,0 -780,39 -1189,237 -151,74 -252,169 -243,292z"/>
			   <path fill="#FEFEFE" d="M9647 2093c-77,-4 -156,-6 -233,0 0,0 95,528 -310,901 -396,366 -3,630 23,641 23,7 47,14 71,21 398,125 774,-153 1008,-498l0 -756c-141,58 -274,73 -336,-60 -91,-195 -223,-249 -223,-249z"/>
			   <path fill="#878789" d="M9647 2093c0,0 132,54 223,249 62,133 195,118 336,60l0 -273c-23,3 -46,5 -69,6 -110,5 -295,-30 -490,-42z"/>
			   <path fill="#FEFEFE" d="M3081 1583c-170,81 -308,-15 -343,-186 -35,-171 190,-312 369,-365 178,-53 217,-458 -44,-458 -261,0 -472,297 -595,366 -123,68 -328,295 -633,9 -16,-15 -33,-29 -50,-43 -322,-252 -913,-332 -913,-332l0 534c279,60 433,212 484,382 0,0 452,-65 801,118 349,184 506,227 914,217 57,-1 128,-13 209,-34 74,-40 -41,-283 -199,-208z"/>
			   <path fill="#FEFEFE" d="M2872 2041c-1153,-202 -1290,291 -2000,361l0 756c0,0 83,-27 115,-229 21,-132 117,-195 251,-213 199,-195 1389,42 1706,-103 247,-113 416,-256 360,-378 -37,-78 -167,-147 -432,-194z"/>
			   <path fill="#FEFEFE" d="M4904 2259c-258,67 -492,471 -396,766 61,189 257,334 670,317 57,-2 110,-8 158,-3 325,33 381,285 365,511 -14,211 -7,447 55,632 69,206 206,347 460,320 292,-32 305,-265 239,-449 -43,-118 -183,-247 -176,-396 15,-310 309,-534 576,-292 177,161 286,435 428,566 72,66 153,96 255,56 201,-78 152,-272 82,-484 -36,-109 -78,-222 -94,-327 -36,-240 222,-433 377,-708 61,-110 107,-233 110,-377 4,-148 -44,-358 -156,-399 -167,-62 -79,-275 -322,-365 -159,-59 -271,36 -366,170 -49,70 -94,150 -138,225 -129,216 -76,525 -704,400 -258,-51 -475,-106 -670,-146 -278,-56 -510,-81 -753,-17z"/>
			   <path fill="#FEFEFE" d="M7415 5637c-410,-74 -540,140 -832,44 -199,-65 -425,-61 -599,42 -124,74 -221,198 -262,383 -65,293 71,415 226,557 31,28 62,56 93,87 242,244 26,405 -27,469 -6,7 -13,16 -22,26 -72,87 -210,296 187,574 386,270 742,-169 783,-484 6,-48 5,-92 -4,-131 -41,-176 -67,-317 -49,-416 12,-68 45,-117 107,-144 153,-65 255,-34 461,-192 146,-113 260,-439 167,-646 -38,-83 -110,-147 -229,-169z"/>
			   <path fill="#FEFEFE" d="M5073 6392c515,-93 550,-505 291,-727 -7,-5 -14,-11 -21,-17 -177,138 -429,245 -778,245 -216,0 -385,-51 -521,-132 -25,327 -422,401 -668,339 -70,-18 -156,-75 -253,-150 -32,60 -112,134 -293,175 -181,41 -766,-260 -1010,70 -243,329 219,617 219,617 13,7 25,14 38,21 422,249 346,845 -293,963 -363,68 -565,151 -666,231 -77,60 -94,119 -79,168 35,112 434,184 598,159 164,-25 422,27 727,-396 305,-423 487,-615 815,-534 207,50 550,221 871,253 189,19 370,-10 511,-139 381,-348 -250,-393 -253,-678 -3,-286 237,-373 765,-468z"/>
			   <path fill="#FEFEFE" d="M5736 8601c-121,18 -245,49 -352,88 -374,134 -574,491 -690,568 -192,130 -375,119 -526,93 -34,-6 -65,-12 -95,-18 -166,-33 -447,178 -242,439 188,238 168,396 521,331 33,-6 70,-14 111,-24 469,-118 642,-109 771,22 5,5 10,10 16,16 121,125 220,246 428,240 217,-6 442,-179 360,-331 -82,-153 -256,-221 -256,-332 0,-112 152,-135 284,-112 132,22 301,90 374,34 73,-56 160,-134 31,-314 -67,-93 -85,-171 -89,-242 -9,-139 34,-251 -142,-397 -101,-83 -299,-92 -504,-61z"/>
			   <path fill="#878789" d="M4044 5761c136,81 305,132 521,132 349,0 601,-107 778,-245 282,-217 377,-510 381,-571 1,-88 20,-191 -30,-260 -93,-131 -305,79 -357,-263 -2,-12 -4,-24 -4,-35 -819,-303 -919,-1083 -825,-1494 -96,-295 138,-699 396,-766 243,-64 475,-39 753,17 744,-188 275,-881 -65,-984 -340,-102 -173,-462 120,-590 293,-128 350,323 548,612 198,289 560,78 628,-146 67,-224 263,-494 504,-482 240,13 440,109 744,53 305,-56 632,-165 881,72 409,-198 1189,-237 1189,-237l-5268 0c-315,601 -1167,1086 -1658,1217 -81,21 -152,33 -209,34 -408,10 -565,-33 -914,-217 -349,-183 -801,-118 -801,-118 26,86 25,176 2,262 -80,290 -223,341 -486,378l0 272c710,-70 847,-563 2000,-361 265,47 395,116 432,194 56,-20 524,265 490,562 -35,305 -454,747 -1272,289 -143,-80 -884,-423 -1284,-370 -134,18 -230,81 -251,213 -32,202 -115,229 -115,229l0 1366c0,186 1249,288 1545,116 297,-171 571,-158 870,103 227,198 326,760 757,1018z"/>
			   <path fill="#878789" d="M7243 7339c0,0 -508,366 -95,773 146,144 224,288 249,426 46,252 -85,483 -295,650 -326,258 -581,-64 -720,-129 4,71 22,149 89,242 129,180 42,258 -31,314 -73,56 -242,-12 -374,-34 -132,-23 -284,0 -284,112 0,111 174,179 256,332 82,152 -143,325 -360,331 -208,6 -307,-115 -428,-240 0,0 -228,166 -313,352l5269 0 0 -1728c-204,43 -494,397 -464,609 29,211 237,516 -161,649 -399,134 -539,-269 -721,-362 -75,-38 -100,-99 -48,-190 -193,-106 -231,-291 -184,-433 47,-142 344,-295 725,-437 381,-143 322,-212 369,-790 47,-578 -760,-585 -760,-585 -101,113 -290,126 -374,232 -197,249 -448,254 -798,101 -162,-71 -376,-151 -547,-195z"/>
			   <path fill="#878789" d="M4478 8875c510,-305 762,-727 528,-951 -235,-224 -657,-37 -1296,522 -576,504 -1224,604 -1690,501 -51,-11 -99,-25 -146,-41 -306,-106 -641,-145 -836,-160 -102,-7 -166,-8 -166,-8l0 1319c0,0 546,76 696,411l1489 0c89,0 148,-88 400,-157 249,-68 735,174 895,-209 -353,65 -333,-93 -521,-331 -205,-261 76,-472 242,-439 30,6 61,12 95,18 0,0 -200,-171 310,-475z"/>
			   <path fill="#878789" d="M8825 4515c431,-359 888,-166 1381,9l0 -1366c-234,345 -610,623 -1008,498 -24,-7 -47,-14 -70,-21 -1,0 -1,0 -1,0 -538,-156 -931,-127 -1167,-109 -246,18 -340,277 -340,277 70,212 119,406 -82,484 -102,40 -183,10 -255,-56 0,0 -85,138 44,316 41,56 79,130 103,210 240,230 873,193 1395,-242z"/>
			   <path fill="#494947" d="M4044 5761c-431,-258 -530,-820 -757,-1018 -299,-261 -573,-274 -870,-103 -296,172 -1545,70 -1545,-116l0 4214c0,0 64,1 166,8 -255,-332 1,-679 80,-719 101,-80 303,-163 666,-231 639,-118 715,-714 293,-963 -13,-7 -25,-14 -38,-21 -402,-211 -687,48 -830,-458 -86,-307 223,-435 460,-609 167,-123 216,-348 592,-310 298,30 615,327 862,515 97,75 183,132 253,150 246,62 643,-12 668,-339z"/>
			   <path fill="#494947" d="M7644 5806c93,207 -21,533 -167,646 -206,158 -308,127 -461,192 -62,27 -95,76 -107,144 105,106 424,125 1174,-99 751,-224 909,-101 944,266 11,117 -17,193 -65,246 0,0 807,7 760,585 0,0 627,580 -152,1059 -458,280 -685,472 -758,601 -52,91 -27,152 48,190 182,93 322,496 721,362 398,-133 190,-438 161,-649 -30,-212 260,-566 464,-609l0 -4213c-75,114 -91,348 -205,658 -206,560 -915,734 -1402,678 -486,-56 -955,-57 -955,-57z"/>
			   <path fill="#494947" d="M5948 6663c-155,-142 -291,-264 -226,-557 41,-185 138,-309 262,-383 0,0 -376,129 -620,-58 259,222 224,634 -291,727 -528,95 -768,182 -765,468 3,285 634,330 253,678 -141,129 -322,158 -511,139 -317,749 -663,736 -1396,743 -732,6 -634,527 -634,527 466,103 1114,3 1690,-501 639,-559 1061,-746 1296,-522 234,224 -18,646 -528,951 -510,304 -310,475 -310,475 151,26 334,37 526,-93 116,-77 316,-434 690,-568 107,-39 231,-70 352,-88 -130,-70 -154,-534 301,-500 457,34 464,431 786,533 323,103 489,28 574,-96 -25,-138 -103,-282 -249,-426 -413,-407 95,-773 95,-773 -143,-37 -255,-48 -281,-4 -41,315 -397,754 -783,484 -397,-278 -259,-487 -187,-574 -183,55 -207,-74 -277,-267 -71,-192 233,-315 233,-315z"/>
			   <path fill="#494947" d="M5823 5246c185,49 436,-188 683,-112 248,76 496,208 771,74 188,-91 203,-282 153,-451 -24,-80 -62,-154 -103,-210 -129,-178 -44,-316 -44,-316 -142,-131 -251,-405 -428,-566 -267,-242 -561,-18 -576,292 -7,149 133,278 176,396 66,184 53,417 -239,449 -254,27 -391,-114 -460,-320 -30,24 -440,-248 -423,37 0,11 2,23 4,35 52,342 264,132 357,263 50,69 31,172 30,260 0,78 13,145 99,169z"/>
			   <path fill="#494947" d="M7857 1992c112,41 160,251 156,399 -3,144 -49,267 -110,377 -101,381 -296,529 149,629 446,99 672,-880 959,-1153 104,-99 249,-140 403,-151 77,-6 156,-4 233,0 195,12 380,47 490,42 23,-1 46,-3 69,-6l0 -1021c-92,-32 -186,-43 -271,-23 -354,80 -525,208 -1161,18 -637,-190 -1165,-299 -1560,-87 -395,213 -374,777 -45,781 95,-134 207,-229 366,-170 243,90 155,303 322,365z"/>
			   <path fill="#494947" d="M1358 1752c23,-86 24,-176 -2,-262 -51,-170 -205,-322 -484,-382l0 1022c263,-37 406,-88 486,-378z"/>
			   <path fill="#B4B4B6" d="M3304 2235c56,122 -113,265 -360,378 -317,145 -1507,-92 -1706,103 400,-53 1141,290 1284,370 818,458 1237,16 1272,-289 34,-297 -434,-582 -490,-562z"/>
			   <path fill="#B4B4B6" d="M3280 1791c491,-131 1343,-616 1658,-1217l-1875 0c261,0 222,405 44,458 -179,53 -404,194 -369,365 35,171 173,267 343,186 158,-75 273,168 199,208z"/>
			   <path fill="#B4B4B6" d="M1785 906c-39,-196 -214,-332 -214,-332l-699 0c0,0 591,80 913,332z"/>
			   <path fill="#878789" d="M1571 574c0,0 175,136 214,332 17,14 34,28 50,43 305,286 510,59 633,-9 123,-69 334,-366 595,-366l-1492 0z"/>
			   <path fill="#B4B4B6" d="M5592 1292c340,103 809,796 65,984 195,40 412,95 670,146 628,125 575,-184 704,-400 44,-75 89,-155 138,-225 -329,-4 -350,-568 45,-781 395,-212 923,-103 1560,87 -9,-123 92,-218 243,-292 -249,-237 -576,-128 -881,-72 -304,56 -504,-40 -744,-53 -241,-12 -437,258 -504,482 -68,224 -430,435 -628,146 -198,-289 -255,-740 -548,-612 -293,128 -460,488 -120,590z"/>
			   <path fill="#B4B4B6" d="M9011 2244c-287,273 -513,1252 -959,1153 -445,-100 -250,-248 -149,-629 -155,275 -413,468 -377,708 16,105 58,218 94,327 0,0 94,-259 340,-277 236,-18 629,-47 1167,109 -26,-11 -419,-275 -23,-641 405,-373 310,-901 310,-901 -154,11 -299,52 -403,151z"/>
			   <path fill="#B4B4B6" d="M5701 3850c16,-226 -40,-478 -365,-511 -48,-5 -101,1 -158,3 -413,17 -609,-128 -670,-317 -94,411 6,1191 825,1494 -17,-285 393,-13 423,-37 -62,-185 -69,-421 -55,-632z"/>
			   <path fill="#B4B4B6" d="M8825 4515c-522,435 -1155,472 -1395,242 50,169 35,360 -153,451 -275,134 -523,2 -771,-74 -247,-76 -498,161 -683,112 -86,-24 -99,-91 -99,-169 -4,61 -99,354 -381,571 7,6 14,11 21,17 244,187 620,58 620,58 174,-103 400,-107 599,-42 292,96 422,-118 832,-44 119,22 191,86 229,169 0,0 469,1 955,57 487,56 1196,-118 1402,-678 114,-310 130,-544 205,-658l0 -3c-493,-175 -950,-368 -1381,-9z"/>
			   <path fill="#B4B4B6" d="M9027 6955c-35,-367 -193,-490 -944,-266 -750,224 -1069,205 -1174,99 -18,99 8,240 49,416 9,39 10,83 4,131 26,-44 138,-33 281,4 171,44 385,124 547,195 350,153 601,148 798,-101 84,-106 273,-119 374,-232 48,-53 76,-129 65,-246z"/>
			   <path fill="#B4B4B6" d="M6014 7219c53,-64 269,-225 27,-469 -31,-31 -62,-59 -93,-87 0,0 -304,123 -233,315 70,193 94,322 277,267 9,-10 16,-19 22,-26z"/>
			   <path fill="#B4B4B6" d="M4050 7677c-321,-32 -664,-203 -871,-253 -328,-81 -510,111 -815,534 -305,423 -563,371 -727,396 -164,25 -563,-47 -598,-159 -15,-49 2,-108 79,-168 -79,40 -335,387 -80,719 195,15 530,54 836,160 47,16 95,30 146,41 0,0 -98,-521 634,-527 733,-7 1079,6 1396,-743z"/>
			   <path fill="#B4B4B6" d="M1820 6195c244,-330 829,-29 1010,-70 181,-41 261,-115 293,-175 -247,-188 -564,-485 -862,-515 -376,-38 -425,187 -592,310 -237,174 -546,302 -460,609 143,506 428,247 830,458 0,0 -462,-288 -219,-617z"/>
			   <path fill="#B4B4B6" d="M6037 8101c-455,-34 -431,430 -301,500 205,-31 403,-22 504,61 176,146 133,258 142,397 139,65 394,387 720,129 210,-167 341,-398 295,-650 -85,124 -251,199 -574,96 -322,-102 -329,-499 -786,-533z"/>
			   <path fill="#B4B4B6" d="M9722 7786c-47,578 12,647 -369,790 -381,142 -678,295 -725,437 -47,142 -9,327 184,433 73,-129 300,-321 758,-601 779,-479 152,-1059 152,-1059z"/>
			   <path fill="#B4B4B6" d="M5234 10100c-129,-131 -302,-140 -771,-22 -41,10 -78,18 -111,24 -160,383 -646,141 -895,209 -252,69 -311,157 -400,157l1880 0c85,-186 313,-352 313,-352 -6,-6 -11,-11 -16,-16z"/>
			   <path fill="#B4B4B6" d="M1568 10468c-150,-335 -696,-411 -696,-411l0 411 696 0z"/>
			   <path fill="#FEFEFE" d="M8774 1103c636,190 807,62 1161,-18 85,-20 179,-9 271,23l0 -534c0,0 -780,39 -1189,237 -151,74 -252,169 -243,292z"/>
			   <path fill="#FEFEFE" d="M9647 2093c-77,-4 -156,-6 -233,0 0,0 95,528 -310,901 -396,366 -3,630 23,641 23,7 47,14 71,21 398,125 774,-153 1008,-498l0 -756c-141,58 -274,73 -336,-60 -91,-195 -223,-249 -223,-249z"/>
			   <path fill="#878789" d="M9647 2093c0,0 132,54 223,249 62,133 195,118 336,60l0 -273c-23,3 -46,5 -69,6 -110,5 -295,-30 -490,-42z"/>
			   <path fill="#FEFEFE" d="M3081 1583c-170,81 -308,-15 -343,-186 -35,-171 190,-312 369,-365 178,-53 217,-458 -44,-458 -261,0 -472,297 -595,366 -123,68 -328,295 -633,9 -16,-15 -33,-29 -50,-43 -322,-252 -913,-332 -913,-332l0 534c279,60 433,212 484,382 0,0 452,-65 801,118 349,184 506,227 914,217 57,-1 128,-13 209,-34 74,-40 -41,-283 -199,-208z"/>
			   <path fill="#FEFEFE" d="M2872 2041c-1153,-202 -1290,291 -2000,361l0 756c0,0 83,-27 115,-229 21,-132 117,-195 251,-213 199,-195 1389,42 1706,-103 247,-113 416,-256 360,-378 -37,-78 -167,-147 -432,-194z"/>
			   <path fill="#FEFEFE" d="M4904 2259c-258,67 -492,471 -396,766 61,189 257,334 670,317 57,-2 110,-8 158,-3 325,33 381,285 365,511 -14,211 -7,447 55,632 69,206 206,347 460,320 292,-32 305,-265 239,-449 -43,-118 -183,-247 -176,-396 15,-310 309,-534 576,-292 177,161 286,435 428,566 72,66 153,96 255,56 201,-78 152,-272 82,-484 -36,-109 -78,-222 -94,-327 -36,-240 222,-433 377,-708 61,-110 107,-233 110,-377 4,-148 -44,-358 -156,-399 -167,-62 -79,-275 -322,-365 -159,-59 -271,36 -366,170 -49,70 -94,150 -138,225 -129,216 -76,525 -704,400 -258,-51 -475,-106 -670,-146 -278,-56 -510,-81 -753,-17z"/>
			   <path fill="#FEFEFE" d="M7415 5637c-410,-74 -540,140 -832,44 -199,-65 -425,-61 -599,42 -124,74 -221,198 -262,383 -65,293 71,415 226,557 31,28 62,56 93,87 242,244 26,405 -27,469 -6,7 -13,16 -22,26 -72,87 -210,296 187,574 386,270 742,-169 783,-484 6,-48 5,-92 -4,-131 -41,-176 -67,-317 -49,-416 12,-68 45,-117 107,-144 153,-65 255,-34 461,-192 146,-113 260,-439 167,-646 -38,-83 -110,-147 -229,-169z"/>
			   <path fill="#FEFEFE" d="M5073 6392c515,-93 550,-505 291,-727 -7,-5 -14,-11 -21,-17 -177,138 -429,245 -778,245 -216,0 -385,-51 -521,-132 -25,327 -422,401 -668,339 -70,-18 -156,-75 -253,-150 -32,60 -112,134 -293,175 -181,41 -766,-260 -1010,70 -243,329 219,617 219,617 13,7 25,14 38,21 422,249 346,845 -293,963 -363,68 -565,151 -666,231 -77,60 -94,119 -79,168 35,112 434,184 598,159 164,-25 422,27 727,-396 305,-423 487,-615 815,-534 207,50 550,221 871,253 189,19 370,-10 511,-139 381,-348 -250,-393 -253,-678 -3,-286 237,-373 765,-468z"/>
			   <path fill="#FEFEFE" d="M5736 8601c-121,18 -245,49 -352,88 -374,134 -574,491 -690,568 -192,130 -375,119 -526,93 -34,-6 -65,-12 -95,-18 -166,-33 -447,178 -242,439 188,238 168,396 521,331 33,-6 70,-14 111,-24 469,-118 642,-109 771,22 5,5 10,10 16,16 121,125 220,246 428,240 217,-6 442,-179 360,-331 -82,-153 -256,-221 -256,-332 0,-112 152,-135 284,-112 132,22 301,90 374,34 73,-56 160,-134 31,-314 -67,-93 -85,-171 -89,-242 -9,-139 34,-251 -142,-397 -101,-83 -299,-92 -504,-61z"/>
			   <path fill="#878789" d="M4044 5761c136,81 305,132 521,132 349,0 601,-107 778,-245 282,-217 377,-510 381,-571 1,-88 20,-191 -30,-260 -93,-131 -305,79 -357,-263 -2,-12 -4,-24 -4,-35 -819,-303 -919,-1083 -825,-1494 -96,-295 138,-699 396,-766 243,-64 475,-39 753,17 744,-188 275,-881 -65,-984 -340,-102 -173,-462 120,-590 293,-128 350,323 548,612 198,289 560,78 628,-146 67,-224 263,-494 504,-482 240,13 440,109 744,53 305,-56 632,-165 881,72 409,-198 1189,-237 1189,-237l-5268 0c-315,601 -1167,1086 -1658,1217 -81,21 -152,33 -209,34 -408,10 -565,-33 -914,-217 -349,-183 -801,-118 -801,-118 26,86 25,176 2,262 -80,290 -223,341 -486,378l0 272c710,-70 847,-563 2000,-361 265,47 395,116 432,194 56,-20 524,265 490,562 -35,305 -454,747 -1272,289 -143,-80 -884,-423 -1284,-370 -134,18 -230,81 -251,213 -32,202 -115,229 -115,229l0 1366c0,186 1249,288 1545,116 297,-171 571,-158 870,103 227,198 326,760 757,1018z"/>
			   <path fill="#878789" d="M7243 7339c0,0 -508,366 -95,773 146,144 224,288 249,426 46,252 -85,483 -295,650 -326,258 -581,-64 -720,-129 4,71 22,149 89,242 129,180 42,258 -31,314 -73,56 -242,-12 -374,-34 -132,-23 -284,0 -284,112 0,111 174,179 256,332 82,152 -143,325 -360,331 -208,6 -307,-115 -428,-240 0,0 -228,166 -313,352l5269 0 0 -1728c-204,43 -494,397 -464,609 29,211 237,516 -161,649 -399,134 -539,-269 -721,-362 -75,-38 -100,-99 -48,-190 -193,-106 -231,-291 -184,-433 47,-142 344,-295 725,-437 381,-143 322,-212 369,-790 47,-578 -760,-585 -760,-585 -101,113 -290,126 -374,232 -197,249 -448,254 -798,101 -162,-71 -376,-151 -547,-195z"/>
			   <path fill="#878789" d="M4478 8875c510,-305 762,-727 528,-951 -235,-224 -657,-37 -1296,522 -576,504 -1224,604 -1690,501 -51,-11 -99,-25 -146,-41 -306,-106 -641,-145 -836,-160 -102,-7 -166,-8 -166,-8l0 1319c0,0 546,76 696,411l1489 0c89,0 148,-88 400,-157 249,-68 735,174 895,-209 -353,65 -333,-93 -521,-331 -205,-261 76,-472 242,-439 30,6 61,12 95,18 0,0 -200,-171 310,-475z"/>
			   <path fill="#878789" d="M8825 4515c431,-359 888,-166 1381,9l0 -1366c-234,345 -610,623 -1008,498 -24,-7 -47,-14 -70,-21 -1,0 -1,0 -1,0 -538,-156 -931,-127 -1167,-109 -246,18 -340,277 -340,277 70,212 119,406 -82,484 -102,40 -183,10 -255,-56 0,0 -85,138 44,316 41,56 79,130 103,210 240,230 873,193 1395,-242z"/>
			   <path fill="#494947" d="M4044 5761c-431,-258 -530,-820 -757,-1018 -299,-261 -573,-274 -870,-103 -296,172 -1545,70 -1545,-116l0 4214c0,0 64,1 166,8 -255,-332 1,-679 80,-719 101,-80 303,-163 666,-231 639,-118 715,-714 293,-963 -13,-7 -25,-14 -38,-21 -402,-211 -687,48 -830,-458 -86,-307 223,-435 460,-609 167,-123 216,-348 592,-310 298,30 615,327 862,515 97,75 183,132 253,150 246,62 643,-12 668,-339z"/>
			   <path fill="#494947" d="M7644 5806c93,207 -21,533 -167,646 -206,158 -308,127 -461,192 -62,27 -95,76 -107,144 105,106 424,125 1174,-99 751,-224 909,-101 944,266 11,117 -17,193 -65,246 0,0 807,7 760,585 0,0 627,580 -152,1059 -458,280 -685,472 -758,601 -52,91 -27,152 48,190 182,93 322,496 721,362 398,-133 190,-438 161,-649 -30,-212 260,-566 464,-609l0 -4213c-75,114 -91,348 -205,658 -206,560 -915,734 -1402,678 -486,-56 -955,-57 -955,-57z"/>
			   <path fill="#494947" d="M5948 6663c-155,-142 -291,-264 -226,-557 41,-185 138,-309 262,-383 0,0 -376,129 -620,-58 259,222 224,634 -291,727 -528,95 -768,182 -765,468 3,285 634,330 253,678 -141,129 -322,158 -511,139 -317,749 -663,736 -1396,743 -732,6 -634,527 -634,527 466,103 1114,3 1690,-501 639,-559 1061,-746 1296,-522 234,224 -18,646 -528,951 -510,304 -310,475 -310,475 151,26 334,37 526,-93 116,-77 316,-434 690,-568 107,-39 231,-70 352,-88 -130,-70 -154,-534 301,-500 457,34 464,431 786,533 323,103 489,28 574,-96 -25,-138 -103,-282 -249,-426 -413,-407 95,-773 95,-773 -143,-37 -255,-48 -281,-4 -41,315 -397,754 -783,484 -397,-278 -259,-487 -187,-574 -183,55 -207,-74 -277,-267 -71,-192 233,-315 233,-315z"/>
			   <path fill="#494947" d="M5823 5246c185,49 436,-188 683,-112 248,76 496,208 771,74 188,-91 203,-282 153,-451 -24,-80 -62,-154 -103,-210 -129,-178 -44,-316 -44,-316 -142,-131 -251,-405 -428,-566 -267,-242 -561,-18 -576,292 -7,149 133,278 176,396 66,184 53,417 -239,449 -254,27 -391,-114 -460,-320 -30,24 -440,-248 -423,37 0,11 2,23 4,35 52,342 264,132 357,263 50,69 31,172 30,260 0,78 13,145 99,169z"/>
			   <path fill="#494947" d="M7857 1992c112,41 160,251 156,399 -3,144 -49,267 -110,377 -101,381 -296,529 149,629 446,99 672,-880 959,-1153 104,-99 249,-140 403,-151 77,-6 156,-4 233,0 195,12 380,47 490,42 23,-1 46,-3 69,-6l0 -1021c-92,-32 -186,-43 -271,-23 -354,80 -525,208 -1161,18 -637,-190 -1165,-299 -1560,-87 -395,213 -374,777 -45,781 95,-134 207,-229 366,-170 243,90 155,303 322,365z"/>
			   <path fill="#494947" d="M1358 1752c23,-86 24,-176 -2,-262 -51,-170 -205,-322 -484,-382l0 1022c263,-37 406,-88 486,-378z"/>
			   <path fill="#B4B4B6" d="M3304 2235c56,122 -113,265 -360,378 -317,145 -1507,-92 -1706,103 400,-53 1141,290 1284,370 818,458 1237,16 1272,-289 34,-297 -434,-582 -490,-562z"/>
			   <path fill="#B4B4B6" d="M3280 1791c491,-131 1343,-616 1658,-1217l-1875 0c261,0 222,405 44,458 -179,53 -404,194 -369,365 35,171 173,267 343,186 158,-75 273,168 199,208z"/>
			   <path fill="#B4B4B6" d="M1785 906c-39,-196 -214,-332 -214,-332l-699 0c0,0 591,80 913,332z"/>
			   <path fill="#878789" d="M1571 574c0,0 175,136 214,332 17,14 34,28 50,43 305,286 510,59 633,-9 123,-69 334,-366 595,-366l-1492 0z"/>
			   <path fill="#B4B4B6" d="M5592 1292c340,103 809,796 65,984 195,40 412,95 670,146 628,125 575,-184 704,-400 44,-75 89,-155 138,-225 -329,-4 -350,-568 45,-781 395,-212 923,-103 1560,87 -9,-123 92,-218 243,-292 -249,-237 -576,-128 -881,-72 -304,56 -504,-40 -744,-53 -241,-12 -437,258 -504,482 -68,224 -430,435 -628,146 -198,-289 -255,-740 -548,-612 -293,128 -460,488 -120,590z"/>
			   <path fill="#B4B4B6" d="M9011 2244c-287,273 -513,1252 -959,1153 -445,-100 -250,-248 -149,-629 -155,275 -413,468 -377,708 16,105 58,218 94,327 0,0 94,-259 340,-277 236,-18 629,-47 1167,109 -26,-11 -419,-275 -23,-641 405,-373 310,-901 310,-901 -154,11 -299,52 -403,151z"/>
			   <path fill="#B4B4B6" d="M5701 3850c16,-226 -40,-478 -365,-511 -48,-5 -101,1 -158,3 -413,17 -609,-128 -670,-317 -94,411 6,1191 825,1494 -17,-285 393,-13 423,-37 -62,-185 -69,-421 -55,-632z"/>
			   <path fill="#B4B4B6" d="M8825 4515c-522,435 -1155,472 -1395,242 50,169 35,360 -153,451 -275,134 -523,2 -771,-74 -247,-76 -498,161 -683,112 -86,-24 -99,-91 -99,-169 -4,61 -99,354 -381,571 7,6 14,11 21,17 244,187 620,58 620,58 174,-103 400,-107 599,-42 292,96 422,-118 832,-44 119,22 191,86 229,169 0,0 469,1 955,57 487,56 1196,-118 1402,-678 114,-310 130,-544 205,-658l0 -3c-493,-175 -950,-368 -1381,-9z"/>
			   <path fill="#B4B4B6" d="M9027 6955c-35,-367 -193,-490 -944,-266 -750,224 -1069,205 -1174,99 -18,99 8,240 49,416 9,39 10,83 4,131 26,-44 138,-33 281,4 171,44 385,124 547,195 350,153 601,148 798,-101 84,-106 273,-119 374,-232 48,-53 76,-129 65,-246z"/>
			   <path fill="#B4B4B6" d="M6014 7219c53,-64 269,-225 27,-469 -31,-31 -62,-59 -93,-87 0,0 -304,123 -233,315 70,193 94,322 277,267 9,-10 16,-19 22,-26z"/>
			   <path fill="#B4B4B6" d="M4050 7677c-321,-32 -664,-203 -871,-253 -328,-81 -510,111 -815,534 -305,423 -563,371 -727,396 -164,25 -563,-47 -598,-159 -15,-49 2,-108 79,-168 -79,40 -335,387 -80,719 195,15 530,54 836,160 47,16 95,30 146,41 0,0 -98,-521 634,-527 733,-7 1079,6 1396,-743z"/>
			   <path fill="#B4B4B6" d="M1820 6195c244,-330 829,-29 1010,-70 181,-41 261,-115 293,-175 -247,-188 -564,-485 -862,-515 -376,-38 -425,187 -592,310 -237,174 -546,302 -460,609 143,506 428,247 830,458 0,0 -462,-288 -219,-617z"/>
			   <path fill="#B4B4B6" d="M6037 8101c-455,-34 -431,430 -301,500 205,-31 403,-22 504,61 176,146 133,258 142,397 139,65 394,387 720,129 210,-167 341,-398 295,-650 -85,124 -251,199 -574,96 -322,-102 -329,-499 -786,-533z"/>
			   <path fill="#B4B4B6" d="M9722 7786c-47,578 12,647 -369,790 -381,142 -678,295 -725,437 -47,142 -9,327 184,433 73,-129 300,-321 758,-601 779,-479 152,-1059 152,-1059z"/>
			   <path fill="#B4B4B6" d="M5234 10100c-129,-131 -302,-140 -771,-22 -41,10 -78,18 -111,24 -160,383 -646,141 -895,209 -252,69 -311,157 -400,157l1880 0c85,-186 313,-352 313,-352 -6,-6 -11,-11 -16,-16z"/>
			   <path fill="#B4B4B6" d="M1568 10468c-150,-335 -696,-411 -696,-411l0 411 696 0z"/>
			   <path fill="#FEFEFE" d="M8774 1103c636,190 807,62 1161,-18 85,-20 179,-9 271,23l0 -534c0,0 -780,39 -1189,237 -151,74 -252,169 -243,292z"/>
			   <path fill="#FEFEFE" d="M9647 2093c-77,-4 -156,-6 -233,0 0,0 95,528 -310,901 -396,366 -3,630 23,641 23,7 47,14 71,21 398,125 774,-153 1008,-498l0 -756c-141,58 -274,73 -336,-60 -91,-195 -223,-249 -223,-249z"/>
			   <path fill="#878789" d="M9647 2093c0,0 132,54 223,249 62,133 195,118 336,60l0 -273c-23,3 -46,5 -69,6 -110,5 -295,-30 -490,-42z"/>
			   <path fill="#FEFEFE" d="M3081 1583c-170,81 -308,-15 -343,-186 -35,-171 190,-312 369,-365 178,-53 217,-458 -44,-458 -261,0 -472,297 -595,366 -123,68 -328,295 -633,9 -16,-15 -33,-29 -50,-43 -322,-252 -913,-332 -913,-332l0 534c279,60 433,212 484,382 0,0 452,-65 801,118 349,184 506,227 914,217 57,-1 128,-13 209,-34 74,-40 -41,-283 -199,-208z"/>
			   <path fill="#FEFEFE" d="M2872 2041c-1153,-202 -1290,291 -2000,361l0 756c0,0 83,-27 115,-229 21,-132 117,-195 251,-213 199,-195 1389,42 1706,-103 247,-113 416,-256 360,-378 -37,-78 -167,-147 -432,-194z"/>
			   <path fill="#FEFEFE" d="M4904 2259c-258,67 -492,471 -396,766 61,189 257,334 670,317 57,-2 110,-8 158,-3 325,33 381,285 365,511 -14,211 -7,447 55,632 69,206 206,347 460,320 292,-32 305,-265 239,-449 -43,-118 -183,-247 -176,-396 15,-310 309,-534 576,-292 177,161 286,435 428,566 72,66 153,96 255,56 201,-78 152,-272 82,-484 -36,-109 -78,-222 -94,-327 -36,-240 222,-433 377,-708 61,-110 107,-233 110,-377 4,-148 -44,-358 -156,-399 -167,-62 -79,-275 -322,-365 -159,-59 -271,36 -366,170 -49,70 -94,150 -138,225 -129,216 -76,525 -704,400 -258,-51 -475,-106 -670,-146 -278,-56 -510,-81 -753,-17z"/>
			   <path fill="#FEFEFE" d="M7415 5637c-410,-74 -540,140 -832,44 -199,-65 -425,-61 -599,42 -124,74 -221,198 -262,383 -65,293 71,415 226,557 31,28 62,56 93,87 242,244 26,405 -27,469 -6,7 -13,16 -22,26 -72,87 -210,296 187,574 386,270 742,-169 783,-484 6,-48 5,-92 -4,-131 -41,-176 -67,-317 -49,-416 12,-68 45,-117 107,-144 153,-65 255,-34 461,-192 146,-113 260,-439 167,-646 -38,-83 -110,-147 -229,-169z"/>
			   <path fill="#FEFEFE" d="M5073 6392c515,-93 550,-505 291,-727 -7,-5 -14,-11 -21,-17 -177,138 -429,245 -778,245 -216,0 -385,-51 -521,-132 -25,327 -422,401 -668,339 -70,-18 -156,-75 -253,-150 -32,60 -112,134 -293,175 -181,41 -766,-260 -1010,70 -243,329 219,617 219,617 13,7 25,14 38,21 422,249 346,845 -293,963 -363,68 -565,151 -666,231 -77,60 -94,119 -79,168 35,112 434,184 598,159 164,-25 422,27 727,-396 305,-423 487,-615 815,-534 207,50 550,221 871,253 189,19 370,-10 511,-139 381,-348 -250,-393 -253,-678 -3,-286 237,-373 765,-468z"/>
			   <path fill="#FEFEFE" d="M5736 8601c-121,18 -245,49 -352,88 -374,134 -574,491 -690,568 -192,130 -375,119 -526,93 -34,-6 -65,-12 -95,-18 -166,-33 -447,178 -242,439 188,238 168,396 521,331 33,-6 70,-14 111,-24 469,-118 642,-109 771,22 5,5 10,10 16,16 121,125 220,246 428,240 217,-6 442,-179 360,-331 -82,-153 -256,-221 -256,-332 0,-112 152,-135 284,-112 132,22 301,90 374,34 73,-56 160,-134 31,-314 -67,-93 -85,-171 -89,-242 -9,-139 34,-251 -142,-397 -101,-83 -299,-92 -504,-61z"/>
			   <path fill="#878789" d="M4044 5761c136,81 305,132 521,132 349,0 601,-107 778,-245 282,-217 377,-510 381,-571 1,-88 20,-191 -30,-260 -93,-131 -305,79 -357,-263 -2,-12 -4,-24 -4,-35 -819,-303 -919,-1083 -825,-1494 -96,-295 138,-699 396,-766 243,-64 475,-39 753,17 744,-188 275,-881 -65,-984 -340,-102 -173,-462 120,-590 293,-128 350,323 548,612 198,289 560,78 628,-146 67,-224 263,-494 504,-482 240,13 440,109 744,53 305,-56 632,-165 881,72 409,-198 1189,-237 1189,-237l-5268 0c-315,601 -1167,1086 -1658,1217 -81,21 -152,33 -209,34 -408,10 -565,-33 -914,-217 -349,-183 -801,-118 -801,-118 26,86 25,176 2,262 -80,290 -223,341 -486,378l0 272c710,-70 847,-563 2000,-361 265,47 395,116 432,194 56,-20 524,265 490,562 -35,305 -454,747 -1272,289 -143,-80 -884,-423 -1284,-370 -134,18 -230,81 -251,213 -32,202 -115,229 -115,229l0 1366c0,186 1249,288 1545,116 297,-171 571,-158 870,103 227,198 326,760 757,1018z"/>
			   <path fill="#878789" d="M7243 7339c0,0 -508,366 -95,773 146,144 224,288 249,426 46,252 -85,483 -295,650 -326,258 -581,-64 -720,-129 4,71 22,149 89,242 129,180 42,258 -31,314 -73,56 -242,-12 -374,-34 -132,-23 -284,0 -284,112 0,111 174,179 256,332 82,152 -143,325 -360,331 -208,6 -307,-115 -428,-240 0,0 -228,166 -313,352l5269 0 0 -1728c-204,43 -494,397 -464,609 29,211 237,516 -161,649 -399,134 -539,-269 -721,-362 -75,-38 -100,-99 -48,-190 -193,-106 -231,-291 -184,-433 47,-142 344,-295 725,-437 381,-143 322,-212 369,-790 47,-578 -760,-585 -760,-585 -101,113 -290,126 -374,232 -197,249 -448,254 -798,101 -162,-71 -376,-151 -547,-195z"/>
			   <path fill="#878789" d="M4478 8875c510,-305 762,-727 528,-951 -235,-224 -657,-37 -1296,522 -576,504 -1224,604 -1690,501 -51,-11 -99,-25 -146,-41 -306,-106 -641,-145 -836,-160 -102,-7 -166,-8 -166,-8l0 1319c0,0 546,76 696,411l1489 0c89,0 148,-88 400,-157 249,-68 735,174 895,-209 -353,65 -333,-93 -521,-331 -205,-261 76,-472 242,-439 30,6 61,12 95,18 0,0 -200,-171 310,-475z"/>
			   <path fill="#878789" d="M8825 4515c431,-359 888,-166 1381,9l0 -1366c-234,345 -610,623 -1008,498 -24,-7 -47,-14 -70,-21 -1,0 -1,0 -1,0 -538,-156 -931,-127 -1167,-109 -246,18 -340,277 -340,277 70,212 119,406 -82,484 -102,40 -183,10 -255,-56 0,0 -85,138 44,316 41,56 79,130 103,210 240,230 873,193 1395,-242z"/>
			   <path fill="#494947" d="M4044 5761c-431,-258 -530,-820 -757,-1018 -299,-261 -573,-274 -870,-103 -296,172 -1545,70 -1545,-116l0 4214c0,0 64,1 166,8 -255,-332 1,-679 80,-719 101,-80 303,-163 666,-231 639,-118 715,-714 293,-963 -13,-7 -25,-14 -38,-21 -402,-211 -687,48 -830,-458 -86,-307 223,-435 460,-609 167,-123 216,-348 592,-310 298,30 615,327 862,515 97,75 183,132 253,150 246,62 643,-12 668,-339z"/>
			   <path fill="#494947" d="M7644 5806c93,207 -21,533 -167,646 -206,158 -308,127 -461,192 -62,27 -95,76 -107,144 105,106 424,125 1174,-99 751,-224 909,-101 944,266 11,117 -17,193 -65,246 0,0 807,7 760,585 0,0 627,580 -152,1059 -458,280 -685,472 -758,601 -52,91 -27,152 48,190 182,93 322,496 721,362 398,-133 190,-438 161,-649 -30,-212 260,-566 464,-609l0 -4213c-75,114 -91,348 -205,658 -206,560 -915,734 -1402,678 -486,-56 -955,-57 -955,-57z"/>
			   <path fill="#494947" d="M5948 6663c-155,-142 -291,-264 -226,-557 41,-185 138,-309 262,-383 0,0 -376,129 -620,-58 259,222 224,634 -291,727 -528,95 -768,182 -765,468 3,285 634,330 253,678 -141,129 -322,158 -511,139 -317,749 -663,736 -1396,743 -732,6 -634,527 -634,527 466,103 1114,3 1690,-501 639,-559 1061,-746 1296,-522 234,224 -18,646 -528,951 -510,304 -310,475 -310,475 151,26 334,37 526,-93 116,-77 316,-434 690,-568 107,-39 231,-70 352,-88 -130,-70 -154,-534 301,-500 457,34 464,431 786,533 323,103 489,28 574,-96 -25,-138 -103,-282 -249,-426 -413,-407 95,-773 95,-773 -143,-37 -255,-48 -281,-4 -41,315 -397,754 -783,484 -397,-278 -259,-487 -187,-574 -183,55 -207,-74 -277,-267 -71,-192 233,-315 233,-315z"/>
			   <path fill="#494947" d="M5823 5246c185,49 436,-188 683,-112 248,76 496,208 771,74 188,-91 203,-282 153,-451 -24,-80 -62,-154 -103,-210 -129,-178 -44,-316 -44,-316 -142,-131 -251,-405 -428,-566 -267,-242 -561,-18 -576,292 -7,149 133,278 176,396 66,184 53,417 -239,449 -254,27 -391,-114 -460,-320 -30,24 -440,-248 -423,37 0,11 2,23 4,35 52,342 264,132 357,263 50,69 31,172 30,260 0,78 13,145 99,169z"/>
			   <path fill="#494947" d="M7857 1992c112,41 160,251 156,399 -3,144 -49,267 -110,377 -101,381 -296,529 149,629 446,99 672,-880 959,-1153 104,-99 249,-140 403,-151 77,-6 156,-4 233,0 195,12 380,47 490,42 23,-1 46,-3 69,-6l0 -1021c-92,-32 -186,-43 -271,-23 -354,80 -525,208 -1161,18 -637,-190 -1165,-299 -1560,-87 -395,213 -374,777 -45,781 95,-134 207,-229 366,-170 243,90 155,303 322,365z"/>
			   <path fill="#494947" d="M1358 1752c23,-86 24,-176 -2,-262 -51,-170 -205,-322 -484,-382l0 1022c263,-37 406,-88 486,-378z"/>
			   <path fill="#B4B4B6" d="M3304 2235c56,122 -113,265 -360,378 -317,145 -1507,-92 -1706,103 400,-53 1141,290 1284,370 818,458 1237,16 1272,-289 34,-297 -434,-582 -490,-562z"/>
			   <path fill="#B4B4B6" d="M3280 1791c491,-131 1343,-616 1658,-1217l-1875 0c261,0 222,405 44,458 -179,53 -404,194 -369,365 35,171 173,267 343,186 158,-75 273,168 199,208z"/>
			   <path fill="#B4B4B6" d="M1785 906c-39,-196 -214,-332 -214,-332l-699 0c0,0 591,80 913,332z"/>
			   <path fill="#878789" d="M1571 574c0,0 175,136 214,332 17,14 34,28 50,43 305,286 510,59 633,-9 123,-69 334,-366 595,-366l-1492 0z"/>
			   <path fill="#B4B4B6" d="M5592 1292c340,103 809,796 65,984 195,40 412,95 670,146 628,125 575,-184 704,-400 44,-75 89,-155 138,-225 -329,-4 -350,-568 45,-781 395,-212 923,-103 1560,87 -9,-123 92,-218 243,-292 -249,-237 -576,-128 -881,-72 -304,56 -504,-40 -744,-53 -241,-12 -437,258 -504,482 -68,224 -430,435 -628,146 -198,-289 -255,-740 -548,-612 -293,128 -460,488 -120,590z"/>
			   <path fill="#B4B4B6" d="M9011 2244c-287,273 -513,1252 -959,1153 -445,-100 -250,-248 -149,-629 -155,275 -413,468 -377,708 16,105 58,218 94,327 0,0 94,-259 340,-277 236,-18 629,-47 1167,109 -26,-11 -419,-275 -23,-641 405,-373 310,-901 310,-901 -154,11 -299,52 -403,151z"/>
			   <path fill="#B4B4B6" d="M5701 3850c16,-226 -40,-478 -365,-511 -48,-5 -101,1 -158,3 -413,17 -609,-128 -670,-317 -94,411 6,1191 825,1494 -17,-285 393,-13 423,-37 -62,-185 -69,-421 -55,-632z"/>
			   <path fill="#B4B4B6" d="M8825 4515c-522,435 -1155,472 -1395,242 50,169 35,360 -153,451 -275,134 -523,2 -771,-74 -247,-76 -498,161 -683,112 -86,-24 -99,-91 -99,-169 -4,61 -99,354 -381,571 7,6 14,11 21,17 244,187 620,58 620,58 174,-103 400,-107 599,-42 292,96 422,-118 832,-44 119,22 191,86 229,169 0,0 469,1 955,57 487,56 1196,-118 1402,-678 114,-310 130,-544 205,-658l0 -3c-493,-175 -950,-368 -1381,-9z"/>
			   <path fill="#B4B4B6" d="M9027 6955c-35,-367 -193,-490 -944,-266 -750,224 -1069,205 -1174,99 -18,99 8,240 49,416 9,39 10,83 4,131 26,-44 138,-33 281,4 171,44 385,124 547,195 350,153 601,148 798,-101 84,-106 273,-119 374,-232 48,-53 76,-129 65,-246z"/>
			   <path fill="#B4B4B6" d="M6014 7219c53,-64 269,-225 27,-469 -31,-31 -62,-59 -93,-87 0,0 -304,123 -233,315 70,193 94,322 277,267 9,-10 16,-19 22,-26z"/>
			   <path fill="#B4B4B6" d="M4050 7677c-321,-32 -664,-203 -871,-253 -328,-81 -510,111 -815,534 -305,423 -563,371 -727,396 -164,25 -563,-47 -598,-159 -15,-49 2,-108 79,-168 -79,40 -335,387 -80,719 195,15 530,54 836,160 47,16 95,30 146,41 0,0 -98,-521 634,-527 733,-7 1079,6 1396,-743z"/>
			   <path fill="#B4B4B6" d="M1820 6195c244,-330 829,-29 1010,-70 181,-41 261,-115 293,-175 -247,-188 -564,-485 -862,-515 -376,-38 -425,187 -592,310 -237,174 -546,302 -460,609 143,506 428,247 830,458 0,0 -462,-288 -219,-617z"/>
			   <path fill="#B4B4B6" d="M6037 8101c-455,-34 -431,430 -301,500 205,-31 403,-22 504,61 176,146 133,258 142,397 139,65 394,387 720,129 210,-167 341,-398 295,-650 -85,124 -251,199 -574,96 -322,-102 -329,-499 -786,-533z"/>
			   <path fill="#B4B4B6" d="M9722 7786c-47,578 12,647 -369,790 -381,142 -678,295 -725,437 -47,142 -9,327 184,433 73,-129 300,-321 758,-601 779,-479 152,-1059 152,-1059z"/>
			   <path fill="#B4B4B6" d="M5234 10100c-129,-131 -302,-140 -771,-22 -41,10 -78,18 -111,24 -160,383 -646,141 -895,209 -252,69 -311,157 -400,157l1880 0c85,-186 313,-352 313,-352 -6,-6 -11,-11 -16,-16z"/>
			   <path fill="#B4B4B6" d="M1568 10468c-150,-335 -696,-411 -696,-411l0 411 696 0z"/>
			   <path fill="#FEFEFE" d="M8774 1103c636,190 807,62 1161,-18 85,-20 179,-9 271,23l0 -534c0,0 -780,39 -1189,237 -151,74 -252,169 -243,292z"/>
			   <path fill="#FEFEFE" d="M9647 2093c-77,-4 -156,-6 -233,0 0,0 95,528 -310,901 -396,366 -3,630 23,641 23,7 47,14 71,21 398,125 774,-153 1008,-498l0 -756c-141,58 -274,73 -336,-60 -91,-195 -223,-249 -223,-249z"/>
			   <path fill="#878789" d="M9647 2093c0,0 132,54 223,249 62,133 195,118 336,60l0 -273c-23,3 -46,5 -69,6 -110,5 -295,-30 -490,-42z"/>
			   <path fill="#FEFEFE" d="M3081 1583c-170,81 -308,-15 -343,-186 -35,-171 190,-312 369,-365 178,-53 217,-458 -44,-458 -261,0 -472,297 -595,366 -123,68 -328,295 -633,9 -16,-15 -33,-29 -50,-43 -322,-252 -913,-332 -913,-332l0 534c279,60 433,212 484,382 0,0 452,-65 801,118 349,184 506,227 914,217 57,-1 128,-13 209,-34 74,-40 -41,-283 -199,-208z"/>
			   <path fill="#FEFEFE" d="M2872 2041c-1153,-202 -1290,291 -2000,361l0 756c0,0 83,-27 115,-229 21,-132 117,-195 251,-213 199,-195 1389,42 1706,-103 247,-113 416,-256 360,-378 -37,-78 -167,-147 -432,-194z"/>
			   <path fill="#FEFEFE" d="M4904 2259c-258,67 -492,471 -396,766 61,189 257,334 670,317 57,-2 110,-8 158,-3 325,33 381,285 365,511 -14,211 -7,447 55,632 69,206 206,347 460,320 292,-32 305,-265 239,-449 -43,-118 -183,-247 -176,-396 15,-310 309,-534 576,-292 177,161 286,435 428,566 72,66 153,96 255,56 201,-78 152,-272 82,-484 -36,-109 -78,-222 -94,-327 -36,-240 222,-433 377,-708 61,-110 107,-233 110,-377 4,-148 -44,-358 -156,-399 -167,-62 -79,-275 -322,-365 -159,-59 -271,36 -366,170 -49,70 -94,150 -138,225 -129,216 -76,525 -704,400 -258,-51 -475,-106 -670,-146 -278,-56 -510,-81 -753,-17z"/>
			   <path fill="#FEFEFE" d="M7415 5637c-410,-74 -540,140 -832,44 -199,-65 -425,-61 -599,42 -124,74 -221,198 -262,383 -65,293 71,415 226,557 31,28 62,56 93,87 242,244 26,405 -27,469 -6,7 -13,16 -22,26 -72,87 -210,296 187,574 386,270 742,-169 783,-484 6,-48 5,-92 -4,-131 -41,-176 -67,-317 -49,-416 12,-68 45,-117 107,-144 153,-65 255,-34 461,-192 146,-113 260,-439 167,-646 -38,-83 -110,-147 -229,-169z"/>
			   <path fill="#FEFEFE" d="M5073 6392c515,-93 550,-505 291,-727 -7,-5 -14,-11 -21,-17 -177,138 -429,245 -778,245 -216,0 -385,-51 -521,-132 -25,327 -422,401 -668,339 -70,-18 -156,-75 -253,-150 -32,60 -112,134 -293,175 -181,41 -766,-260 -1010,70 -243,329 219,617 219,617 13,7 25,14 38,21 422,249 346,845 -293,963 -363,68 -565,151 -666,231 -77,60 -94,119 -79,168 35,112 434,184 598,159 164,-25 422,27 727,-396 305,-423 487,-615 815,-534 207,50 550,221 871,253 189,19 370,-10 511,-139 381,-348 -250,-393 -253,-678 -3,-286 237,-373 765,-468z"/>
			   <path fill="#FEFEFE" d="M5736 8601c-121,18 -245,49 -352,88 -374,134 -574,491 -690,568 -192,130 -375,119 -526,93 -34,-6 -65,-12 -95,-18 -166,-33 -447,178 -242,439 188,238 168,396 521,331 33,-6 70,-14 111,-24 469,-118 642,-109 771,22 5,5 10,10 16,16 121,125 220,246 428,240 217,-6 442,-179 360,-331 -82,-153 -256,-221 -256,-332 0,-112 152,-135 284,-112 132,22 301,90 374,34 73,-56 160,-134 31,-314 -67,-93 -85,-171 -89,-242 -9,-139 34,-251 -142,-397 -101,-83 -299,-92 -504,-61z"/>
			   <path fill="#878789" d="M4044 5761c136,81 305,132 521,132 349,0 601,-107 778,-245 282,-217 377,-510 381,-571 1,-88 20,-191 -30,-260 -93,-131 -305,79 -357,-263 -2,-12 -4,-24 -4,-35 -819,-303 -919,-1083 -825,-1494 -96,-295 138,-699 396,-766 243,-64 475,-39 753,17 744,-188 275,-881 -65,-984 -340,-102 -173,-462 120,-590 293,-128 350,323 548,612 198,289 560,78 628,-146 67,-224 263,-494 504,-482 240,13 440,109 744,53 305,-56 632,-165 881,72 409,-198 1189,-237 1189,-237l-5268 0c-315,601 -1167,1086 -1658,1217 -81,21 -152,33 -209,34 -408,10 -565,-33 -914,-217 -349,-183 -801,-118 -801,-118 26,86 25,176 2,262 -80,290 -223,341 -486,378l0 272c710,-70 847,-563 2000,-361 265,47 395,116 432,194 56,-20 524,265 490,562 -35,305 -454,747 -1272,289 -143,-80 -884,-423 -1284,-370 -134,18 -230,81 -251,213 -32,202 -115,229 -115,229l0 1366c0,186 1249,288 1545,116 297,-171 571,-158 870,103 227,198 326,760 757,1018z"/>
			   <path fill="#878789" d="M7243 7339c0,0 -508,366 -95,773 146,144 224,288 249,426 46,252 -85,483 -295,650 -326,258 -581,-64 -720,-129 4,71 22,149 89,242 129,180 42,258 -31,314 -73,56 -242,-12 -374,-34 -132,-23 -284,0 -284,112 0,111 174,179 256,332 82,152 -143,325 -360,331 -208,6 -307,-115 -428,-240 0,0 -228,166 -313,352l5269 0 0 -1728c-204,43 -494,397 -464,609 29,211 237,516 -161,649 -399,134 -539,-269 -721,-362 -75,-38 -100,-99 -48,-190 -193,-106 -231,-291 -184,-433 47,-142 344,-295 725,-437 381,-143 322,-212 369,-790 47,-578 -760,-585 -760,-585 -101,113 -290,126 -374,232 -197,249 -448,254 -798,101 -162,-71 -376,-151 -547,-195z"/>
			   <path fill="#878789" d="M4478 8875c510,-305 762,-727 528,-951 -235,-224 -657,-37 -1296,522 -576,504 -1224,604 -1690,501 -51,-11 -99,-25 -146,-41 -306,-106 -641,-145 -836,-160 -102,-7 -166,-8 -166,-8l0 1319c0,0 546,76 696,411l1489 0c89,0 148,-88 400,-157 249,-68 735,174 895,-209 -353,65 -333,-93 -521,-331 -205,-261 76,-472 242,-439 30,6 61,12 95,18 0,0 -200,-171 310,-475z"/>
			   <path fill="#878789" d="M8825 4515c431,-359 888,-166 1381,9l0 -1366c-234,345 -610,623 -1008,498 -24,-7 -47,-14 -70,-21 -1,0 -1,0 -1,0 -538,-156 -931,-127 -1167,-109 -246,18 -340,277 -340,277 70,212 119,406 -82,484 -102,40 -183,10 -255,-56 0,0 -85,138 44,316 41,56 79,130 103,210 240,230 873,193 1395,-242z"/>
			   <path fill="#494947" d="M4044 5761c-431,-258 -530,-820 -757,-1018 -299,-261 -573,-274 -870,-103 -296,172 -1545,70 -1545,-116l0 4214c0,0 64,1 166,8 -255,-332 1,-679 80,-719 101,-80 303,-163 666,-231 639,-118 715,-714 293,-963 -13,-7 -25,-14 -38,-21 -402,-211 -687,48 -830,-458 -86,-307 223,-435 460,-609 167,-123 216,-348 592,-310 298,30 615,327 862,515 97,75 183,132 253,150 246,62 643,-12 668,-339z"/>
			   <path fill="#494947" d="M7644 5806c93,207 -21,533 -167,646 -206,158 -308,127 -461,192 -62,27 -95,76 -107,144 105,106 424,125 1174,-99 751,-224 909,-101 944,266 11,117 -17,193 -65,246 0,0 807,7 760,585 0,0 627,580 -152,1059 -458,280 -685,472 -758,601 -52,91 -27,152 48,190 182,93 322,496 721,362 398,-133 190,-438 161,-649 -30,-212 260,-566 464,-609l0 -4213c-75,114 -91,348 -205,658 -206,560 -915,734 -1402,678 -486,-56 -955,-57 -955,-57z"/>
			   <path fill="#494947" d="M5948 6663c-155,-142 -291,-264 -226,-557 41,-185 138,-309 262,-383 0,0 -376,129 -620,-58 259,222 224,634 -291,727 -528,95 -768,182 -765,468 3,285 634,330 253,678 -141,129 -322,158 -511,139 -317,749 -663,736 -1396,743 -732,6 -634,527 -634,527 466,103 1114,3 1690,-501 639,-559 1061,-746 1296,-522 234,224 -18,646 -528,951 -510,304 -310,475 -310,475 151,26 334,37 526,-93 116,-77 316,-434 690,-568 107,-39 231,-70 352,-88 -130,-70 -154,-534 301,-500 457,34 464,431 786,533 323,103 489,28 574,-96 -25,-138 -103,-282 -249,-426 -413,-407 95,-773 95,-773 -143,-37 -255,-48 -281,-4 -41,315 -397,754 -783,484 -397,-278 -259,-487 -187,-574 -183,55 -207,-74 -277,-267 -71,-192 233,-315 233,-315z"/>
			   <path fill="#494947" d="M5823 5246c185,49 436,-188 683,-112 248,76 496,208 771,74 188,-91 203,-282 153,-451 -24,-80 -62,-154 -103,-210 -129,-178 -44,-316 -44,-316 -142,-131 -251,-405 -428,-566 -267,-242 -561,-18 -576,292 -7,149 133,278 176,396 66,184 53,417 -239,449 -254,27 -391,-114 -460,-320 -30,24 -440,-248 -423,37 0,11 2,23 4,35 52,342 264,132 357,263 50,69 31,172 30,260 0,78 13,145 99,169z"/>
			   <path fill="#494947" d="M7857 1992c112,41 160,251 156,399 -3,144 -49,267 -110,377 -101,381 -296,529 149,629 446,99 672,-880 959,-1153 104,-99 249,-140 403,-151 77,-6 156,-4 233,0 195,12 380,47 490,42 23,-1 46,-3 69,-6l0 -1021c-92,-32 -186,-43 -271,-23 -354,80 -525,208 -1161,18 -637,-190 -1165,-299 -1560,-87 -395,213 -374,777 -45,781 95,-134 207,-229 366,-170 243,90 155,303 322,365z"/>
			   <path fill="#494947" d="M1358 1752c23,-86 24,-176 -2,-262 -51,-170 -205,-322 -484,-382l0 1022c263,-37 406,-88 486,-378z"/>
			   <path fill="#B4B4B6" d="M3304 2235c56,122 -113,265 -360,378 -317,145 -1507,-92 -1706,103 400,-53 1141,290 1284,370 818,458 1237,16 1272,-289 34,-297 -434,-582 -490,-562z"/>
			   <path fill="#B4B4B6" d="M3280 1791c491,-131 1343,-616 1658,-1217l-1875 0c261,0 222,405 44,458 -179,53 -404,194 -369,365 35,171 173,267 343,186 158,-75 273,168 199,208z"/>
			   <path fill="#B4B4B6" d="M1785 906c-39,-196 -214,-332 -214,-332l-699 0c0,0 591,80 913,332z"/>
			   <path fill="#878789" d="M1571 574c0,0 175,136 214,332 17,14 34,28 50,43 305,286 510,59 633,-9 123,-69 334,-366 595,-366l-1492 0z"/>
			   <path fill="#B4B4B6" d="M5592 1292c340,103 809,796 65,984 195,40 412,95 670,146 628,125 575,-184 704,-400 44,-75 89,-155 138,-225 -329,-4 -350,-568 45,-781 395,-212 923,-103 1560,87 -9,-123 92,-218 243,-292 -249,-237 -576,-128 -881,-72 -304,56 -504,-40 -744,-53 -241,-12 -437,258 -504,482 -68,224 -430,435 -628,146 -198,-289 -255,-740 -548,-612 -293,128 -460,488 -120,590z"/>
			   <path fill="#B4B4B6" d="M9011 2244c-287,273 -513,1252 -959,1153 -445,-100 -250,-248 -149,-629 -155,275 -413,468 -377,708 16,105 58,218 94,327 0,0 94,-259 340,-277 236,-18 629,-47 1167,109 -26,-11 -419,-275 -23,-641 405,-373 310,-901 310,-901 -154,11 -299,52 -403,151z"/>
			   <path fill="#B4B4B6" d="M5701 3850c16,-226 -40,-478 -365,-511 -48,-5 -101,1 -158,3 -413,17 -609,-128 -670,-317 -94,411 6,1191 825,1494 -17,-285 393,-13 423,-37 -62,-185 -69,-421 -55,-632z"/>
			   <path fill="#B4B4B6" d="M8825 4515c-522,435 -1155,472 -1395,242 50,169 35,360 -153,451 -275,134 -523,2 -771,-74 -247,-76 -498,161 -683,112 -86,-24 -99,-91 -99,-169 -4,61 -99,354 -381,571 7,6 14,11 21,17 244,187 620,58 620,58 174,-103 400,-107 599,-42 292,96 422,-118 832,-44 119,22 191,86 229,169 0,0 469,1 955,57 487,56 1196,-118 1402,-678 114,-310 130,-544 205,-658l0 -3c-493,-175 -950,-368 -1381,-9z"/>
			   <path fill="#B4B4B6" d="M9027 6955c-35,-367 -193,-490 -944,-266 -750,224 -1069,205 -1174,99 -18,99 8,240 49,416 9,39 10,83 4,131 26,-44 138,-33 281,4 171,44 385,124 547,195 350,153 601,148 798,-101 84,-106 273,-119 374,-232 48,-53 76,-129 65,-246z"/>
			   <path fill="#B4B4B6" d="M6014 7219c53,-64 269,-225 27,-469 -31,-31 -62,-59 -93,-87 0,0 -304,123 -233,315 70,193 94,322 277,267 9,-10 16,-19 22,-26z"/>
			   <path fill="#B4B4B6" d="M4050 7677c-321,-32 -664,-203 -871,-253 -328,-81 -510,111 -815,534 -305,423 -563,371 -727,396 -164,25 -563,-47 -598,-159 -15,-49 2,-108 79,-168 -79,40 -335,387 -80,719 195,15 530,54 836,160 47,16 95,30 146,41 0,0 -98,-521 634,-527 733,-7 1079,6 1396,-743z"/>
			   <path fill="#B4B4B6" d="M1820 6195c244,-330 829,-29 1010,-70 181,-41 261,-115 293,-175 -247,-188 -564,-485 -862,-515 -376,-38 -425,187 -592,310 -237,174 -546,302 -460,609 143,506 428,247 830,458 0,0 -462,-288 -219,-617z"/>
			   <path fill="#B4B4B6" d="M6037 8101c-455,-34 -431,430 -301,500 205,-31 403,-22 504,61 176,146 133,258 142,397 139,65 394,387 720,129 210,-167 341,-398 295,-650 -85,124 -251,199 -574,96 -322,-102 -329,-499 -786,-533z"/>
			   <path fill="#B4B4B6" d="M9722 7786c-47,578 12,647 -369,790 -381,142 -678,295 -725,437 -47,142 -9,327 184,433 73,-129 300,-321 758,-601 779,-479 152,-1059 152,-1059z"/>
			   <path fill="#B4B4B6" d="M5234 10100c-129,-131 -302,-140 -771,-22 -41,10 -78,18 -111,24 -160,383 -646,141 -895,209 -252,69 -311,157 -400,157l1880 0c85,-186 313,-352 313,-352 -6,-6 -11,-11 -16,-16z"/>
			   <path fill="#B4B4B6" d="M1568 10468c-150,-335 -696,-411 -696,-411l0 411 696 0z"/>
			   <path fill="#FEFEFE" d="M8774 1103c636,190 807,62 1161,-18 85,-20 179,-9 271,23l0 -534c0,0 -780,39 -1189,237 -151,74 -252,169 -243,292z"/>
			   <path fill="#FEFEFE" d="M9647 2093c-77,-4 -156,-6 -233,0 0,0 95,528 -310,901 -396,366 -3,630 23,641 23,7 47,14 71,21 398,125 774,-153 1008,-498l0 -756c-141,58 -274,73 -336,-60 -91,-195 -223,-249 -223,-249z"/>
			   <path fill="#878789" d="M9647 2093c0,0 132,54 223,249 62,133 195,118 336,60l0 -273c-23,3 -46,5 -69,6 -110,5 -295,-30 -490,-42z"/>
			   <path fill="#FEFEFE" d="M3081 1583c-170,81 -308,-15 -343,-186 -35,-171 190,-312 369,-365 178,-53 217,-458 -44,-458 -261,0 -472,297 -595,366 -123,68 -328,295 -633,9 -16,-15 -33,-29 -50,-43 -322,-252 -913,-332 -913,-332l0 534c279,60 433,212 484,382 0,0 452,-65 801,118 349,184 506,227 914,217 57,-1 128,-13 209,-34 74,-40 -41,-283 -199,-208z"/>
			   <path fill="#FEFEFE" d="M2872 2041c-1153,-202 -1290,291 -2000,361l0 756c0,0 83,-27 115,-229 21,-132 117,-195 251,-213 199,-195 1389,42 1706,-103 247,-113 416,-256 360,-378 -37,-78 -167,-147 -432,-194z"/>
			   <path fill="#FEFEFE" d="M4904 2259c-258,67 -492,471 -396,766 61,189 257,334 670,317 57,-2 110,-8 158,-3 325,33 381,285 365,511 -14,211 -7,447 55,632 69,206 206,347 460,320 292,-32 305,-265 239,-449 -43,-118 -183,-247 -176,-396 15,-310 309,-534 576,-292 177,161 286,435 428,566 72,66 153,96 255,56 201,-78 152,-272 82,-484 -36,-109 -78,-222 -94,-327 -36,-240 222,-433 377,-708 61,-110 107,-233 110,-377 4,-148 -44,-358 -156,-399 -167,-62 -79,-275 -322,-365 -159,-59 -271,36 -366,170 -49,70 -94,150 -138,225 -129,216 -76,525 -704,400 -258,-51 -475,-106 -670,-146 -278,-56 -510,-81 -753,-17z"/>
			   <path fill="#FEFEFE" d="M7415 5637c-410,-74 -540,140 -832,44 -199,-65 -425,-61 -599,42 -124,74 -221,198 -262,383 -65,293 71,415 226,557 31,28 62,56 93,87 242,244 26,405 -27,469 -6,7 -13,16 -22,26 -72,87 -210,296 187,574 386,270 742,-169 783,-484 6,-48 5,-92 -4,-131 -41,-176 -67,-317 -49,-416 12,-68 45,-117 107,-144 153,-65 255,-34 461,-192 146,-113 260,-439 167,-646 -38,-83 -110,-147 -229,-169z"/>
			   <path fill="#FEFEFE" d="M5073 6392c515,-93 550,-505 291,-727 -7,-5 -14,-11 -21,-17 -177,138 -429,245 -778,245 -216,0 -385,-51 -521,-132 -25,327 -422,401 -668,339 -70,-18 -156,-75 -253,-150 -32,60 -112,134 -293,175 -181,41 -766,-260 -1010,70 -243,329 219,617 219,617 13,7 25,14 38,21 422,249 346,845 -293,963 -363,68 -565,151 -666,231 -77,60 -94,119 -79,168 35,112 434,184 598,159 164,-25 422,27 727,-396 305,-423 487,-615 815,-534 207,50 550,221 871,253 189,19 370,-10 511,-139 381,-348 -250,-393 -253,-678 -3,-286 237,-373 765,-468z"/>
			   <path fill="#FEFEFE" d="M5736 8601c-121,18 -245,49 -352,88 -374,134 -574,491 -690,568 -192,130 -375,119 -526,93 -34,-6 -65,-12 -95,-18 -166,-33 -447,178 -242,439 188,238 168,396 521,331 33,-6 70,-14 111,-24 469,-118 642,-109 771,22 5,5 10,10 16,16 121,125 220,246 428,240 217,-6 442,-179 360,-331 -82,-153 -256,-221 -256,-332 0,-112 152,-135 284,-112 132,22 301,90 374,34 73,-56 160,-134 31,-314 -67,-93 -85,-171 -89,-242 -9,-139 34,-251 -142,-397 -101,-83 -299,-92 -504,-61z"/>
			   <path fill="#878789" d="M4044 5761c136,81 305,132 521,132 349,0 601,-107 778,-245 282,-217 377,-510 381,-571 1,-88 20,-191 -30,-260 -93,-131 -305,79 -357,-263 -2,-12 -4,-24 -4,-35 -819,-303 -919,-1083 -825,-1494 -96,-295 138,-699 396,-766 243,-64 475,-39 753,17 744,-188 275,-881 -65,-984 -340,-102 -173,-462 120,-590 293,-128 350,323 548,612 198,289 560,78 628,-146 67,-224 263,-494 504,-482 240,13 440,109 744,53 305,-56 632,-165 881,72 409,-198 1189,-237 1189,-237l-5268 0c-315,601 -1167,1086 -1658,1217 -81,21 -152,33 -209,34 -408,10 -565,-33 -914,-217 -349,-183 -801,-118 -801,-118 26,86 25,176 2,262 -80,290 -223,341 -486,378l0 272c710,-70 847,-563 2000,-361 265,47 395,116 432,194 56,-20 524,265 490,562 -35,305 -454,747 -1272,289 -143,-80 -884,-423 -1284,-370 -134,18 -230,81 -251,213 -32,202 -115,229 -115,229l0 1366c0,186 1249,288 1545,116 297,-171 571,-158 870,103 227,198 326,760 757,1018z"/>
			   <path fill="#878789" d="M7243 7339c0,0 -508,366 -95,773 146,144 224,288 249,426 46,252 -85,483 -295,650 -326,258 -581,-64 -720,-129 4,71 22,149 89,242 129,180 42,258 -31,314 -73,56 -242,-12 -374,-34 -132,-23 -284,0 -284,112 0,111 174,179 256,332 82,152 -143,325 -360,331 -208,6 -307,-115 -428,-240 0,0 -228,166 -313,352l5269 0 0 -1728c-204,43 -494,397 -464,609 29,211 237,516 -161,649 -399,134 -539,-269 -721,-362 -75,-38 -100,-99 -48,-190 -193,-106 -231,-291 -184,-433 47,-142 344,-295 725,-437 381,-143 322,-212 369,-790 47,-578 -760,-585 -760,-585 -101,113 -290,126 -374,232 -197,249 -448,254 -798,101 -162,-71 -376,-151 -547,-195z"/>
			   <path fill="#878789" d="M4478 8875c510,-305 762,-727 528,-951 -235,-224 -657,-37 -1296,522 -576,504 -1224,604 -1690,501 -51,-11 -99,-25 -146,-41 -306,-106 -641,-145 -836,-160 -102,-7 -166,-8 -166,-8l0 1319c0,0 546,76 696,411l1489 0c89,0 148,-88 400,-157 249,-68 735,174 895,-209 -353,65 -333,-93 -521,-331 -205,-261 76,-472 242,-439 30,6 61,12 95,18 0,0 -200,-171 310,-475z"/>
			   <path fill="#878789" d="M8825 4515c431,-359 888,-166 1381,9l0 -1366c-234,345 -610,623 -1008,498 -24,-7 -47,-14 -70,-21 -1,0 -1,0 -1,0 -538,-156 -931,-127 -1167,-109 -246,18 -340,277 -340,277 70,212 119,406 -82,484 -102,40 -183,10 -255,-56 0,0 -85,138 44,316 41,56 79,130 103,210 240,230 873,193 1395,-242z"/>
			   <path fill="#494947" d="M4044 5761c-431,-258 -530,-820 -757,-1018 -299,-261 -573,-274 -870,-103 -296,172 -1545,70 -1545,-116l0 4214c0,0 64,1 166,8 -255,-332 1,-679 80,-719 101,-80 303,-163 666,-231 639,-118 715,-714 293,-963 -13,-7 -25,-14 -38,-21 -402,-211 -687,48 -830,-458 -86,-307 223,-435 460,-609 167,-123 216,-348 592,-310 298,30 615,327 862,515 97,75 183,132 253,150 246,62 643,-12 668,-339z"/>
			   <path fill="#494947" d="M7644 5806c93,207 -21,533 -167,646 -206,158 -308,127 -461,192 -62,27 -95,76 -107,144 105,106 424,125 1174,-99 751,-224 909,-101 944,266 11,117 -17,193 -65,246 0,0 807,7 760,585 0,0 627,580 -152,1059 -458,280 -685,472 -758,601 -52,91 -27,152 48,190 182,93 322,496 721,362 398,-133 190,-438 161,-649 -30,-212 260,-566 464,-609l0 -4213c-75,114 -91,348 -205,658 -206,560 -915,734 -1402,678 -486,-56 -955,-57 -955,-57z"/>
			   <path fill="#494947" d="M5948 6663c-155,-142 -291,-264 -226,-557 41,-185 138,-309 262,-383 0,0 -376,129 -620,-58 259,222 224,634 -291,727 -528,95 -768,182 -765,468 3,285 634,330 253,678 -141,129 -322,158 -511,139 -317,749 -663,736 -1396,743 -732,6 -634,527 -634,527 466,103 1114,3 1690,-501 639,-559 1061,-746 1296,-522 234,224 -18,646 -528,951 -510,304 -310,475 -310,475 151,26 334,37 526,-93 116,-77 316,-434 690,-568 107,-39 231,-70 352,-88 -130,-70 -154,-534 301,-500 457,34 464,431 786,533 323,103 489,28 574,-96 -25,-138 -103,-282 -249,-426 -413,-407 95,-773 95,-773 -143,-37 -255,-48 -281,-4 -41,315 -397,754 -783,484 -397,-278 -259,-487 -187,-574 -183,55 -207,-74 -277,-267 -71,-192 233,-315 233,-315z"/>
			   <path fill="#494947" d="M5823 5246c185,49 436,-188 683,-112 248,76 496,208 771,74 188,-91 203,-282 153,-451 -24,-80 -62,-154 -103,-210 -129,-178 -44,-316 -44,-316 -142,-131 -251,-405 -428,-566 -267,-242 -561,-18 -576,292 -7,149 133,278 176,396 66,184 53,417 -239,449 -254,27 -391,-114 -460,-320 -30,24 -440,-248 -423,37 0,11 2,23 4,35 52,342 264,132 357,263 50,69 31,172 30,260 0,78 13,145 99,169z"/>
			   <path fill="#494947" d="M7857 1992c112,41 160,251 156,399 -3,144 -49,267 -110,377 -101,381 -296,529 149,629 446,99 672,-880 959,-1153 104,-99 249,-140 403,-151 77,-6 156,-4 233,0 195,12 380,47 490,42 23,-1 46,-3 69,-6l0 -1021c-92,-32 -186,-43 -271,-23 -354,80 -525,208 -1161,18 -637,-190 -1165,-299 -1560,-87 -395,213 -374,777 -45,781 95,-134 207,-229 366,-170 243,90 155,303 322,365z"/>
			   <path fill="#494947" d="M1358 1752c23,-86 24,-176 -2,-262 -51,-170 -205,-322 -484,-382l0 1022c263,-37 406,-88 486,-378z"/>
			   <path fill="#B4B4B6" d="M3304 2235c56,122 -113,265 -360,378 -317,145 -1507,-92 -1706,103 400,-53 1141,290 1284,370 818,458 1237,16 1272,-289 34,-297 -434,-582 -490,-562z"/>
			   <path fill="#B4B4B6" d="M3280 1791c491,-131 1343,-616 1658,-1217l-1875 0c261,0 222,405 44,458 -179,53 -404,194 -369,365 35,171 173,267 343,186 158,-75 273,168 199,208z"/>
			   <path fill="#B4B4B6" d="M1785 906c-39,-196 -214,-332 -214,-332l-699 0c0,0 591,80 913,332z"/>
			   <path fill="#878789" d="M1571 574c0,0 175,136 214,332 17,14 34,28 50,43 305,286 510,59 633,-9 123,-69 334,-366 595,-366l-1492 0z"/>
			   <path fill="#B4B4B6" d="M5592 1292c340,103 809,796 65,984 195,40 412,95 670,146 628,125 575,-184 704,-400 44,-75 89,-155 138,-225 -329,-4 -350,-568 45,-781 395,-212 923,-103 1560,87 -9,-123 92,-218 243,-292 -249,-237 -576,-128 -881,-72 -304,56 -504,-40 -744,-53 -241,-12 -437,258 -504,482 -68,224 -430,435 -628,146 -198,-289 -255,-740 -548,-612 -293,128 -460,488 -120,590z"/>
			   <path fill="#B4B4B6" d="M9011 2244c-287,273 -513,1252 -959,1153 -445,-100 -250,-248 -149,-629 -155,275 -413,468 -377,708 16,105 58,218 94,327 0,0 94,-259 340,-277 236,-18 629,-47 1167,109 -26,-11 -419,-275 -23,-641 405,-373 310,-901 310,-901 -154,11 -299,52 -403,151z"/>
			   <path fill="#B4B4B6" d="M5701 3850c16,-226 -40,-478 -365,-511 -48,-5 -101,1 -158,3 -413,17 -609,-128 -670,-317 -94,411 6,1191 825,1494 -17,-285 393,-13 423,-37 -62,-185 -69,-421 -55,-632z"/>
			   <path fill="#B4B4B6" d="M8825 4515c-522,435 -1155,472 -1395,242 50,169 35,360 -153,451 -275,134 -523,2 -771,-74 -247,-76 -498,161 -683,112 -86,-24 -99,-91 -99,-169 -4,61 -99,354 -381,571 7,6 14,11 21,17 244,187 620,58 620,58 174,-103 400,-107 599,-42 292,96 422,-118 832,-44 119,22 191,86 229,169 0,0 469,1 955,57 487,56 1196,-118 1402,-678 114,-310 130,-544 205,-658l0 -3c-493,-175 -950,-368 -1381,-9z"/>
			   <path fill="#B4B4B6" d="M9027 6955c-35,-367 -193,-490 -944,-266 -750,224 -1069,205 -1174,99 -18,99 8,240 49,416 9,39 10,83 4,131 26,-44 138,-33 281,4 171,44 385,124 547,195 350,153 601,148 798,-101 84,-106 273,-119 374,-232 48,-53 76,-129 65,-246z"/>
			   <path fill="#B4B4B6" d="M6014 7219c53,-64 269,-225 27,-469 -31,-31 -62,-59 -93,-87 0,0 -304,123 -233,315 70,193 94,322 277,267 9,-10 16,-19 22,-26z"/>
			   <path fill="#B4B4B6" d="M4050 7677c-321,-32 -664,-203 -871,-253 -328,-81 -510,111 -815,534 -305,423 -563,371 -727,396 -164,25 -563,-47 -598,-159 -15,-49 2,-108 79,-168 -79,40 -335,387 -80,719 195,15 530,54 836,160 47,16 95,30 146,41 0,0 -98,-521 634,-527 733,-7 1079,6 1396,-743z"/>
			   <path fill="#B4B4B6" d="M1820 6195c244,-330 829,-29 1010,-70 181,-41 261,-115 293,-175 -247,-188 -564,-485 -862,-515 -376,-38 -425,187 -592,310 -237,174 -546,302 -460,609 143,506 428,247 830,458 0,0 -462,-288 -219,-617z"/>
			   <path fill="#B4B4B6" d="M6037 8101c-455,-34 -431,430 -301,500 205,-31 403,-22 504,61 176,146 133,258 142,397 139,65 394,387 720,129 210,-167 341,-398 295,-650 -85,124 -251,199 -574,96 -322,-102 -329,-499 -786,-533z"/>
			   <path fill="#B4B4B6" d="M9722 7786c-47,578 12,647 -369,790 -381,142 -678,295 -725,437 -47,142 -9,327 184,433 73,-129 300,-321 758,-601 779,-479 152,-1059 152,-1059z"/>
			   <path fill="#B4B4B6" d="M5234 10100c-129,-131 -302,-140 -771,-22 -41,10 -78,18 -111,24 -160,383 -646,141 -895,209 -252,69 -311,157 -400,157l1880 0c85,-186 313,-352 313,-352 -6,-6 -11,-11 -16,-16z"/>
			   <path fill="#B4B4B6" d="M1568 10468c-150,-335 -696,-411 -696,-411l0 411 696 0z"/>
			   <path fill="#FEFEFE" d="M8774 1103c636,190 807,62 1161,-18 85,-20 179,-9 271,23l0 -534c0,0 -780,39 -1189,237 -151,74 -252,169 -243,292z"/>
			   <path fill="#FEFEFE" d="M9647 2093c-77,-4 -156,-6 -233,0 0,0 95,528 -310,901 -396,366 -3,630 23,641 23,7 47,14 71,21 398,125 774,-153 1008,-498l0 -756c-141,58 -274,73 -336,-60 -91,-195 -223,-249 -223,-249z"/>
			   <path fill="#878789" d="M9647 2093c0,0 132,54 223,249 62,133 195,118 336,60l0 -273c-23,3 -46,5 -69,6 -110,5 -295,-30 -490,-42z"/>
			   <path fill="#FEFEFE" d="M3081 1583c-170,81 -308,-15 -343,-186 -35,-171 190,-312 369,-365 178,-53 217,-458 -44,-458 -261,0 -472,297 -595,366 -123,68 -328,295 -633,9 -16,-15 -33,-29 -50,-43 -322,-252 -913,-332 -913,-332l0 534c279,60 433,212 484,382 0,0 452,-65 801,118 349,184 506,227 914,217 57,-1 128,-13 209,-34 74,-40 -41,-283 -199,-208z"/>
			   <path fill="#FEFEFE" d="M2872 2041c-1153,-202 -1290,291 -2000,361l0 756c0,0 83,-27 115,-229 21,-132 117,-195 251,-213 199,-195 1389,42 1706,-103 247,-113 416,-256 360,-378 -37,-78 -167,-147 -432,-194z"/>
			   <path fill="#FEFEFE" d="M4904 2259c-258,67 -492,471 -396,766 61,189 257,334 670,317 57,-2 110,-8 158,-3 325,33 381,285 365,511 -14,211 -7,447 55,632 69,206 206,347 460,320 292,-32 305,-265 239,-449 -43,-118 -183,-247 -176,-396 15,-310 309,-534 576,-292 177,161 286,435 428,566 72,66 153,96 255,56 201,-78 152,-272 82,-484 -36,-109 -78,-222 -94,-327 -36,-240 222,-433 377,-708 61,-110 107,-233 110,-377 4,-148 -44,-358 -156,-399 -167,-62 -79,-275 -322,-365 -159,-59 -271,36 -366,170 -49,70 -94,150 -138,225 -129,216 -76,525 -704,400 -258,-51 -475,-106 -670,-146 -278,-56 -510,-81 -753,-17z"/>
			   <path fill="#FEFEFE" d="M7415 5637c-410,-74 -540,140 -832,44 -199,-65 -425,-61 -599,42 -124,74 -221,198 -262,383 -65,293 71,415 226,557 31,28 62,56 93,87 242,244 26,405 -27,469 -6,7 -13,16 -22,26 -72,87 -210,296 187,574 386,270 742,-169 783,-484 6,-48 5,-92 -4,-131 -41,-176 -67,-317 -49,-416 12,-68 45,-117 107,-144 153,-65 255,-34 461,-192 146,-113 260,-439 167,-646 -38,-83 -110,-147 -229,-169z"/>
			   <path fill="#FEFEFE" d="M5073 6392c515,-93 550,-505 291,-727 -7,-5 -14,-11 -21,-17 -177,138 -429,245 -778,245 -216,0 -385,-51 -521,-132 -25,327 -422,401 -668,339 -70,-18 -156,-75 -253,-150 -32,60 -112,134 -293,175 -181,41 -766,-260 -1010,70 -243,329 219,617 219,617 13,7 25,14 38,21 422,249 346,845 -293,963 -363,68 -565,151 -666,231 -77,60 -94,119 -79,168 35,112 434,184 598,159 164,-25 422,27 727,-396 305,-423 487,-615 815,-534 207,50 550,221 871,253 189,19 370,-10 511,-139 381,-348 -250,-393 -253,-678 -3,-286 237,-373 765,-468z"/>
			   <path fill="#FEFEFE" d="M5736 8601c-121,18 -245,49 -352,88 -374,134 -574,491 -690,568 -192,130 -375,119 -526,93 -34,-6 -65,-12 -95,-18 -166,-33 -447,178 -242,439 188,238 168,396 521,331 33,-6 70,-14 111,-24 469,-118 642,-109 771,22 5,5 10,10 16,16 121,125 220,246 428,240 217,-6 442,-179 360,-331 -82,-153 -256,-221 -256,-332 0,-112 152,-135 284,-112 132,22 301,90 374,34 73,-56 160,-134 31,-314 -67,-93 -85,-171 -89,-242 -9,-139 34,-251 -142,-397 -101,-83 -299,-92 -504,-61z"/>
			   <path fill="#878789" d="M4044 5761c136,81 305,132 521,132 349,0 601,-107 778,-245 282,-217 377,-510 381,-571 1,-88 20,-191 -30,-260 -93,-131 -305,79 -357,-263 -2,-12 -4,-24 -4,-35 -819,-303 -919,-1083 -825,-1494 -96,-295 138,-699 396,-766 243,-64 475,-39 753,17 744,-188 275,-881 -65,-984 -340,-102 -173,-462 120,-590 293,-128 350,323 548,612 198,289 560,78 628,-146 67,-224 263,-494 504,-482 240,13 440,109 744,53 305,-56 632,-165 881,72 409,-198 1189,-237 1189,-237l-5268 0c-315,601 -1167,1086 -1658,1217 -81,21 -152,33 -209,34 -408,10 -565,-33 -914,-217 -349,-183 -801,-118 -801,-118 26,86 25,176 2,262 -80,290 -223,341 -486,378l0 272c710,-70 847,-563 2000,-361 265,47 395,116 432,194 56,-20 524,265 490,562 -35,305 -454,747 -1272,289 -143,-80 -884,-423 -1284,-370 -134,18 -230,81 -251,213 -32,202 -115,229 -115,229l0 1366c0,186 1249,288 1545,116 297,-171 571,-158 870,103 227,198 326,760 757,1018z"/>
			   <path fill="#878789" d="M7243 7339c0,0 -508,366 -95,773 146,144 224,288 249,426 46,252 -85,483 -295,650 -326,258 -581,-64 -720,-129 4,71 22,149 89,242 129,180 42,258 -31,314 -73,56 -242,-12 -374,-34 -132,-23 -284,0 -284,112 0,111 174,179 256,332 82,152 -143,325 -360,331 -208,6 -307,-115 -428,-240 0,0 -228,166 -313,352l5269 0 0 -1728c-204,43 -494,397 -464,609 29,211 237,516 -161,649 -399,134 -539,-269 -721,-362 -75,-38 -100,-99 -48,-190 -193,-106 -231,-291 -184,-433 47,-142 344,-295 725,-437 381,-143 322,-212 369,-790 47,-578 -760,-585 -760,-585 -101,113 -290,126 -374,232 -197,249 -448,254 -798,101 -162,-71 -376,-151 -547,-195z"/>
			   <path fill="#878789" d="M4478 8875c510,-305 762,-727 528,-951 -235,-224 -657,-37 -1296,522 -576,504 -1224,604 -1690,501 -51,-11 -99,-25 -146,-41 -306,-106 -641,-145 -836,-160 -102,-7 -166,-8 -166,-8l0 1319c0,0 546,76 696,411l1489 0c89,0 148,-88 400,-157 249,-68 735,174 895,-209 -353,65 -333,-93 -521,-331 -205,-261 76,-472 242,-439 30,6 61,12 95,18 0,0 -200,-171 310,-475z"/>
			   <path fill="#878789" d="M8825 4515c431,-359 888,-166 1381,9l0 -1366c-234,345 -610,623 -1008,498 -24,-7 -47,-14 -70,-21 -1,0 -1,0 -1,0 -538,-156 -931,-127 -1167,-109 -246,18 -340,277 -340,277 70,212 119,406 -82,484 -102,40 -183,10 -255,-56 0,0 -85,138 44,316 41,56 79,130 103,210 240,230 873,193 1395,-242z"/>
			   <path fill="#494947" d="M4044 5761c-431,-258 -530,-820 -757,-1018 -299,-261 -573,-274 -870,-103 -296,172 -1545,70 -1545,-116l0 4214c0,0 64,1 166,8 -255,-332 1,-679 80,-719 101,-80 303,-163 666,-231 639,-118 715,-714 293,-963 -13,-7 -25,-14 -38,-21 -402,-211 -687,48 -830,-458 -86,-307 223,-435 460,-609 167,-123 216,-348 592,-310 298,30 615,327 862,515 97,75 183,132 253,150 246,62 643,-12 668,-339z"/>
			   <path fill="#494947" d="M7644 5806c93,207 -21,533 -167,646 -206,158 -308,127 -461,192 -62,27 -95,76 -107,144 105,106 424,125 1174,-99 751,-224 909,-101 944,266 11,117 -17,193 -65,246 0,0 807,7 760,585 0,0 627,580 -152,1059 -458,280 -685,472 -758,601 -52,91 -27,152 48,190 182,93 322,496 721,362 398,-133 190,-438 161,-649 -30,-212 260,-566 464,-609l0 -4213c-75,114 -91,348 -205,658 -206,560 -915,734 -1402,678 -486,-56 -955,-57 -955,-57z"/>
			   <path fill="#494947" d="M5948 6663c-155,-142 -291,-264 -226,-557 41,-185 138,-309 262,-383 0,0 -376,129 -620,-58 259,222 224,634 -291,727 -528,95 -768,182 -765,468 3,285 634,330 253,678 -141,129 -322,158 -511,139 -317,749 -663,736 -1396,743 -732,6 -634,527 -634,527 466,103 1114,3 1690,-501 639,-559 1061,-746 1296,-522 234,224 -18,646 -528,951 -510,304 -310,475 -310,475 151,26 334,37 526,-93 116,-77 316,-434 690,-568 107,-39 231,-70 352,-88 -130,-70 -154,-534 301,-500 457,34 464,431 786,533 323,103 489,28 574,-96 -25,-138 -103,-282 -249,-426 -413,-407 95,-773 95,-773 -143,-37 -255,-48 -281,-4 -41,315 -397,754 -783,484 -397,-278 -259,-487 -187,-574 -183,55 -207,-74 -277,-267 -71,-192 233,-315 233,-315z"/>
			   <path fill="#494947" d="M5823 5246c185,49 436,-188 683,-112 248,76 496,208 771,74 188,-91 203,-282 153,-451 -24,-80 -62,-154 -103,-210 -129,-178 -44,-316 -44,-316 -142,-131 -251,-405 -428,-566 -267,-242 -561,-18 -576,292 -7,149 133,278 176,396 66,184 53,417 -239,449 -254,27 -391,-114 -460,-320 -30,24 -440,-248 -423,37 0,11 2,23 4,35 52,342 264,132 357,263 50,69 31,172 30,260 0,78 13,145 99,169z"/>
			   <path fill="#494947" d="M7857 1992c112,41 160,251 156,399 -3,144 -49,267 -110,377 -101,381 -296,529 149,629 446,99 672,-880 959,-1153 104,-99 249,-140 403,-151 77,-6 156,-4 233,0 195,12 380,47 490,42 23,-1 46,-3 69,-6l0 -1021c-92,-32 -186,-43 -271,-23 -354,80 -525,208 -1161,18 -637,-190 -1165,-299 -1560,-87 -395,213 -374,777 -45,781 95,-134 207,-229 366,-170 243,90 155,303 322,365z"/>
			   <path fill="#494947" d="M1358 1752c23,-86 24,-176 -2,-262 -51,-170 -205,-322 -484,-382l0 1022c263,-37 406,-88 486,-378z"/>
			   <path fill="#B4B4B6" d="M3304 2235c56,122 -113,265 -360,378 -317,145 -1507,-92 -1706,103 400,-53 1141,290 1284,370 818,458 1237,16 1272,-289 34,-297 -434,-582 -490,-562z"/>
			   <path fill="#B4B4B6" d="M3280 1791c491,-131 1343,-616 1658,-1217l-1875 0c261,0 222,405 44,458 -179,53 -404,194 -369,365 35,171 173,267 343,186 158,-75 273,168 199,208z"/>
			   <path fill="#B4B4B6" d="M1785 906c-39,-196 -214,-332 -214,-332l-699 0c0,0 591,80 913,332z"/>
			   <path fill="#878789" d="M1571 574c0,0 175,136 214,332 17,14 34,28 50,43 305,286 510,59 633,-9 123,-69 334,-366 595,-366l-1492 0z"/>
			   <path fill="#B4B4B6" d="M5592 1292c340,103 809,796 65,984 195,40 412,95 670,146 628,125 575,-184 704,-400 44,-75 89,-155 138,-225 -329,-4 -350,-568 45,-781 395,-212 923,-103 1560,87 -9,-123 92,-218 243,-292 -249,-237 -576,-128 -881,-72 -304,56 -504,-40 -744,-53 -241,-12 -437,258 -504,482 -68,224 -430,435 -628,146 -198,-289 -255,-740 -548,-612 -293,128 -460,488 -120,590z"/>
			   <path fill="#B4B4B6" d="M9011 2244c-287,273 -513,1252 -959,1153 -445,-100 -250,-248 -149,-629 -155,275 -413,468 -377,708 16,105 58,218 94,327 0,0 94,-259 340,-277 236,-18 629,-47 1167,109 -26,-11 -419,-275 -23,-641 405,-373 310,-901 310,-901 -154,11 -299,52 -403,151z"/>
			   <path fill="#B4B4B6" d="M5701 3850c16,-226 -40,-478 -365,-511 -48,-5 -101,1 -158,3 -413,17 -609,-128 -670,-317 -94,411 6,1191 825,1494 -17,-285 393,-13 423,-37 -62,-185 -69,-421 -55,-632z"/>
			   <path fill="#B4B4B6" d="M8825 4515c-522,435 -1155,472 -1395,242 50,169 35,360 -153,451 -275,134 -523,2 -771,-74 -247,-76 -498,161 -683,112 -86,-24 -99,-91 -99,-169 -4,61 -99,354 -381,571 7,6 14,11 21,17 244,187 620,58 620,58 174,-103 400,-107 599,-42 292,96 422,-118 832,-44 119,22 191,86 229,169 0,0 469,1 955,57 487,56 1196,-118 1402,-678 114,-310 130,-544 205,-658l0 -3c-493,-175 -950,-368 -1381,-9z"/>
			   <path fill="#B4B4B6" d="M9027 6955c-35,-367 -193,-490 -944,-266 -750,224 -1069,205 -1174,99 -18,99 8,240 49,416 9,39 10,83 4,131 26,-44 138,-33 281,4 171,44 385,124 547,195 350,153 601,148 798,-101 84,-106 273,-119 374,-232 48,-53 76,-129 65,-246z"/>
			   <path fill="#B4B4B6" d="M6014 7219c53,-64 269,-225 27,-469 -31,-31 -62,-59 -93,-87 0,0 -304,123 -233,315 70,193 94,322 277,267 9,-10 16,-19 22,-26z"/>
			   <path fill="#B4B4B6" d="M4050 7677c-321,-32 -664,-203 -871,-253 -328,-81 -510,111 -815,534 -305,423 -563,371 -727,396 -164,25 -563,-47 -598,-159 -15,-49 2,-108 79,-168 -79,40 -335,387 -80,719 195,15 530,54 836,160 47,16 95,30 146,41 0,0 -98,-521 634,-527 733,-7 1079,6 1396,-743z"/>
			   <path fill="#B4B4B6" d="M1820 6195c244,-330 829,-29 1010,-70 181,-41 261,-115 293,-175 -247,-188 -564,-485 -862,-515 -376,-38 -425,187 -592,310 -237,174 -546,302 -460,609 143,506 428,247 830,458 0,0 -462,-288 -219,-617z"/>
			   <path fill="#B4B4B6" d="M6037 8101c-455,-34 -431,430 -301,500 205,-31 403,-22 504,61 176,146 133,258 142,397 139,65 394,387 720,129 210,-167 341,-398 295,-650 -85,124 -251,199 -574,96 -322,-102 -329,-499 -786,-533z"/>
			   <path fill="#B4B4B6" d="M9722 7786c-47,578 12,647 -369,790 -381,142 -678,295 -725,437 -47,142 -9,327 184,433 73,-129 300,-321 758,-601 779,-479 152,-1059 152,-1059z"/>
			   <path fill="#B4B4B6" d="M5234 10100c-129,-131 -302,-140 -771,-22 -41,10 -78,18 -111,24 -160,383 -646,141 -895,209 -252,69 -311,157 -400,157l1880 0c85,-186 313,-352 313,-352 -6,-6 -11,-11 -16,-16z"/>
			   <path fill="#B4B4B6" d="M1568 10468c-150,-335 -696,-411 -696,-411l0 411 696 0z"/>
			   <path fill="#FEFEFE" d="M8774 1103c636,190 807,62 1161,-18 85,-20 179,-9 271,23l0 -534c0,0 -780,39 -1189,237 -151,74 -252,169 -243,292z"/>
			   <path fill="#FEFEFE" d="M9647 2093c-77,-4 -156,-6 -233,0 0,0 95,528 -310,901 -396,366 -3,630 23,641 23,7 47,14 71,21 398,125 774,-153 1008,-498l0 -756c-141,58 -274,73 -336,-60 -91,-195 -223,-249 -223,-249z"/>
			   <path fill="#878789" d="M9647 2093c0,0 132,54 223,249 62,133 195,118 336,60l0 -273c-23,3 -46,5 -69,6 -110,5 -295,-30 -490,-42z"/>
			   <path fill="#FEFEFE" d="M3081 1583c-170,81 -308,-15 -343,-186 -35,-171 190,-312 369,-365 178,-53 217,-458 -44,-458 -261,0 -472,297 -595,366 -123,68 -328,295 -633,9 -16,-15 -33,-29 -50,-43 -322,-252 -913,-332 -913,-332l0 534c279,60 433,212 484,382 0,0 452,-65 801,118 349,184 506,227 914,217 57,-1 128,-13 209,-34 74,-40 -41,-283 -199,-208z"/>
			   <path fill="#FEFEFE" d="M2872 2041c-1153,-202 -1290,291 -2000,361l0 756c0,0 83,-27 115,-229 21,-132 117,-195 251,-213 199,-195 1389,42 1706,-103 247,-113 416,-256 360,-378 -37,-78 -167,-147 -432,-194z"/>
			   <path fill="#FEFEFE" d="M4904 2259c-258,67 -492,471 -396,766 61,189 257,334 670,317 57,-2 110,-8 158,-3 325,33 381,285 365,511 -14,211 -7,447 55,632 69,206 206,347 460,320 292,-32 305,-265 239,-449 -43,-118 -183,-247 -176,-396 15,-310 309,-534 576,-292 177,161 286,435 428,566 72,66 153,96 255,56 201,-78 152,-272 82,-484 -36,-109 -78,-222 -94,-327 -36,-240 222,-433 377,-708 61,-110 107,-233 110,-377 4,-148 -44,-358 -156,-399 -167,-62 -79,-275 -322,-365 -159,-59 -271,36 -366,170 -49,70 -94,150 -138,225 -129,216 -76,525 -704,400 -258,-51 -475,-106 -670,-146 -278,-56 -510,-81 -753,-17z"/>
			   <path fill="#FEFEFE" d="M7415 5637c-410,-74 -540,140 -832,44 -199,-65 -425,-61 -599,42 -124,74 -221,198 -262,383 -65,293 71,415 226,557 31,28 62,56 93,87 242,244 26,405 -27,469 -6,7 -13,16 -22,26 -72,87 -210,296 187,574 386,270 742,-169 783,-484 6,-48 5,-92 -4,-131 -41,-176 -67,-317 -49,-416 12,-68 45,-117 107,-144 153,-65 255,-34 461,-192 146,-113 260,-439 167,-646 -38,-83 -110,-147 -229,-169z"/>
			   <path fill="#FEFEFE" d="M5073 6392c515,-93 550,-505 291,-727 -7,-5 -14,-11 -21,-17 -177,138 -429,245 -778,245 -216,0 -385,-51 -521,-132 -25,327 -422,401 -668,339 -70,-18 -156,-75 -253,-150 -32,60 -112,134 -293,175 -181,41 -766,-260 -1010,70 -243,329 219,617 219,617 13,7 25,14 38,21 422,249 346,845 -293,963 -363,68 -565,151 -666,231 -77,60 -94,119 -79,168 35,112 434,184 598,159 164,-25 422,27 727,-396 305,-423 487,-615 815,-534 207,50 550,221 871,253 189,19 370,-10 511,-139 381,-348 -250,-393 -253,-678 -3,-286 237,-373 765,-468z"/>
			   <path fill="#FEFEFE" d="M5736 8601c-121,18 -245,49 -352,88 -374,134 -574,491 -690,568 -192,130 -375,119 -526,93 -34,-6 -65,-12 -95,-18 -166,-33 -447,178 -242,439 188,238 168,396 521,331 33,-6 70,-14 111,-24 469,-118 642,-109 771,22 5,5 10,10 16,16 121,125 220,246 428,240 217,-6 442,-179 360,-331 -82,-153 -256,-221 -256,-332 0,-112 152,-135 284,-112 132,22 301,90 374,34 73,-56 160,-134 31,-314 -67,-93 -85,-171 -89,-242 -9,-139 34,-251 -142,-397 -101,-83 -299,-92 -504,-61z"/>
			   <path fill="#878789" d="M4044 5761c136,81 305,132 521,132 349,0 601,-107 778,-245 282,-217 377,-510 381,-571 1,-88 20,-191 -30,-260 -93,-131 -305,79 -357,-263 -2,-12 -4,-24 -4,-35 -819,-303 -919,-1083 -825,-1494 -96,-295 138,-699 396,-766 243,-64 475,-39 753,17 744,-188 275,-881 -65,-984 -340,-102 -173,-462 120,-590 293,-128 350,323 548,612 198,289 560,78 628,-146 67,-224 263,-494 504,-482 240,13 440,109 744,53 305,-56 632,-165 881,72 409,-198 1189,-237 1189,-237l-5268 0c-315,601 -1167,1086 -1658,1217 -81,21 -152,33 -209,34 -408,10 -565,-33 -914,-217 -349,-183 -801,-118 -801,-118 26,86 25,176 2,262 -80,290 -223,341 -486,378l0 272c710,-70 847,-563 2000,-361 265,47 395,116 432,194 56,-20 524,265 490,562 -35,305 -454,747 -1272,289 -143,-80 -884,-423 -1284,-370 -134,18 -230,81 -251,213 -32,202 -115,229 -115,229l0 1366c0,186 1249,288 1545,116 297,-171 571,-158 870,103 227,198 326,760 757,1018z"/>
			   <path fill="#878789" d="M7243 7339c0,0 -508,366 -95,773 146,144 224,288 249,426 46,252 -85,483 -295,650 -326,258 -581,-64 -720,-129 4,71 22,149 89,242 129,180 42,258 -31,314 -73,56 -242,-12 -374,-34 -132,-23 -284,0 -284,112 0,111 174,179 256,332 82,152 -143,325 -360,331 -208,6 -307,-115 -428,-240 0,0 -228,166 -313,352l5269 0 0 -1728c-204,43 -494,397 -464,609 29,211 237,516 -161,649 -399,134 -539,-269 -721,-362 -75,-38 -100,-99 -48,-190 -193,-106 -231,-291 -184,-433 47,-142 344,-295 725,-437 381,-143 322,-212 369,-790 47,-578 -760,-585 -760,-585 -101,113 -290,126 -374,232 -197,249 -448,254 -798,101 -162,-71 -376,-151 -547,-195z"/>
			   <path fill="#878789" d="M4478 8875c510,-305 762,-727 528,-951 -235,-224 -657,-37 -1296,522 -576,504 -1224,604 -1690,501 -51,-11 -99,-25 -146,-41 -306,-106 -641,-145 -836,-160 -102,-7 -166,-8 -166,-8l0 1319c0,0 546,76 696,411l1489 0c89,0 148,-88 400,-157 249,-68 735,174 895,-209 -353,65 -333,-93 -521,-331 -205,-261 76,-472 242,-439 30,6 61,12 95,18 0,0 -200,-171 310,-475z"/>
			   <path fill="#878789" d="M8825 4515c431,-359 888,-166 1381,9l0 -1366c-234,345 -610,623 -1008,498 -24,-7 -47,-14 -70,-21 -1,0 -1,0 -1,0 -538,-156 -931,-127 -1167,-109 -246,18 -340,277 -340,277 70,212 119,406 -82,484 -102,40 -183,10 -255,-56 0,0 -85,138 44,316 41,56 79,130 103,210 240,230 873,193 1395,-242z"/>
			   <path fill="#494947" d="M4044 5761c-431,-258 -530,-820 -757,-1018 -299,-261 -573,-274 -870,-103 -296,172 -1545,70 -1545,-116l0 4214c0,0 64,1 166,8 -255,-332 1,-679 80,-719 101,-80 303,-163 666,-231 639,-118 715,-714 293,-963 -13,-7 -25,-14 -38,-21 -402,-211 -687,48 -830,-458 -86,-307 223,-435 460,-609 167,-123 216,-348 592,-310 298,30 615,327 862,515 97,75 183,132 253,150 246,62 643,-12 668,-339z"/>
			   <path fill="#494947" d="M7644 5806c93,207 -21,533 -167,646 -206,158 -308,127 -461,192 -62,27 -95,76 -107,144 105,106 424,125 1174,-99 751,-224 909,-101 944,266 11,117 -17,193 -65,246 0,0 807,7 760,585 0,0 627,580 -152,1059 -458,280 -685,472 -758,601 -52,91 -27,152 48,190 182,93 322,496 721,362 398,-133 190,-438 161,-649 -30,-212 260,-566 464,-609l0 -4213c-75,114 -91,348 -205,658 -206,560 -915,734 -1402,678 -486,-56 -955,-57 -955,-57z"/>
			   <path fill="#494947" d="M5948 6663c-155,-142 -291,-264 -226,-557 41,-185 138,-309 262,-383 0,0 -376,129 -620,-58 259,222 224,634 -291,727 -528,95 -768,182 -765,468 3,285 634,330 253,678 -141,129 -322,158 -511,139 -317,749 -663,736 -1396,743 -732,6 -634,527 -634,527 466,103 1114,3 1690,-501 639,-559 1061,-746 1296,-522 234,224 -18,646 -528,951 -510,304 -310,475 -310,475 151,26 334,37 526,-93 116,-77 316,-434 690,-568 107,-39 231,-70 352,-88 -130,-70 -154,-534 301,-500 457,34 464,431 786,533 323,103 489,28 574,-96 -25,-138 -103,-282 -249,-426 -413,-407 95,-773 95,-773 -143,-37 -255,-48 -281,-4 -41,315 -397,754 -783,484 -397,-278 -259,-487 -187,-574 -183,55 -207,-74 -277,-267 -71,-192 233,-315 233,-315z"/>
			   <path fill="#494947" d="M5823 5246c185,49 436,-188 683,-112 248,76 496,208 771,74 188,-91 203,-282 153,-451 -24,-80 -62,-154 -103,-210 -129,-178 -44,-316 -44,-316 -142,-131 -251,-405 -428,-566 -267,-242 -561,-18 -576,292 -7,149 133,278 176,396 66,184 53,417 -239,449 -254,27 -391,-114 -460,-320 -30,24 -440,-248 -423,37 0,11 2,23 4,35 52,342 264,132 357,263 50,69 31,172 30,260 0,78 13,145 99,169z"/>
			   <path fill="#494947" d="M7857 1992c112,41 160,251 156,399 -3,144 -49,267 -110,377 -101,381 -296,529 149,629 446,99 672,-880 959,-1153 104,-99 249,-140 403,-151 77,-6 156,-4 233,0 195,12 380,47 490,42 23,-1 46,-3 69,-6l0 -1021c-92,-32 -186,-43 -271,-23 -354,80 -525,208 -1161,18 -637,-190 -1165,-299 -1560,-87 -395,213 -374,777 -45,781 95,-134 207,-229 366,-170 243,90 155,303 322,365z"/>
			   <path fill="#494947" d="M1358 1752c23,-86 24,-176 -2,-262 -51,-170 -205,-322 -484,-382l0 1022c263,-37 406,-88 486,-378z"/>
			   <path fill="#B4B4B6" d="M3304 2235c56,122 -113,265 -360,378 -317,145 -1507,-92 -1706,103 400,-53 1141,290 1284,370 818,458 1237,16 1272,-289 34,-297 -434,-582 -490,-562z"/>
			   <path fill="#B4B4B6" d="M3280 1791c491,-131 1343,-616 1658,-1217l-1875 0c261,0 222,405 44,458 -179,53 -404,194 -369,365 35,171 173,267 343,186 158,-75 273,168 199,208z"/>
			   <path fill="#B4B4B6" d="M1785 906c-39,-196 -214,-332 -214,-332l-699 0c0,0 591,80 913,332z"/>
			   <path fill="#878789" d="M1571 574c0,0 175,136 214,332 17,14 34,28 50,43 305,286 510,59 633,-9 123,-69 334,-366 595,-366l-1492 0z"/>
			   <path fill="#B4B4B6" d="M5592 1292c340,103 809,796 65,984 195,40 412,95 670,146 628,125 575,-184 704,-400 44,-75 89,-155 138,-225 -329,-4 -350,-568 45,-781 395,-212 923,-103 1560,87 -9,-123 92,-218 243,-292 -249,-237 -576,-128 -881,-72 -304,56 -504,-40 -744,-53 -241,-12 -437,258 -504,482 -68,224 -430,435 -628,146 -198,-289 -255,-740 -548,-612 -293,128 -460,488 -120,590z"/>
			   <path fill="#B4B4B6" d="M9011 2244c-287,273 -513,1252 -959,1153 -445,-100 -250,-248 -149,-629 -155,275 -413,468 -377,708 16,105 58,218 94,327 0,0 94,-259 340,-277 236,-18 629,-47 1167,109 -26,-11 -419,-275 -23,-641 405,-373 310,-901 310,-901 -154,11 -299,52 -403,151z"/>
			   <path fill="#B4B4B6" d="M5701 3850c16,-226 -40,-478 -365,-511 -48,-5 -101,1 -158,3 -413,17 -609,-128 -670,-317 -94,411 6,1191 825,1494 -17,-285 393,-13 423,-37 -62,-185 -69,-421 -55,-632z"/>
			   <path fill="#B4B4B6" d="M8825 4515c-522,435 -1155,472 -1395,242 50,169 35,360 -153,451 -275,134 -523,2 -771,-74 -247,-76 -498,161 -683,112 -86,-24 -99,-91 -99,-169 -4,61 -99,354 -381,571 7,6 14,11 21,17 244,187 620,58 620,58 174,-103 400,-107 599,-42 292,96 422,-118 832,-44 119,22 191,86 229,169 0,0 469,1 955,57 487,56 1196,-118 1402,-678 114,-310 130,-544 205,-658l0 -3c-493,-175 -950,-368 -1381,-9z"/>
			   <path fill="#B4B4B6" d="M9027 6955c-35,-367 -193,-490 -944,-266 -750,224 -1069,205 -1174,99 -18,99 8,240 49,416 9,39 10,83 4,131 26,-44 138,-33 281,4 171,44 385,124 547,195 350,153 601,148 798,-101 84,-106 273,-119 374,-232 48,-53 76,-129 65,-246z"/>
			   <path fill="#B4B4B6" d="M6014 7219c53,-64 269,-225 27,-469 -31,-31 -62,-59 -93,-87 0,0 -304,123 -233,315 70,193 94,322 277,267 9,-10 16,-19 22,-26z"/>
			   <path fill="#B4B4B6" d="M4050 7677c-321,-32 -664,-203 -871,-253 -328,-81 -510,111 -815,534 -305,423 -563,371 -727,396 -164,25 -563,-47 -598,-159 -15,-49 2,-108 79,-168 -79,40 -335,387 -80,719 195,15 530,54 836,160 47,16 95,30 146,41 0,0 -98,-521 634,-527 733,-7 1079,6 1396,-743z"/>
			   <path fill="#B4B4B6" d="M1820 6195c244,-330 829,-29 1010,-70 181,-41 261,-115 293,-175 -247,-188 -564,-485 -862,-515 -376,-38 -425,187 -592,310 -237,174 -546,302 -460,609 143,506 428,247 830,458 0,0 -462,-288 -219,-617z"/>
			   <path fill="#B4B4B6" d="M6037 8101c-455,-34 -431,430 -301,500 205,-31 403,-22 504,61 176,146 133,258 142,397 139,65 394,387 720,129 210,-167 341,-398 295,-650 -85,124 -251,199 -574,96 -322,-102 -329,-499 -786,-533z"/>
			   <path fill="#B4B4B6" d="M9722 7786c-47,578 12,647 -369,790 -381,142 -678,295 -725,437 -47,142 -9,327 184,433 73,-129 300,-321 758,-601 779,-479 152,-1059 152,-1059z"/>
			   <path fill="#B4B4B6" d="M5234 10100c-129,-131 -302,-140 -771,-22 -41,10 -78,18 -111,24 -160,383 -646,141 -895,209 -252,69 -311,157 -400,157l1880 0c85,-186 313,-352 313,-352 -6,-6 -11,-11 -16,-16z"/>
			   <path fill="#B4B4B6" d="M1568 10468c-150,-335 -696,-411 -696,-411l0 411 696 0z"/>
			   <path fill="#FEFEFE" d="M8774 1103c636,190 807,62 1161,-18 85,-20 179,-9 271,23l0 -534c0,0 -780,39 -1189,237 -151,74 -252,169 -243,292z"/>
			   <path fill="#FEFEFE" d="M9647 2093c-77,-4 -156,-6 -233,0 0,0 95,528 -310,901 -396,366 -3,630 23,641 23,7 47,14 71,21 398,125 774,-153 1008,-498l0 -756c-141,58 -274,73 -336,-60 -91,-195 -223,-249 -223,-249z"/>
			   <path fill="#878789" d="M9647 2093c0,0 132,54 223,249 62,133 195,118 336,60l0 -273c-23,3 -46,5 -69,6 -110,5 -295,-30 -490,-42z"/>
			   <path fill="#FEFEFE" d="M3081 1583c-170,81 -308,-15 -343,-186 -35,-171 190,-312 369,-365 178,-53 217,-458 -44,-458 -261,0 -472,297 -595,366 -123,68 -328,295 -633,9 -16,-15 -33,-29 -50,-43 -322,-252 -913,-332 -913,-332l0 534c279,60 433,212 484,382 0,0 452,-65 801,118 349,184 506,227 914,217 57,-1 128,-13 209,-34 74,-40 -41,-283 -199,-208z"/>
			   <path fill="#FEFEFE" d="M2872 2041c-1153,-202 -1290,291 -2000,361l0 756c0,0 83,-27 115,-229 21,-132 117,-195 251,-213 199,-195 1389,42 1706,-103 247,-113 416,-256 360,-378 -37,-78 -167,-147 -432,-194z"/>
			   <path fill="#FEFEFE" d="M4904 2259c-258,67 -492,471 -396,766 61,189 257,334 670,317 57,-2 110,-8 158,-3 325,33 381,285 365,511 -14,211 -7,447 55,632 69,206 206,347 460,320 292,-32 305,-265 239,-449 -43,-118 -183,-247 -176,-396 15,-310 309,-534 576,-292 177,161 286,435 428,566 72,66 153,96 255,56 201,-78 152,-272 82,-484 -36,-109 -78,-222 -94,-327 -36,-240 222,-433 377,-708 61,-110 107,-233 110,-377 4,-148 -44,-358 -156,-399 -167,-62 -79,-275 -322,-365 -159,-59 -271,36 -366,170 -49,70 -94,150 -138,225 -129,216 -76,525 -704,400 -258,-51 -475,-106 -670,-146 -278,-56 -510,-81 -753,-17z"/>
			   <path fill="#FEFEFE" d="M7415 5637c-410,-74 -540,140 -832,44 -199,-65 -425,-61 -599,42 -124,74 -221,198 -262,383 -65,293 71,415 226,557 31,28 62,56 93,87 242,244 26,405 -27,469 -6,7 -13,16 -22,26 -72,87 -210,296 187,574 386,270 742,-169 783,-484 6,-48 5,-92 -4,-131 -41,-176 -67,-317 -49,-416 12,-68 45,-117 107,-144 153,-65 255,-34 461,-192 146,-113 260,-439 167,-646 -38,-83 -110,-147 -229,-169z"/>
			   <path fill="#FEFEFE" d="M5073 6392c515,-93 550,-505 291,-727 -7,-5 -14,-11 -21,-17 -177,138 -429,245 -778,245 -216,0 -385,-51 -521,-132 -25,327 -422,401 -668,339 -70,-18 -156,-75 -253,-150 -32,60 -112,134 -293,175 -181,41 -766,-260 -1010,70 -243,329 219,617 219,617 13,7 25,14 38,21 422,249 346,845 -293,963 -363,68 -565,151 -666,231 -77,60 -94,119 -79,168 35,112 434,184 598,159 164,-25 422,27 727,-396 305,-423 487,-615 815,-534 207,50 550,221 871,253 189,19 370,-10 511,-139 381,-348 -250,-393 -253,-678 -3,-286 237,-373 765,-468z"/>
			   <path fill="#FEFEFE" d="M5736 8601c-121,18 -245,49 -352,88 -374,134 -574,491 -690,568 -192,130 -375,119 -526,93 -34,-6 -65,-12 -95,-18 -166,-33 -447,178 -242,439 188,238 168,396 521,331 33,-6 70,-14 111,-24 469,-118 642,-109 771,22 5,5 10,10 16,16 121,125 220,246 428,240 217,-6 442,-179 360,-331 -82,-153 -256,-221 -256,-332 0,-112 152,-135 284,-112 132,22 301,90 374,34 73,-56 160,-134 31,-314 -67,-93 -85,-171 -89,-242 -9,-139 34,-251 -142,-397 -101,-83 -299,-92 -504,-61z"/>
			   <path fill="#B4B4B6" d="M10206 10057c0,0 -675,43 -1056,411l1056 0 0 -411z"/>
			   <path fill="#B4B4B6" d="M9168 574c-80,97 -151,237 -151,237 409,-198 1189,-237 1189,-237l-1038 0z"/>
			  </g>
			</pattern>
		    <clipPath id="id0">
		     <path d="M12664 9318c176,-26 344,100 376,282 31,181 -87,350 -263,376 -176,26 -344,-100 -375,-282 -32,-181 86,-350 262,-376z"/>
		    </clipPath>
		    <clipPath id="id1">
		     <path d="M7844 12799c164,47 258,230 209,407 -48,178 -220,284 -384,237 -164,-47 -258,-229 -209,-407 48,-177 220,-283 384,-237z"/>
		    </clipPath>
		    <clipPath id="id2">
		     <path d="M7920 14654c170,-20 331,110 360,292 29,182 -85,346 -255,366 -169,21 -330,-110 -359,-291 -29,-182 85,-346 254,-367z"/>
		    </clipPath>
		    <linearGradient id="id3" gradientUnits="userSpaceOnUse" x1="12377.4" y1="9647.09" x2="13063.7" y2="9647.09">
			   <stop offset="0" style={{stopOpacity:1, stopColor:"#332C2B"}}/>
		   <stop offset="0.168627" style={{stopOpacity:1, stopColor:"#454241"}}/>
		   <stop offset="0.341176" style={{stopOpacity:1, stopColor:"#BFBFBF"}}/>
		   <stop offset="0.521569" style={{stopOpacity:1, stopColor:"#746F6E"}}/>
		   <stop offset="0.678431" style={{stopOpacity:1, stopColor:"#626363"}}/>
		   <stop offset="0.85098" style={{stopOpacity:1, stopColor:"#D6D5D5"}}/>
		   <stop offset="1" style={{stopOpacity:1, stopColor:"#585858"}}/>
		  </linearGradient>
		  <linearGradient id="id4" gradientUnits="userSpaceOnUse" href="#id3" x1="7413.25" y1="13121.4" x2="8099.53" y2="13121.4">
		  </linearGradient>
		  <linearGradient id="id5" gradientUnits="userSpaceOnUse" href="#id3" x1="7629.54" y1="14983.4" x2="8315.82" y2="14983.4">
		  </linearGradient>
		</defs>
		 <g id="main">
		  <g id="hood">
		   <path className={"hood-" + config['hood-base-color']} d="M8986 3286c2400,90 3933,981 5158,2394 1000,1152 1425,4899 853,6051 -115,232 -344,3217 -644,4948 -45,263 -46,691 -203,858 -190,203 -726,646 -1927,607 -1034,-34 -2372,-288 -3169,-495 -2380,-619 -3869,-1559 -3938,-1800 -457,-1590 -1295,-3225 -1494,-5836 -213,-2800 293,-3778 749,-4522 849,-1385 2876,-2271 4615,-2205z"/>
		   <path className={"hood-" + config['crown-color']} d="M13697 5246c-140,-131 -282,-255 -423,-370 -5,-4 -9,-8 -14,-12 149,122 295,249 437,382zm428 428c-139,-148 -282,-291 -428,-428 -145,-136 -291,-263 -437,-382l-18 -14c-492,-398 -988,-703 -1475,-932 -1561,-736 -3037,-699 -4063,-507 -26,331 -56,694 -85,1085 -25,345 -41,625 -58,856 715,733 1670,1438 2916,1891 2122,770 4325,-7 3722,-1476l-74 -93z"/>
		   <path className="fil-eye-border" d="M7756 10852c307,1248 1737,1487 1784,1494 230,-16 440,-49 632,-94 696,-162 1153,-480 1407,-708 -133,-855 -861,-1448 -1865,-1514 -206,-14 -425,-5 -652,28 -803,117 -1263,704 -1306,794z"/>
		   <path className={"hood-" + config['chin-color'] + " str_332C2B_20"} d="M12504 18153c-1622,-32 -3457,-460 -4673,-848l507 849c489,156 2446,913 4078,549 650,-145 1152,-464 1637,-1076 -368,321 -861,540 -1549,526z"/>
		   <path className={"hood-" + config['ear-a-color'] + " str_332C2B_20"} d="M4979 4360c-8,89 -6,181 3,275 21,-18 344,-292 624,-490 289,-204 551,-631 652,-773 101,-142 338,-499 584,-413 246,81 301,1336 77,2443 -171,845 -707,1174 -1373,1908l366 286c792,-710 1174,-968 1402,-1267 238,-312 225,-748 304,-1837 28,-393 58,-756 84,-1088 99,-1253 136,-2050 -215,-2216 -94,-36 -172,-63 -242,-72 -53,-6 -101,-1 -147,19 -86,38 -151,169 -217,302 -231,458 -347,821 -677,1268 -344,439 -736,761 -1053,1172 0,0 0,0 0,0 -30,38 -59,77 -87,117 -60,108 -89,237 -95,381 3,-5 6,-10 10,-15z"/>
		   <path className={"hood-" + config['ear-c-color']} d="M13034 2126c-206,408 -463,1039 -1117,1641 -51,47 -98,95 -144,145 527,248 1019,563 1479,934 5,5 11,9 17,14 146,119 292,246 438,383 53,-153 126,-322 231,-514 539,-990 409,-1185 378,-2302 -31,-1117 130,-1413 -324,-1528 -455,-115 -752,820 -958,1227z"/>
		   <path className={"hood-" + config['ear-b-color']} d="M4988 4694c85,571 413,1233 648,1609 0,0 0,1 0,1 -254,830 -334,816 -334,816l244 190c666,-734 1202,-1063 1373,-1908 224,-1107 169,-2362 -77,-2443 -246,-86 -483,271 -584,413 -101,142 -363,569 -652,773 -280,198 -603,472 -624,490 2,20 4,39 6,59z"/>
		   <path className={"hood-" + config['ear-c-color'] + " str_332C2B_20"} d="M4647 6555c153,154 412,370 655,565 0,0 80,14 334,-816 -244,-393 -533,-994 -632,-1522 -10,-49 -17,-98 -23,-146 -11,-90 -16,-178 -12,-261 6,-144 35,-273 95,-381 -79,113 -153,233 -218,365 -189,383 -327,714 -395,1015 0,0 0,1 -1,1 -97,434 -50,805 197,1180z"/>
		   <path className="fil-eye-border" d="M14734 9743c0,0 484,632 -138,1671 45,35 89,70 134,106 510,-852 312,-1494 197,-1801 -68,3 -132,11 -193,24z"/>
		   <path className="clear" d="M6967 16743c-1634,-687 -1905,-1224 -2014,-1372 117,370 137,440 137,440 39,54 83,134 199,246 206,200 640,502 1685,941 225,93 517,198 857,307 1216,388 3051,816 4673,848 688,14 1181,-205 1549,-526 33,-29 66,-59 97,-90 38,-108 72,-229 100,-347 -377,369 -963,721 -1711,707 -2076,-41 -4543,-730 -5572,-1154z"/>
		   <path className="fil-eye" d="M8770 10196c-1000,488 -758,836 -703,966 490,972 1756,1122 2124,1075 704,-161 1167,-478 1424,-704 -134,-850 -871,-1440 -1887,-1506 -333,-14 -753,69 -958,169z"/>
		   <path className="fil-eye" d="M14734 9743c-750,159 -955,1015 -955,1015 173,142 484,391 817,656 622,-1039 138,-1671 138,-1671z"/>
		   <path className="clear str_222222_50" d="M4926 15286c-186,-686 -1523,-4023 -1364,-6982 58,-1082 290,-2082 889,-2930m3249 -1969c1543,-290 4102,-229 6444,2275 451,530 915,2104 954,2527 1,14 441,2501 -101,3524"/>
		   <path className="clear str_222222_50" d="M7561 5350c716,737 1668,1439 2920,1893 2126,772 4333,-7 3729,-1479"/>
		   <path className="clear str_222222_20_40_20" d="M7577 5208c716,739 1658,1446 2869,1905 2058,781 4337,78 3689,-1444"/>
		   <path className="clear str_222222_20_40_20" d="M7564 5505c760,752 1755,1454 2974,1876 2087,725 4308,-37 3729,-1478"/>
		   <path className="clear str_222222_50" d="M9496 12320c-275,-56 -1471,-361 -1750,-1476 43,-89 509,-672 1322,-789 1349,-195 2388,455 2548,1482"/>
		   <path className="clear str_222222_25" d="M10107 12244c-448,17 -1582,-174 -2040,-1082 -55,-130 -297,-478 703,-966 215,-105 668,-192 1008,-167"/>
		   <path className="clear str_222222_50" d="M13779 10758c0,0 239,-1000 1148,-1039 115,307 313,949 -197,1801"/>
		   <path className="clear str_222222_25" d="M14734 9743c0,0 484,632 -138,1671"/>
		   <path className="clear str_222222_75" d="M4926 15286c141,444 164,525 164,525 109,148 250,500 1884,1187 1029,424 3454,1114 5530,1155 751,15 1269,-247 1646,-616 124,-352 203,-858 203,-858"/>
		   <path className="clear str_222222_50" d="M4953 15371c109,148 380,685 2014,1372 1029,424 3496,1113 5572,1154 750,14 1337,-340 1714,-710"/>
		   <path className="clear str_222222_25_125_75" d="M5005 15555c148,211 533,682 1962,1283 1029,424 3496,1113 5572,1154 750,14 1337,-340 1714,-710"/>
		   <path className="clear str_222222_50" d="M7831 17305l507 849c489,156 2446,913 4078,549 650,-145 1152,-464 1637,-1076"/>
		   <path className="clear str_222222_75" d="M5912 7596c792,-710 1174,-968 1402,-1267 238,-312 225,-748 304,-1838 136,-1873 313,-3093 -131,-3303 -165,-64 -282,-99 -389,-53 -86,38 -151,169 -217,302 -231,458 -347,821 -677,1268 -467,596 -1022,976 -1358,1654 -463,934 -618,1561 -199,2196 353,354 1265,1041 1265,1041z"/>
		   <path className="clear str_222222_50" d="M5152 3875c-460,603 69,1761 484,2429 -254,830 -334,816 -334,816"/>
		   <path className="clear str_222222_50" d="M4981 4636c0,0 335,-286 625,-491 289,-204 551,-631 652,-773 101,-142 338,-499 584,-413 246,81 301,1336 77,2443 -172,850 -714,1178 -1387,1923"/>
		   <path className="clear str_222222_30" d="M5840 7517c696,-667 1099,-932 1317,-1252 234,-344 227,-753 305,-1819 135,-1874 239,-3176 -217,-3330"/>
		   <path className="clear str_222222_20_100_60" d="M5725 7434c696,-668 1022,-908 1240,-1228 234,-344 252,-742 329,-1807 136,-1874 213,-3041 -106,-3038 -135,11 -210,455 -662,1185 -452,730 -955,956 -1557,1829"/>
		   <path className="clear str_222222_20_100_60" d="M4827 4399c0,0 -182,562 617,1932 -106,313 -240,675 -240,675"/>
		   <path className="clear str_222222_20_100_60" d="M5022 4768c0,0 434,-344 682,-531 248,-186 492,-531 580,-659 88,-128 279,-520 484,-447 207,68 246,1218 21,2205 -78,340 -155,626 -367,898 -253,324 -655,624 -968,997"/>
		   <path className="clear str_222222_75" d="M11771 3915c46,-51 94,-100 146,-148 654,-602 911,-1233 1117,-1641 206,-407 503,-1342 958,-1227 454,115 293,411 324,1528 31,1117 161,1312 -378,2302 -106,194 -179,364 -233,518"/>
		   <path className="clear str_222222_30" d="M13832 910c455,115 254,400 284,1517 31,1117 161,1312 -378,2302 -69,127 -124,244 -169,352"/>
		   <path className="clear str_222222_25_125_75" d="M11906 3960c567,-496 801,-881 1161,-1587 151,-295 553,-1333 811,-1273 258,59 113,695 139,1547 26,852 139,1042 -230,1783 -72,143 -271,474 -309,585"/>
		   <path className="clear str_222222_25_125_75" d="M4589 6722c0,0 1299,1106 1343,1106"/>
		  </g>
		  {config['muzzle-style'] === 'default' || config['muzzle-style'] === 'added-default' ? <g id="muzzle-default">
		   <path className={"hood-" + config['muzzle-color'] + " str_332C2B_20"} d="M14214 13818c-529,-291 -497,-573 -492,-739 1,-23 3,-46 6,-67l-1861 -1751c0,0 -968,1339 -3319,1037 -243,-11 -1435,-140 -1625,1135 -122,867 152,1626 976,2208 531,346 1242,583 1582,773 342,191 759,503 1095,686 649,351 1666,724 3393,-412l245 -2870z"/>
		   <path className={"hood-" + config['bridge-color'] + " str_332C2B_20"} d="M13753 12905c230,-694 1650,-539 1985,-487 36,5 59,10 67,12 49,17 94,33 136,50 0,0 -1482,-1163 -2071,-1643 -126,-102 -210,-173 -232,-196 -124,-129 -170,-294 -226,-674 -21,-210 -118,-1054 -744,-989 -717,78 -632,895 -568,1132 158,714 -169,1087 -233,1151l1861 1751c5,-38 14,-73 25,-107z"/>
		   <path className={"hood-" + config['muzzle-lip-color']} d="M15416 14284c-55,8 -694,-249 -870,-314 -130,-50 -240,-101 -332,-152l-233 2726 -12 144c129,-2 256,-2 378,-1 429,2 804,18 1063,23 171,3 291,3 343,-10 17,-4 27,-9 28,-16 97,-735 363,-2853 341,-3074 -55,70 -123,138 -204,216 -125,120 -289,265 -502,458z"/>
		   <path className={"hood-" + config['nose-color']} d="M13946 13037c0,0 0,0 0,0 0,0 0,0 0,0zm-29 -19c0,0 0,0 0,0 9,6 19,13 29,19 -10,-7 -19,-13 -29,-19zm-164 -113c-11,34 -20,69 -25,107 -3,21 -5,44 -6,67 -5,166 -37,448 492,739 48,26 100,52 158,79 53,24 111,49 174,73 176,65 815,322 870,314 213,-193 377,-338 502,-458 85,-81 151,-151 204,-217 125,-159 165,-297 160,-514 -2,-1 -5,-3 -7,-5 -76,-63 -114,-345 70,-227 16,10 34,23 53,40 16,-113 -64,-267 -457,-423 -42,-17 -87,-33 -136,-50 -8,-2 -31,-7 -67,-12 -335,-52 -1755,-207 -1985,487zm197 -4c654,11 624,298 568,350 -142,99 -322,26 -447,-109 -37,-40 -81,-74 -125,-105 -10,-7 -19,-13 -29,-19 -11,-8 -22,-15 -34,-22 -48,-32 -94,-60 -130,-91 72,-4 137,-5 197,-4z"/>
		   <path className="fil-nose" d="M13917 13018c54,35 108,75 154,124 125,135 305,208 447,109 56,-52 86,-339 -568,-350 -60,-1 -125,0 -197,4 44,38 104,73 164,113z"/>
		   <path className="fil-nose" d="M16283 12836c-111,-21 -74,199 -9,254 3,2 6,4 8,5 0,-4 0,-9 0,-14 0,0 101,-70 116,-178 -49,-42 -86,-62 -115,-67z"/>
		   <path className="clear str_222222_50" d="M11867 11261c0,0 -968,1339 -3319,1037 -243,-11 -1435,-140 -1625,1135 -122,867 152,1626 976,2208 531,346 1242,583 1582,773 342,191 759,503 1095,686 649,351 1666,724 3393,-412 984,-15 1800,63 1812,-4 98,-744 369,-2902 340,-3081"/>
		   <line className="clear str_222222_50" x1="13969" y1="16688" x2="14216" y2= "13793" />
		   <path className="clear str_222222_50" d="M16282 13081c0,0 474,-330 -477,-651 -89,-22 -2040,-333 -2083,649 -6,195 -49,550 824,891 176,65 815,322 870,314 708,-642 879,-750 866,-1203z"/>
		   <path className="clear str_222222_30" d="M13753 12905c83,71 221,132 318,237 125,135 305,208 447,109 61,-56 91,-393 -765,-346z"/>
		   <path className="clear str_222222_30" d="M16282 13095c-88,-53 -134,-408 116,-192"/>
		   <path className="clear str_222222_30_150_150" d="M16251 12802c-41,-62 -286,-257 -579,-279 -687,-53 -1505,-116 -1722,378"/>
		   <path className="clear str_222222_25_125_75" d="M13917 13018c0,0 -207,575 718,788 126,36 717,296 763,279 170,-145 597,-329 841,-915"/>
		   <path className="clear str_222222_50" d="M15941 12480c0,0 -2179,-1710 -2303,-1839 -124,-129 -170,-294 -226,-674 -21,-210 -118,-1054 -744,-989 -717,78 -632,895 -568,1132 172,780 -234,1154 -244,1161"/>
		   <path className="clear str_222222_25_125_125" d="M14364 13989l-223 2520c513,6 1499,15 1499,15 0,0 274,-2093 278,-2698"/>
		   <path className="clear str_222222_30_150_90" d="M11972 11450c0,0 -1114,1299 -3465,997 -242,-11 -1210,-151 -1400,1124 -79,835 190,1410 899,1938 567,371 1100,463 1661,817 703,445 1411,939 2084,991 724,56 1449,-309 2138,-719"/>
		   <path className="fil-nose str_222222_50" d="M15412 16702c0,0 -74,343 93,332 167,-11 142,-56 251,-343"/>
		   <line className="clear str_222222_50" x1="13728" y1="13012" x2="11867" y2= "11261" />
		   <path className="clear str_222222_25_125_75" d="M13870 10837c-409,-178 -572,-409 -591,-741 -25,-422 -114,-962 -572,-909 -605,74 -474,689 -421,912 146,734 -243,1258 -233,1255"/>
		   <line className="clear str_222222_20_40_20" x1="13711" y1="13126" x2="11847" y2= "11365" />
		   <line className="clear str_222222_20_40_20" x1="13704" y1="12859" x2="11946" y2= "11203" />
		   <path className="clear str_222222_20_40_20" d="M15738 12418c-679,-560 -1410,-1116 -2088,-1676"/>
		   <path className="clear str_222222_30" d="M13858 12976c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25" d="M16345 12863c0,0 -45,114 -100,168"/>
		  </g> : <g/>}
		  {config['muzzle-style'] === 'added-default' ? <g id="muzzle-added-default">
		   <path className="clear" d="M8322 12379c-461,-369 -1361,31 -1439,1246 -50,772 397,1655 866,2059 268,232 615,212 761,112 229,-156 360,-442 304,-686 -87,-371 -510,-585 -586,-1017 -109,-623 555,-1345 94,-1714z"/>
		   <path className="clear str_222222_50" d="M8322 12379c461,369 -203,1091 -94,1714 76,432 499,646 586,1017 56,244 -75,530 -304,686 -146,100 -493,119 -761,-112 -469,-404 -916,-1287 -866,-2059 78,-1215 978,-1615 1439,-1246z"/>
		   <path className={"hood-" + config['muzzle-a-color']} d="M8228 14093c-100,-571 449,-1225 187,-1614 -24,-36 -54,-69 -93,-100 -21,-17 -44,-33 -68,-47 -475,-282 -1296,135 -1371,1293 -50,772 397,1655 866,2059 189,163 416,201 585,175 72,-11 133,-34 176,-63 10,-7 20,-14 29,-21 211,-159 329,-432 275,-665 -87,-371 -510,-585 -586,-1017z"/>
		   <path className="clear str_222222_25_125_75" d="M7049 13683c68,-1053 807,-1449 1169,-1132 362,318 -258,738 -164,1486 62,493 498,748 593,1050 64,204 -27,398 -214,512 -124,76 -360,120 -572,-79 -368,-348 -858,-1130 -814,-1799"/>
		  </g> : <g/>}
		  {config['muzzle-style'] === 'fur' || config['muzzle-style'] === 'added-fur' ? <g id="muzzle-fur">
		   <path className={"hood-" + config['muzzle-color']} d="M13975 16542l233 -2726c-529,-290 -497,-572 -492,-739 1,-23 3,-45 6,-66l-1861 -1751c-902,1176 -2979,1458 -5087,608 -15,265 -52,1241 464,1909 -236,55 -434,27 -706,0 154,953 1324,2262 2282,2723 38,25 1272,545 2209,804 1049,291 1550,146 2940,-618l12 -144z"/>
		   <path className={"hood-" + config['muzzle-lip-color']} d="M15410 14278c-55,8 -694,-249 -870,-313 -130,-51 -240,-102 -332,-153l-233 2726 -12 144c129,-2 256,-3 378,-2 429,2 804,18 1063,23 171,4 291,3 342,-9 18,-4 27,-9 28,-16 97,-735 363,-2853 341,-3074 -54,70 -122,138 -203,216 -125,120 -290,265 -502,458z"/>
		   <path className={"hood-" + config['nose-color']} d="M13940 13032c0,-1 0,-1 0,-1 0,0 0,0 0,1zm-29 -20c0,0 0,0 0,1 9,6 19,12 29,18 -10,-6 -19,-13 -29,-19zm-164 -112c-11,33 -20,69 -25,106 -3,22 -5,44 -6,67 -5,166 -37,448 492,739 48,26 100,53 158,79 53,25 111,49 174,74 176,64 815,321 870,313 212,-193 377,-338 502,-458 84,-81 151,-151 203,-217 125,-159 165,-296 161,-513 -3,-2 -5,-4 -8,-6 -76,-63 -113,-345 70,-227 16,10 34,24 54,41 16,-113 -64,-268 -457,-424 -42,-16 -87,-33 -136,-50 -8,-2 -32,-6 -68,-12 -335,-52 -1754,-207 -1984,488zm197 -5c654,12 624,299 568,350 -142,99 -322,27 -447,-108 -37,-41 -81,-75 -125,-105 -10,-7 -19,-14 -29,-20 -11,-8 -22,-15 -34,-22 -48,-31 -94,-60 -130,-90 72,-4 137,-6 197,-5z"/>
		   <path className={"hood-" + config['bridge-color']} d="M12094 10105c127,574 -59,928 -170,1078 -27,36 -50,61 -63,73l1861 1750c5,-37 14,-73 25,-106 230,-695 1649,-540 1984,-488 36,6 60,10 68,12 49,17 94,34 136,50 0,0 -448,-351 -949,-747 -89,-70 -178,-141 -268,-212 -45,-36 -89,-71 -133,-106 -334,-265 -646,-514 -818,-657 -72,-59 -120,-100 -135,-116 -124,-129 -169,-294 -226,-674 -21,-210 -118,-1054 -743,-989 -718,78 -632,895 -569,1132zm707 -56c-171,25 -333,-97 -364,-273 -30,-176 84,-339 254,-364 171,-25 334,97 364,272 30,176 -84,339 -254,365z"/>
		   <path className="fil-nose" d="M13911 13012c54,36 108,75 154,125 125,135 305,207 447,108 56,-51 86,-338 -568,-350 -60,-1 -125,1 -197,5 44,37 104,72 164,112z"/>
		   <path className="fil-nose" d="M16277 12830c-112,-20 -75,199 -9,254 3,2 5,4 8,6 0,-5 0,-10 -1,-14 0,0 102,-71 117,-178 -49,-43 -87,-63 -115,-68z"/>
		   <path className="clear str_222222_50" d="M11861 11256c-902,1176 -2979,1458 -5087,607 -15,265 -52,1242 464,1910 -236,55 -434,27 -706,0 154,953 1324,2262 2282,2723 38,24 1272,545 2209,804 1050,291 1550,145 2940,-618 983,-15 1800,63 1811,-4 98,-744 369,-2901 340,-3080"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <path className="clear str_222222_50" d="M16275 13076c0,0 474,-330 -476,-652 -90,-22 -2040,-332 -2083,649 -6,196 -49,551 824,892 176,64 815,321 870,313 707,-642 878,-750 865,-1202z"/>
		   <path className="clear str_222222_30" d="M13747 12900c83,70 221,132 318,237 125,135 305,207 447,108 61,-56 91,-393 -765,-345z"/>
		   <path className="clear str_222222_30" d="M16276 13090c-88,-53 -135,-408 116,-192"/>
		   <path className="clear str_222222_30_150_150" d="M16245 12797c-41,-63 -287,-257 -580,-280 -686,-53 -1504,-116 -1721,378"/>
		   <path className="clear str_222222_25_125_75" d="M13911 13012c0,0 -207,575 717,788 127,36 718,296 764,279 170,-145 596,-328 841,-915"/>
		   <path className="clear str_222222_50" d="M15935 12474c0,0 -2179,-1710 -2303,-1838 -124,-129 -169,-294 -226,-674 -21,-210 -118,-1053 -743,-989 -718,78 -632,895 -569,1132 173,780 -233,1153 -243,1161"/>
		   <path className="clear str_222222_25_125_125" d="M14358 13983l-223 2520c513,5 1499,15 1499,15 0,0 274,-2093 278,-2698"/>
		   <path className="fil-nose str_222222_50" d="M15406 16696c0,0 -74,343 93,331 167,-11 141,-55 251,-343"/>
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <path className="clear str_222222_25_125_75" d="M13864 10832c-409,-178 -572,-409 -591,-741 -25,-422 -114,-962 -572,-909 -604,74 -474,689 -420,912 145,733 -244,1258 -233,1255"/>
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_20_40_20" d="M15731 12412c-678,-559 -1409,-1115 -2087,-1675"/>
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25" d="M16338 12857c0,0 -44,115 -99,168"/>
		   <path className="clear str_222222_25_125_75" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 3,1162 671,1727 -167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <path className="fil-nose" d="M13911 13012c54,36 108,75 154,125 125,135 305,207 447,108 56,-51 86,-338 -568,-350 -60,-1 -125,1 -197,5 44,37 104,72 164,112z"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <path className="clear str_222222_30" d="M13747 12900c83,70 221,132 318,237 125,135 305,207 447,108 61,-56 91,-393 -765,-345z"/>
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25_125_75" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 3,1162 671,1727 -167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25_125_75" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 3,1162 671,1727 -167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25_125_75" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 3,1162 671,1727 -167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <path className="clear" d="M13975 16542l233 -2726c-529,-290 -497,-572 -492,-739 1,-23 3,-45 6,-66l-1861 -1751c-902,1176 -2979,1458 -5087,608 -15,265 -52,1241 464,1909 -236,55 -434,27 -706,0 154,953 1324,2262 2282,2723 38,25 1272,545 2209,804 1049,291 1550,146 2940,-618l12 -144z"/>
		   <path className="clear" d="M15410 14278c-55,8 -694,-249 -870,-313 -130,-51 -240,-102 -332,-153l-233 2726 -12 144c129,-2 256,-3 378,-2 429,2 804,18 1063,23 171,4 291,3 342,-9 18,-4 27,-9 28,-16 97,-735 363,-2853 341,-3074 -54,70 -122,138 -203,216 -125,120 -290,265 -502,458z"/>
		   <path className="clear" d="M13940 13032c0,-1 0,-1 0,-1 0,0 0,0 0,1zm-29 -20c0,0 0,0 0,1 9,6 19,12 29,18 -10,-6 -19,-13 -29,-19zm-164 -112c-11,33 -20,69 -25,106 -3,22 -5,44 -6,67 -5,166 -37,448 492,739 48,26 100,53 158,79 53,25 111,49 174,74 176,64 815,321 870,313 212,-193 377,-338 502,-458 84,-81 151,-151 203,-217 125,-159 165,-296 161,-513 -3,-2 -5,-4 -8,-6 -76,-63 -113,-345 70,-227 16,10 34,24 54,41 16,-113 -64,-268 -457,-424 -42,-16 -87,-33 -136,-50 -8,-2 -32,-6 -68,-12 -335,-52 -1754,-207 -1984,488zm197 -5c654,12 624,299 568,350 -142,99 -322,27 -447,-108 -37,-41 -81,-75 -125,-105 -10,-7 -19,-14 -29,-20 -11,-8 -22,-15 -34,-22 -48,-31 -94,-60 -130,-90 72,-4 137,-6 197,-5z"/>
		   <path className="clear" d="M12094 10105c127,574 -59,928 -170,1078 -27,36 -50,61 -63,73l1861 1750c5,-37 14,-73 25,-106 230,-695 1649,-540 1984,-488 36,6 60,10 68,12 49,17 94,34 136,50 0,0 -448,-351 -949,-747 -89,-70 -178,-141 -268,-212 -45,-36 -89,-71 -133,-106 -334,-265 -646,-514 -818,-657 -72,-59 -120,-100 -135,-116 -124,-129 -169,-294 -226,-674 -21,-210 -118,-1054 -743,-989 -718,78 -632,895 -569,1132zm707 -56c-171,25 -333,-97 -364,-273 -30,-176 84,-339 254,-364 171,-25 334,97 364,272 30,176 -84,339 -254,365z"/>
		   <path className="clear" d="M13911 13012c54,36 108,75 154,125 125,135 305,207 447,108 56,-51 86,-338 -568,-350 -60,-1 -125,1 -197,5 44,37 104,72 164,112z"/>
		   <path className="clear" d="M16277 12830c-112,-20 -75,199 -9,254 3,2 5,4 8,6 0,-5 0,-10 -1,-14 0,0 102,-71 117,-178 -49,-43 -87,-63 -115,-68z"/>
		   <path className="clear str_222222_25" d="M11861 11256c-902,1176 -2979,1458 -5087,607 -15,265 -52,1242 464,1910 -236,55 -434,27 -706,0 154,953 1324,2262 2282,2723 38,24 1272,545 2209,804 1050,291 1550,145 2940,-618 983,-15 1800,63 1811,-4 98,-744 369,-2901 340,-3080"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <path className="clear str_222222_50" d="M16275 13076c0,0 474,-330 -476,-652 -90,-22 -2040,-332 -2083,649 -6,196 -49,551 824,892 176,64 815,321 870,313 707,-642 878,-750 865,-1202z"/>
		   <path className="clear str_222222_30" d="M13747 12900c83,70 221,132 318,237 125,135 305,207 447,108 61,-56 91,-393 -765,-345z"/>
		   <path className="clear str_222222_30" d="M16276 13090c-88,-53 -135,-408 116,-192"/>
		   <path className="clear str_222222_30_150_150" d="M16245 12797c-41,-63 -287,-257 -580,-280 -686,-53 -1504,-116 -1721,378"/>
		   <path className="clear str_222222_25_125_75" d="M13911 13012c0,0 -207,575 717,788 127,36 718,296 764,279 170,-145 596,-328 841,-915"/>
		   <path className="clear str_222222_50" d="M15935 12474c0,0 -394,-309 -856,-674 -625,-493 -1376,-1090 -1447,-1164 -124,-129 -169,-294 -226,-674 -21,-210 -118,-1053 -743,-989 -718,78 -632,895 -569,1132 173,780 -233,1153 -243,1161"/>
		   <path className="clear str_222222_25_125_125" d="M14358 13983l-223 2520c513,5 1499,15 1499,15 0,0 274,-2093 278,-2698"/>
		   <path className="clear str_222222_50" d="M15406 16696c0,0 -74,343 93,331 167,-11 141,-55 251,-343"/>
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <path className="clear str_222222_25_125_75" d="M13864 10832c-409,-178 -572,-409 -591,-741 -25,-422 -114,-962 -572,-909 -604,74 -474,689 -420,912 145,733 -244,1258 -233,1255"/>
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_20_40_20" d="M15731 12412c-678,-559 -1409,-1115 -2087,-1675"/>
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25" d="M16338 12857c0,0 -44,115 -99,168"/>
		   <path className="clear" d="M13911 13012c54,36 108,75 154,125 125,135 305,207 447,108 56,-51 86,-338 -568,-350 -60,-1 -125,1 -197,5 44,37 104,72 164,112z"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <path className="clear str_222222_30" d="M13747 12900c83,70 221,132 318,237 125,135 305,207 447,108 61,-56 91,-393 -765,-345z"/>
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 -1,1145 668,1711m3 16c-167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear" d="M9561 13966c-630,-279 -930,-1012 -917,-1401 3,-116 12,-195 15,-219 0,-1 0,-3 0,-4 -600,-56 -1236,-210 -1874,-468 0,2 0,3 0,5 -2,37 -5,88 -6,149 -8,384 28,1183 470,1756 -1,0 -2,1 -3,1 -234,54 -432,26 -703,-1 1,1 1,3 1,4 156,953 1324,2259 2281,2719 22,14 451,197 990,398 -100,-86 -193,-178 -280,-274 -922,-1017 -1105,-2458 -1105,-2458 0,0 754,107 1131,-207zm-1322 -779c-47,172 -213,275 -372,229 -159,-45 -250,-222 -203,-394 47,-172 214,-274 372,-229 159,45 250,222 203,394zm-375 1756c-28,-176 82,-334 246,-354 164,-20 320,106 348,282 28,176 -82,335 -246,355 -164,20 -320,-107 -348,-283z"/>
		   <path className="clear" d="M9339 13983c-601,-287 -972,-1400 -859,-1457 -539,-50 -922,-137 -1496,-368 -2,46 -62,-25 -63,22 -7,344 76,1014 473,1529 -1,0 164,163 163,163 -210,49 -560,98 -803,73 149,919 1685,2313 2557,2638 -125,125 -1034,-1444 -965,-2586 0,0 440,193 993,-14z"/>
		   <path className="clear" d="M8660 12339c0,0 -41,1127 901,1627 -329,362 -1131,207 -1131,207 0,0 219,1732 1394,2740"/>
		   <path className="clear" d="M13975 16542l233 -2726c-529,-290 -497,-572 -492,-739 1,-23 3,-45 6,-66l-1861 -1751c-902,1176 -2979,1458 -5087,608 -15,265 -52,1241 464,1909 -236,55 -434,27 -706,0 154,953 1324,2262 2282,2723 38,25 1272,545 2209,804 1049,291 1550,146 2940,-618l12 -144z"/>
		   <path className="clear" d="M15410 14278c-55,8 -694,-249 -870,-313 -130,-51 -240,-102 -332,-153l-233 2726 -12 144c129,-2 256,-3 378,-2 429,2 804,18 1063,23 171,4 291,3 342,-9 18,-4 27,-9 28,-16 97,-735 363,-2853 341,-3074 -54,70 -122,138 -203,216 -125,120 -290,265 -502,458z"/>
		   <path className="clear" d="M13940 13032c0,-1 0,-1 0,-1 0,0 0,0 0,1zm-29 -20c0,0 0,0 0,1 9,6 19,12 29,18 -10,-6 -19,-13 -29,-19zm-164 -112c-11,33 -20,69 -25,106 -3,22 -5,44 -6,67 -5,166 -37,448 492,739 48,26 100,53 158,79 53,25 111,49 174,74 176,64 815,321 870,313 212,-193 377,-338 502,-458 84,-81 151,-151 203,-217 125,-159 165,-296 161,-513 -3,-2 -5,-4 -8,-6 -76,-63 -113,-345 70,-227 16,10 34,24 54,41 16,-113 -64,-268 -457,-424 -42,-16 -87,-33 -136,-50 -8,-2 -32,-6 -68,-12 -335,-52 -1754,-207 -1984,488zm197 -5c654,12 624,299 568,350 -142,99 -322,27 -447,-108 -37,-41 -81,-75 -125,-105 -10,-7 -19,-14 -29,-20 -11,-8 -22,-15 -34,-22 -48,-31 -94,-60 -130,-90 72,-4 137,-6 197,-5z"/>
		   <path className="clear" d="M12094 10105c127,574 -59,928 -170,1078 -27,36 -50,61 -63,73l1861 1750c5,-37 14,-73 25,-106 230,-695 1649,-540 1984,-488 36,6 60,10 68,12 49,17 94,34 136,50 0,0 -448,-351 -949,-747 -89,-70 -178,-141 -268,-212 -45,-36 -89,-71 -133,-106 -334,-265 -646,-514 -818,-657 -72,-59 -120,-100 -135,-116 -124,-129 -169,-294 -226,-674 -21,-210 -118,-1054 -743,-989 -718,78 -632,895 -569,1132zm707 -56c-171,25 -333,-97 -364,-273 -30,-176 84,-339 254,-364 171,-25 334,97 364,272 30,176 -84,339 -254,365z"/>
		   <path className="clear" d="M13911 13012c54,36 108,75 154,125 125,135 305,207 447,108 56,-51 86,-338 -568,-350 -60,-1 -125,1 -197,5 44,37 104,72 164,112z"/>
		   <path className="clear" d="M16277 12830c-112,-20 -75,199 -9,254 3,2 5,4 8,6 0,-5 0,-10 -1,-14 0,0 102,-71 117,-178 -49,-43 -87,-63 -115,-68z"/>
		   <path className="clear str_222222_50" d="M11861 11256c-902,1176 -2979,1458 -5087,607 -15,265 -52,1242 464,1910 -236,55 -434,27 -706,0 154,953 1324,2262 2282,2723 38,24 1272,545 2209,804 1050,291 1550,145 2940,-618 983,-15 1800,63 1811,-4 98,-744 369,-2901 340,-3080"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <path className="clear str_222222_50" d="M16275 13076c0,0 474,-330 -476,-652 -90,-22 -2040,-332 -2083,649 -6,196 -49,551 824,892 176,64 815,321 870,313 707,-642 878,-750 865,-1202z"/>
		   <path className="clear str_222222_30" d="M13747 12900c83,70 221,132 318,237 125,135 305,207 447,108 61,-56 91,-393 -765,-345z"/>
		   <path className="clear str_222222_30" d="M16276 13090c-88,-53 -135,-408 116,-192"/>
		   <path className="clear str_222222_30_150_150" d="M16245 12797c-41,-63 -287,-257 -580,-280 -686,-53 -1504,-116 -1721,378"/>
		   <path className="clear str_222222_25_125_75" d="M13911 13012c0,0 -207,575 717,788 127,36 718,296 764,279 170,-145 596,-328 841,-915"/>
		   <path className="clear str_222222_50" d="M15935 12474c0,0 -2179,-1710 -2303,-1838 -124,-129 -169,-294 -226,-674 -21,-210 -118,-1053 -743,-989 -718,78 -632,895 -569,1132 173,780 -233,1153 -243,1161"/>
		   <path className="clear str_222222_25_125_125" d="M14358 13983l-223 2520c513,5 1499,15 1499,15 0,0 274,-2093 278,-2698"/>
		   <path className="clear str_222222_50" d="M15406 16696c0,0 -74,343 93,331 167,-11 141,-55 251,-343"/>
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <path className="clear str_222222_25_125_75" d="M13864 10832c-409,-178 -572,-409 -591,-741 -25,-422 -114,-962 -572,-909 -604,74 -474,689 -420,912 145,733 -244,1258 -233,1255"/>
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_20_40_20" d="M15731 12412c-678,-559 -1409,-1115 -2087,-1675"/>
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25" d="M16338 12857c0,0 -44,115 -99,168"/>
		   <path className="clear str_222222_25_125_75" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 3,1162 671,1727 -167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <path className="clear" d="M13911 13012c54,36 108,75 154,125 125,135 305,207 447,108 56,-51 86,-338 -568,-350 -60,-1 -125,1 -197,5 44,37 104,72 164,112z"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <path className="clear str_222222_30" d="M13747 12900c83,70 221,132 318,237 125,135 305,207 447,108 61,-56 91,-393 -765,-345z"/>
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25_125_75" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 3,1162 671,1727 -167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25_125_75" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 3,1162 671,1727 -167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25_125_75" d="M11802 11598c-803,1045 -2953,1306 -4829,549 -12,236 3,1162 671,1727 -167,97 -563,148 -805,123 137,848 1267,1975 2120,2385 33,21 1245,541 2079,772 933,258 1701,63 2937,-616"/>
		   <path className="clear" d="M15410 14278c-55,8 -694,-249 -870,-313 -130,-51 -240,-102 -332,-153l-233 2726 -12 144c129,-2 256,-3 378,-2 429,2 804,18 1063,23 171,4 291,3 342,-9 18,-4 27,-9 28,-16 97,-735 363,-2853 341,-3074 -54,70 -122,138 -203,216 -125,120 -290,265 -502,458z"/>
		   <path className="clear" d="M13940 13032c0,-1 0,-1 0,-1 0,0 0,0 0,1zm-29 -20c0,0 0,0 0,1 9,6 19,12 29,18 -10,-6 -19,-13 -29,-19zm-164 -112c-11,33 -20,69 -25,106 -3,22 -5,44 -6,67 -5,166 -37,448 492,739 48,26 100,53 158,79 53,25 111,49 174,74 176,64 815,321 870,313 212,-193 377,-338 502,-458 84,-81 151,-151 203,-217 125,-159 165,-296 161,-513 -3,-2 -5,-4 -8,-6 -76,-63 -113,-345 70,-227 16,10 34,24 54,41 16,-113 -64,-268 -457,-424 -42,-16 -87,-33 -136,-50 -8,-2 -32,-6 -68,-12 -335,-52 -1754,-207 -1984,488zm197 -5c654,12 624,299 568,350 -142,99 -322,27 -447,-108 -37,-41 -81,-75 -125,-105 -10,-7 -19,-14 -29,-20 -11,-8 -22,-15 -34,-22 -48,-31 -94,-60 -130,-90 72,-4 137,-6 197,-5z"/>
		   <path className={"hood-" + config['bridge-color']} d="M12094 10105c127,574 -59,928 -170,1078 -27,36 -50,61 -63,73l1861 1750c5,-37 14,-73 25,-106 230,-695 1649,-540 1984,-488 36,6 60,10 68,12 49,17 94,34 136,50 0,0 -448,-351 -949,-747 -89,-70 -178,-141 -268,-212 -45,-36 -89,-71 -133,-106 -334,-265 -646,-514 -818,-657 -72,-59 -120,-100 -135,-116 -124,-129 -169,-294 -226,-674 -21,-210 -118,-1054 -743,-989 -718,78 -632,895 -569,1132z"/>
		   <path className="clear" d="M13911 13012c54,36 108,75 154,125 125,135 305,207 447,108 56,-51 86,-338 -568,-350 -60,-1 -125,1 -197,5 44,37 104,72 164,112z"/>
		   <path className="clear" d="M16277 12830c-112,-20 -75,199 -9,254 3,2 5,4 8,6 0,-5 0,-10 -1,-14 0,0 102,-71 117,-178 -49,-43 -87,-63 -115,-68z"/>
		   <path className="clear str_222222_25" d="M11861 11256c-902,1176 -2979,1458 -5087,607 -15,265 -52,1242 464,1910 -236,55 -434,27 -706,0 154,953 1324,2262 2282,2723 38,24 1272,545 2209,804 1050,291 1550,145 2940,-618 983,-15 1800,63 1811,-4 98,-744 369,-2901 340,-3080"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <path className="clear str_222222_50" d="M16275 13076c0,0 474,-330 -476,-652 -90,-22 -2040,-332 -2083,649 -6,196 -49,551 824,892 176,64 815,321 870,313 707,-642 878,-750 865,-1202z"/>
		   <path className="clear str_222222_30" d="M13747 12900c83,70 221,132 318,237 125,135 305,207 447,108 61,-56 91,-393 -765,-345z"/>
		   <path className="clear str_222222_30" d="M16276 13090c-88,-53 -135,-408 116,-192"/>
		   <path className="clear str_222222_30_150_150" d="M16245 12797c-41,-63 -287,-257 -580,-280 -686,-53 -1504,-116 -1721,378"/>
		   <path className="clear str_222222_25_125_75" d="M13911 13012c0,0 -207,575 717,788 127,36 718,296 764,279 170,-145 596,-328 841,-915"/>
		   <path className="clear str_222222_50" d="M15935 12474c0,0 -394,-309 -856,-674 -625,-493 -1376,-1090 -1447,-1164 -124,-129 -169,-294 -226,-674 -21,-210 -118,-1053 -743,-989 -718,78 -632,895 -569,1132 173,780 -233,1153 -243,1161"/>
		   <path className="clear str_222222_25_125_125" d="M14358 13983l-223 2520c513,5 1499,15 1499,15 0,0 274,-2093 278,-2698"/>
		   <path className="clear str_222222_50" d="M15406 16696c0,0 -74,343 93,331 167,-11 141,-55 251,-343"/>
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <path className="clear str_222222_25_125_75" d="M13864 10832c-409,-178 -572,-409 -591,-741 -25,-422 -114,-962 -572,-909 -604,74 -474,689 -420,912 145,733 -244,1258 -233,1255"/>
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_20_40_20" d="M15731 12412c-678,-559 -1409,-1115 -2087,-1675"/>
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <path className="clear str_222222_25" d="M16338 12857c0,0 -44,115 -99,168"/>
		   <path className="clear" d="M13911 13012c54,36 108,75 154,125 125,135 305,207 447,108 56,-51 86,-338 -568,-350 -60,-1 -125,1 -197,5 44,37 104,72 164,112z"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <path className="clear str_222222_30" d="M13747 12900c83,70 221,132 318,237 125,135 305,207 447,108 61,-56 91,-393 -765,-345z"/>
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		   <line className="clear str_222222_50" x1="13963" y1="16682" x2="14210" y2= "13787" />
		   <line className="clear str_222222_50" x1="13722" y1="13006" x2="11861" y2= "11256" />
		   <line className="clear str_222222_20_40_20" x1="13705" y1="13120" x2="11841" y2= "11359" />
		   <line className="clear str_222222_20_40_20" x1="13698" y1="12854" x2="11940" y2= "11198" />
		   <path className="clear str_222222_30" d="M13852 12970c59,50 156,93 224,167 87,95 214,146 314,76 43,-39 64,-276 -538,-243z"/>
		  </g> : <g/>}
		  {config['muzzle-style'] === 'added-fur' ? <g id="muzzle-added-fur">
		   <path className="clear" d="M9548 13955c-629,-279 -928,-1010 -915,-1397 3,-117 12,-196 15,-219 0,-1 0,-3 0,-4 -599,-56 -1232,-210 -1869,-466 0,1 0,3 0,4 -2,37 -4,88 -6,149 -8,383 28,1179 469,1751 -1,0 -2,0 -3,1 -233,54 -431,26 -700,-1 0,1 0,2 0,4 156,950 1321,2252 2275,2711 22,14 449,196 987,397 -100,-86 -193,-178 -280,-273 -919,-1015 -1101,-2451 -1101,-2451 0,0 752,106 1128,-206zm-1319 -778c-47,172 -213,274 -371,229 -158,-45 -249,-221 -202,-393 47,-171 213,-274 371,-228 158,45 249,221 202,392zm-374 1752c-28,-176 82,-334 246,-354 163,-20 319,106 347,282 28,175 -82,333 -246,353 -164,20 -319,-106 -347,-281z"/>
		   <path className="clear str_222222_25_125_75" d="M9326 13971c-599,-286 -970,-1395 -856,-1452 -538,-51 -920,-137 -1492,-367 -2,46 -62,-25 -63,21 -7,343 76,1012 472,1525 -1,0 163,163 162,163 -210,48 -559,97 -801,73 149,916 1681,2305 2550,2630 -124,125 -1031,-1440 -962,-2579 0,0 439,193 990,-14z"/>
		   <path className="clear str_222222_50" d="M8649 12332c0,0 -41,1124 899,1623 -329,360 -1128,206 -1128,206 0,0 218,1727 1389,2732"/>
		   <path className={"hood-" + config['muzzle-a-color']} d="M9548 13955c-629,-279 -910,-1144 -898,-1531 4,-117 -5,-62 -2,-85 0,-1 0,-3 0,-4 -599,-56 -1232,-210 -1869,-466 0,1 0,3 0,4 -2,37 -4,88 -6,149 -8,383 28,1179 469,1751 -1,0 -2,0 -3,1 -233,54 -431,26 -700,-1 0,1 0,2 0,4 156,950 1321,2252 2275,2711 22,14 449,196 987,397 -100,-86 -193,-178 -280,-273 -919,-1015 -1101,-2451 -1101,-2451 0,0 752,106 1128,-206z"/>
		   <path className="clear str_222222_25_125_75" d="M9326 13971c-599,-286 -970,-1395 -856,-1452 -538,-51 -920,-137 -1492,-367 -2,46 -62,-25 -63,21 -7,343 76,1012 472,1525 -1,0 163,163 162,163 -210,48 -559,97 -801,73 149,916 1681,2305 2550,2630 -124,125 -1057,-1419 -988,-2558 0,0 465,172 1016,-35z"/>
		   <path className="clear str_222222_50" d="M8649 12332c0,0 -41,1124 899,1623 -329,360 -1128,206 -1128,206 0,0 218,1727 1389,2732"/>
		   <path className="clear str_222222_50" d="M8681 12328c-612,-53 -1261,-207 -1913,-470 -14,264 -51,1237 463,1904 -235,55 -432,27 -703,0 152,950 1319,2256 2275,2715 21,13 456,197 1001,401"/>
		  </g> : <g/>}
		  {config['eyebrow-style'] === 'default' ? <g id="eyebrow-default">
		   <path className={"hood-" + config['brow-color']} d="M15252 9078c33,-245 78,-809 -171,-1069l-11 -11c-202,71 -1521,441 -1529,1038 14,168 82,440 392,460 222,-3 386,-192 560,-268 183,-113 448,-219 553,-186 105,32 132,80 187,154 0,0 9,-45 19,-118z"/>
		   <path className={"hood-" + config['brow-color']} d="M9432 8183c-1518,-53 -2036,551 -1860,1107 177,556 817,-12 1976,68 1159,81 1944,618 2200,26 119,-275 3,-601 -367,-806 -428,-236 -1165,-368 -1949,-395z"/>
		   <path className="clear str_222222_50" d="M9432 8183c784,27 1521,159 1949,395 370,205 486,531 367,806 -256,592 -1041,55 -2200,-26 -1159,-80 -1799,488 -1976,-68 -176,-556 342,-1160 1860,-1107z"/>
		   <path className="clear str_222222_25_125_75" d="M9514 8384c716,19 1265,135 1655,302 338,145 524,430 416,623 -234,418 -1007,-69 -2065,-125 -1058,-57 -1664,351 -1777,4 -114,-346 331,-843 1716,-806"/>
		   <path className={"hood-" + config['brow-color'] + " str_222222_50"} d="M15233 9196c0,0 175,-868 -163,-1198 -202,71 -1521,441 -1529,1038 14,168 82,440 392,460 222,-3 386,-192 560,-268 183,-113 448,-219 553,-186 105,32 132,80 187,154z"/>
		   <path className="clear str_222222_25_125_75" d="M15141 8098c-187,67 -1444,387 -1452,940 -2,143 52,258 249,292 217,24 391,-175 552,-246 170,-105 475,-177 572,-147 97,30 139,72 190,141"/>
		  </g> : <g/>}
		  {config['eyebrow-style'] === 'frame' ? <g id="eyebrow-frame">
		   <path className="clear str_332C2B_25_50_75" d="M11607 9466l-381 220c0,0 -2092,-349 -2185,-399 -93,-50 -636,-389 -871,-852 272,32 440,80 440,80 0,0 -230,-387 -341,-806 227,14 816,284 1488,734 -55,-192 -136,-558 -117,-640 293,112 917,457 1967,1663z"/>
		   <path className={"hood-" + config['brow-color'] + " str_222222_50"} d="M13564 9468l444 97c0,0 1125,-484 1169,-532 43,-48 268,-438 237,-785 -176,65 -259,100 -259,100 0,0 4,-186 -95,-483 -152,46 -370,120 -647,529 -43,-137 -124,-250 -171,-302 -156,122 -457,402 -678,1376z"/>
		   <path className="clear str_332C2B_25_50_75" d="M13688 9414l294 70c0,0 1077,-473 1113,-513 36,-40 229,-299 203,-585 -145,54 -224,103 -224,103 0,0 -2,-244 -84,-489 -126,38 -369,265 -598,603 -35,-113 -116,-325 -154,-368 -129,101 -367,375 -550,1179z"/>
		   <path className={"hood-" + config['brow-color'] + " str_222222_50"} d="M11786 9505l-581 319c0,0 -2177,-349 -2290,-410 -113,-60 -829,-594 -1114,-1156 330,39 490,63 490,63 0,0 -186,-294 -320,-801 276,17 679,54 1494,599 -67,-233 -53,-443 -31,-542 356,136 1080,466 2352,1928z"/>
		   <path className="clear str_332C2B_25_50_75" d="M11546 9466l-381 219c0,0 -2092,-349 -2185,-399 -93,-49 -636,-389 -871,-852 272,32 440,81 440,81 0,0 -230,-388 -341,-806 227,13 816,283 1488,733 -55,-192 -136,-558 -117,-640 293,112 917,457 1967,1664z"/>
		   <path className={"hood-" + config['brow-color'] + " str_222222_50"} d="M13564 9468l444 97c0,0 1125,-484 1169,-532 43,-48 268,-438 237,-785 -176,65 -259,100 -259,100 0,0 4,-186 -95,-483 -152,46 -370,120 -647,529 -43,-137 -124,-250 -171,-302 -156,122 -457,402 -678,1376z"/>
		   <path className="clear str_332C2B_25_50_75" d="M13688 9414l294 70c0,0 1077,-473 1113,-513 36,-40 229,-299 203,-585 -145,54 -224,103 -224,103 0,0 -2,-244 -84,-489 -126,38 -369,265 -598,603 -35,-113 -116,-325 -154,-368 -129,101 -367,375 -550,1179z"/>
		  </g> : <g/>}
		  {config['eyebrow-style'] === 'dou' ? <g id="eyebrow-dou">
		   <path className={"hood-" + config['brow-color'] + " str_332C2B_50"} d="M10875 8056c551,307 884,950 706,1304 -177,355 -839,523 -1483,249 -645,-274 -835,-809 -621,-1238 215,-429 847,-621 1398,-315z"/>
		   <path className="clear str_332C2B_20_40_60" d="M10838 8142c492,274 790,847 632,1164 -159,317 -750,467 -1326,222 -575,-245 -745,-723 -553,-1106 191,-382 756,-554 1247,-280z"/>
		   <path className={"hood-" + config['brow-color'] + " str_332C2B_50"} d="M13988 8240c-339,328 -381,893 -169,1116 212,222 716,200 1097,-143 380,-344 230,-828 11,-1073 -218,-245 -600,-227 -939,100z"/>
		   <path className="clear str_332C2B_20_40_60" d="M14034 8295c-275,310 -340,798 -151,996 190,199 640,179 980,-127 340,-307 265,-697 36,-937 -229,-240 -590,-242 -865,68z"/>
		  </g> : <g/>}
		  {config['muzzle-assoicate'] === 'default' && config['spikes-style'] === 'default' ? <g id="muzzle-assoicate-default">
		   <g>
		    <path className="fil-back" d="M13460 14360c54,0 98,43 98,97 0,54 -44,97 -98,97 -53,0 -97,-43 -97,-97 0,-54 44,-97 97,-97zm270 97c0,-149 -121,-270 -270,-270 -148,0 -269,121 -269,270 0,149 121,270 269,270 149,0 270,-121 270,-270z"/>
		    <path className="fil-7F7F7F" d="M13558 14457c0,-54 -44,-97 -98,-97 -53,0 -97,43 -97,97 0,54 44,97 97,97 54,0 98,-43 98,-97z"/>
		    <path className="fil-FEFEFE" d="M13550 14550c9,-15 18,9 -9,34 -27,25 -82,19 -97,13 -14,-5 -8,-12 30,-11 37,0 67,-21 76,-36z"/>
		    <path className="fil-FEFEFE" d="M13279 14391c-7,43 -31,57 -16,-28 15,-85 154,-156 251,-119 97,38 4,19 -61,17 -36,-1 -86,18 -123,50 -28,25 -47,61 -51,80z"/>
		   </g>
		   <g>
		    <path className="fil-back" d="M13345 15590c54,0 97,43 97,97 0,54 -43,97 -97,97 -53,0 -97,-43 -97,-97 0,-54 44,-97 97,-97zm270 97c0,-149 -121,-270 -270,-270 -149,0 -269,121 -269,270 0,149 120,270 269,270 149,0 270,-121 270,-270z"/>
		    <path className="fil-7F7F7F" d="M13442 15687c0,-54 -43,-97 -97,-97 -53,0 -97,43 -97,97 0,54 44,97 97,97 54,0 97,-43 97,-97z"/>
		    <path className="fil-FEFEFE" d="M13435 15780c9,-15 18,9 -9,34 -27,25 -82,19 -97,13 -15,-5 -8,-12 29,-11 38,0 68,-21 77,-36z"/>
		    <path className="fil-FEFEFE" d="M13164 15621c-8,43 -31,57 -16,-28 15,-85 154,-156 250,-119 97,38 4,19 -60,17 -36,-1 -86,18 -123,50 -28,25 -48,61 -51,80z"/>
		   </g>
		   <g>
		    <path className="fil-back" d="M12530 14893c54,0 97,44 97,98 0,53 -43,97 -97,97 -54,0 -97,-44 -97,-97 0,-54 43,-98 97,-98zm270 98c0,-149 -121,-270 -270,-270 -149,0 -270,121 -270,270 0,149 121,269 270,269 149,0 270,-120 270,-269z"/>
		    <path className="fil-7F7F7F" d="M12627 14991c0,-54 -43,-98 -97,-98 -54,0 -97,44 -97,98 0,53 43,97 97,97 54,0 97,-44 97,-97z"/>
		    <path className="fil-FEFEFE" d="M12620 15083c9,-14 18,10 -9,35 -27,25 -82,19 -97,13 -15,-5 -8,-12 29,-12 38,1 68,-21 77,-36z"/>
		    <path className="fil-FEFEFE" d="M12349 14924c-8,43 -31,58 -16,-27 15,-86 153,-157 250,-119 97,38 4,19 -60,17 -37,-1 -87,18 -123,50 -28,24 -48,60 -51,79z"/>
		   </g>
		  </g> : <g/>}
		  {config['muzzle-assoicate'] === 'fur' ? <g id="muzzle-assoicate-fur">
		   <path className={"hood-" + config['muzzle-b-color'] + " str_222222_50"} d="M10871 17251c379,187 836,270 1388,176 493,-85 1062,-312 1717,-733l246 -2876c-530,-291 -498,-574 -493,-741 1,-23 3,-45 6,-66l-908 -855c-19,20 -40,42 -65,65 -317,306 -1107,895 -2500,920 163,309 195,1135 2427,1538 -594,122 -1900,549 -3531,13 125,389 538,1978 1713,2559z"/>
		   <path className="clear str_222222_50" d="M12829 12154c-261,275 -1063,960 -2567,987 163,309 195,1135 2427,1538 -594,122 -1900,549 -3531,13 202,626 1147,4358 4818,2002"/>
		   <path className="clear str_222222_25_125_75" d="M12879 12269c-265,284 -1052,983 -2411,1006 142,282 301,895 2611,1376 -894,387 -2330,657 -3735,249 352,1268 1436,3712 4620,1625"/>
		  </g> : <g/>}
		  {config['muzzle-assoicate'] === 'mustache' ? <g id="muzzle-assoicate-mustache">
		   <path className={"hood-" + config['muzzle-b-color']} d="M13730 14021c0,0 -732,-6 -2411,-518 0,0 1187,1009 2341,1188 13,-309 70,-670 70,-670z"/>
		   <path className={"hood-" + config['muzzle-b-color']} d="M13607 15530c0,0 -730,-13 -2400,-541 0,0 1174,1019 2323,1209 17,-308 77,-668 77,-668z"/>
		   <g>
		    <path className="clear str_222222_35" d="M11319 13503c0,0 1187,1009 2341,1188 13,-309 70,-670 70,-670 0,0 -732,-6 -2411,-518z"/>
		    <path className="clear str_222222_25_125_75" d="M10900 13294c0,0 1501,1341 2850,1549 16,-360 79,-917 79,-917 0,0 -965,-34 -2929,-632z"/>
		   </g>
		   <path className="clear str_222222_35" d="M11207 14989c0,0 1174,1019 2323,1209 17,-308 77,-668 77,-668 0,0 -730,-13 -2400,-541z"/>
		   <path className="clear str_222222_25_125_75" d="M10791 14776c0,0 1483,1353 2827,1575 20,-360 88,-914 88,-914 0,0 -962,-44 -2915,-661z"/>
		  </g> : <g/>}
		  <g id="button">
		   <ellipse className={"hood-" + config['bridge-button'] + " str-button"} transform="matrix(2.66462 -0.39548 0.315412 1.83425 12749.8 9647.09)" rx="120" ry="179"/>
		   <path className="fil-button-light" d="M12969 9575c4,-51 34,45 28,134 -7,88 -89,174 -121,175 -33,1 -47,-21 20,-91 68,-70 69,-167 73,-218z"/>
		   <path className="fil-button-light" d="M12506 9595c-12,36 -14,-34 7,-95 21,-62 93,-114 115,-111 22,2 27,19 -32,61 -58,43 -78,110 -90,145z"/>
		   <ellipse className={"hood-" + config['muzzle-button'] + " str-button"} transform="matrix(2.47937 0.709943 -0.488471 1.7958 7785.67 13121.4)" rx="120" ry="179"/>
		   <path className="fil-button-light" d="M8006 13144c25,-45 11,55 -32,132 -42,78 -149,123 -177,111 -29,-12 -32,-38 56,-74 87,-37 129,-125 153,-169z"/>
		   <path className="fil-button-light" d="M7595 12976c-25,27 3,-37 46,-84 44,-47 128,-66 146,-55 18,11 16,28 -53,44 -68,15 -113,69 -139,95z"/>
		   <ellipse className={"hood-" + config['muzzle-button'] + " str-button"} transform="matrix(2.56495 -0.312346 0.293123 1.83538 8001.96 14983.4)" rx="120" ry="179"/>
		   <path className="fil-button-light" d="M8213 14918c4,-50 32,46 26,134 -7,88 -86,171 -117,171 -31,0 -45,-23 20,-90 65,-68 67,-164 71,-215z"/>
		   <path className="fil-button-light" d="M7768 14925c-12,34 -14,-36 7,-96 21,-60 90,-110 111,-107 21,3 26,20 -30,61 -57,40 -76,107 -88,142z"/>
		  </g>
		  {config['bridge-button'] === 'metal' ? <g id="bridge-button-silver">
		   <path fill="#E62629" d="M12664 9318c176,-26 344,100 376,282 31,181 -87,350 -263,376 -176,26 -344,-100 -375,-282 -32,-181 86,-350 262,-376z"/>
		   <g style={{clipPath:"url(#id0)"}}>
		    <g>
		     <rect fill="url(#id3)" x="12377" y="9273" width="686" height="747"/>
		    </g>
		   </g>
		   <path class="clear str_222222_8" d="M12664 9318c176,-26 344,100 376,282 31,181 -87,350 -263,376 -176,26 -344,-100 -375,-282 -32,-181 86,-350 262,-376z"/>
		   <path fill="#FEFEFE" d="M12940 9575c4,-51 34,45 27,134 -6,88 -88,174 -121,175 -32,1 -46,-21 21,-91 67,-70 69,-167 73,-218z"/>
		   <path fill="#FEFEFE" d="M12477 9595c-13,36 -14,-34 7,-95 21,-62 92,-114 114,-111 22,2 28,19 -31,61 -58,43 -78,110 -90,145z"/>
		   </g> : <g/>}
		  {config['muzzle-button'] === 'metal' ? <g id="muzzle-button-silver">
		   <path fill="#E62629" d="M7844 12799c164,47 258,230 209,407 -48,178 -220,284 -384,237 -164,-47 -258,-229 -209,-407 48,-177 220,-283 384,-237z"/>
		   <g style={{clipPath:"url(#id1)"}}>
		    <g>
		     <rect fill="url(#id4)" x="7413" y="12748" width="686" height="747"/>
		    </g>
		   </g>
		   <path class="clear str_222222_8" d="M7844 12799c164,47 258,230 209,407 -48,178 -220,284 -384,237 -164,-47 -258,-229 -209,-407 48,-177 220,-283 384,-237z"/>
		   <path fill="#FEFEFE" d="M7977 13144c25,-45 10,55 -32,132 -42,78 -149,123 -178,111 -28,-12 -31,-38 56,-74 88,-37 129,-125 154,-169z"/>
		   <path fill="#FEFEFE" d="M7566 12976c-25,27 2,-37 46,-84 44,-47 128,-66 146,-55 18,11 16,28 -53,44 -69,15 -114,69 -139,95z"/>
		   <path fill="#E62629" d="M7920 14654c170,-20 331,110 360,292 29,182 -85,346 -255,366 -169,21 -330,-110 -359,-291 -29,-182 85,-346 254,-367z"/>
		   <g style={{clipPath:"url(#id2)"}}>
		    <g>
		     <rect fill="url(#id5)" x="7630" y="14610" width="686" height="747"/>
		    </g>
		   </g>
		   <path class="clear str_222222_8" d="M7920 14654c170,-20 331,110 360,292 29,182 -85,346 -255,366 -169,21 -330,-110 -359,-291 -29,-182 85,-346 254,-367z"/>
		   <path fill="#FEFEFE" d="M8184 14918c4,-50 32,46 26,134 -7,88 -86,171 -118,171 -31,0 -44,-23 21,-90 65,-68 67,-164 71,-215z"/>
		   <path fill="#FEFEFE" d="M7739 14925c-13,34 -14,-36 7,-96 20,-60 90,-110 111,-107 21,3 26,20 -31,61 -56,40 -75,107 -87,142z"/>
		  </g> : <g/>}
		  {config['glass-hole'] ? <g id="glass-hole">
		   <path className="fil-eye" d="M5770 9958l-22 484c-2,41 13,109 38,144 -8,-1 -16,-4 -24,-9 -39,-24 -69,-83 -67,-131l23 -515c2,-48 36,-68 75,-44 12,7 24,18 33,31 -30,-22 -54,-7 -56,40z"/>
		   <path className="clear str_332C2B_20" d="M5793 9887l0 0c-39,-24 -73,-4 -75,44l-23 515c-2,48 28,107 67,131l0 0c39,24 73,4 75,-44l23 -515c2,-48 -28,-107 -67,-131z"/>
		   <path className="fil-eye str_332C2B_20" d="M5827 9918c-31,-22 -55,-7 -57,40l-22 484c-2,42 14,112 39,146"/>
		   <path className="fil-eye str_332C2B_20" d="M5770 9958c2,-47 26,-62 56,-40 22,29 36,67 34,100l-23 515c-2,38 -23,58 -51,53 -25,-35 -40,-103 -38,-144l22 -484z"/>
		  </g> : <g/>}
		  {config['ear-hole'] ? <g id="ear-hole">
		   <g>
		    <path className="fil-eye-border" d="M4902 11732c-4,-73 15,-141 44,-150 29,-9 56,43 61,117 3,51 -5,99 -21,126 19,-25 29,-68 26,-117 -5,-81 -44,-149 -87,-152 -44,-3 -74,60 -69,141 5,82 44,150 87,153 3,0 5,0 8,-1 -24,-6 -45,-53 -49,-117z"/>
		    <path className="fil-eye" d="M4947 11582c-31,9 -53,76 -48,150 4,64 26,111 52,117 15,-1 28,-10 38,-23 17,-28 26,-76 23,-127 -4,-74 -34,-126 -65,-117z"/>
		    <path className="clear str_332C2B_20" d="M4963 11846c-29,9 -58,-41 -62,-114 -5,-74 16,-141 45,-150 8,-3 18,-2 26,4"/>
		    <circle className="clear str_332C2B_20" transform="matrix(0.335373 0.0223557 0.0392503 0.630909 4933.74 11702.7)" r="233"/>
		   </g>
		   <g>
		    <path className="fil-eye-border" d="M4955 12584c-5,-74 15,-141 44,-151 29,-9 56,43 61,117 3,51 -5,99 -21,127 19,-26 29,-68 26,-118 -5,-81 -44,-149 -87,-152 -44,-3 -74,61 -69,142 5,81 44,149 87,152 3,0 5,0 8,0 -24,-6 -45,-54 -49,-117z"/>
		    <path className="fil-eye" d="M5000 12433c-31,10 -53,77 -48,150 3,64 26,112 52,118 15,-2 28,-10 38,-24 17,-28 26,-76 23,-127 -4,-73 -34,-126 -65,-117z"/>
		    <path className="clear str_332C2B_20" d="M5016 12697c-29,9 -58,-40 -63,-114 -4,-74 17,-140 46,-150 8,-2 18,-2 26,5"/>
		    <circle className="clear str_332C2B_20" transform="matrix(0.335373 0.0223557 0.0392503 0.630909 4986.7 12554)" r="233"/>
		   </g>
		   <g>
		    <path className="fil-eye-border" d="M4717 12163c-5,-74 15,-141 44,-150 29,-10 56,42 61,116 3,51 -6,99 -21,127 18,-25 29,-68 25,-118 -5,-81 -44,-149 -87,-152 -43,-3 -74,61 -69,142 5,81 44,149 88,152 2,0 5,0 8,0 -25,-6 -45,-54 -49,-117z"/>
		    <path className="fil-eye" d="M4762 12013c-32,9 -53,76 -49,150 4,63 26,111 53,117 14,-2 27,-10 38,-24 17,-28 26,-75 23,-127 -5,-73 -34,-126 -65,-116z"/>
		    <path className="clear str_332C2B_20" d="M4778 12276c-29,9 -58,-40 -63,-114 -4,-73 17,-140 46,-149 8,-3 18,-3 25,4"/>
		    <circle className="clear str_332C2B_20" transform="matrix(0.335373 0.0223557 0.0392503 0.630909 4748.39 12133.1)" r="233"/>
		   </g>
		   <g>
		    <path className="fil-eye-border" d="M5103 12139c-5,-74 15,-141 44,-150 29,-10 56,42 61,116 3,51 -6,99 -21,127 18,-25 29,-68 25,-118 -5,-81 -44,-149 -87,-152 -43,-3 -74,61 -69,142 5,81 44,149 88,152 2,0 5,0 8,0 -25,-6 -45,-54 -49,-117z"/>
		    <path className="fil-eye" d="M5148 11989c-32,9 -53,76 -49,150 4,63 26,111 53,117 14,-2 27,-10 38,-24 16,-28 26,-75 23,-127 -5,-73 -34,-126 -65,-116z"/>
		    <path className="clear str_332C2B_20" d="M5164 12252c-29,9 -58,-40 -63,-114 -4,-73 17,-140 46,-149 8,-3 18,-3 25,4"/>
		    <circle className="clear str_332C2B_20" transform="matrix(0.335373 0.0223557 0.0392503 0.630909 5134.37 12109.1)" r="233"/>
		   </g>
		  </g> : <g/>}
		  { config['muzzle-assoicate'] === 'default' && config['spikes-style'] === 'spikes1' ? <g>
		   <path className="fil-7F7F7F str_332C2B_50" d="M11987 14104c348,320 789,719 789,719 0,0 145,421 76,457 -69,35 -422,137 -611,24 -190,-112 -254,-1200 -254,-1200z"/>
 		   <path className="fil-7F7F7F str_332C2B_50" d="M12848 14678l610 48c34,3 68,5 101,-5 113,-35 167,-168 120,-298 -47,-129 -177,-206 -290,-172 -19,6 -36,15 -51,26 -2,1 -3,2 -5,3l-22 18 -463 380z"/>
		   <path className="fil-7F7F7F str_332C2B_50" d="M12818 15689l507 166c27,10 56,18 86,15 101,-12 168,-132 150,-269 -18,-136 -114,-237 -215,-225 -17,2 -33,8 -48,15 -1,1 -2,2 -4,3l-21 13 -455 282z"/>
		  </g> : <g/>}
		  { config['muzzle-assoicate'] === 'default' && config['spikes-style'] === 'spikes2' ? <g>
		   <path className="fil-7F7F7F str_332C2B_50" d="M12848 14678l610 48c34,3 68,5 101,-5 113,-35 167,-168 120,-298 -47,-129 -177,-206 -290,-172 -19,6 -36,15 -51,26 -2,1 -3,2 -5,3l-22 18 -463 380z"/>
 		   <path className="fil-7F7F7F str_332C2B_50" d="M12738 15818l610 48c34,3 68,5 101,-5 113,-35 167,-168 120,-298 -47,-129 -177,-206 -290,-172 -19,6 -36,15 -51,26 -2,1 -3,2 -5,3l-22 18 -463 380z"/>
		   <path className="fil-7F7F7F str_332C2B_50" d="M11918 15240l611 48c33,4 67,5 100,-5 113,-35 167,-168 120,-297 -47,-130 -177,-207 -290,-172 -19,6 -36,15 -51,25 -2,2 -3,3 -5,4l-21 18 -464 379z"/>
		  </g> : <g/>}
		 </g>
		</svg>
	);
}

export default Hood;