import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import SquareIcon from '@mui/icons-material/Square';
import Typography from '@mui/material/Typography';
import {colorList} from './ConfigCode'

import texture1 from './texture1.jpg'
import texture2 from './texture2.jpg'
import texture_black from './texture-black.jpg'

const colorMap = {
  "red": {
    "color": "#E90924",
    "display_name": "红色"
  },
  "orange": {
    "color": "#FF6512",
    "display_name": "橙色"
  },
  "yellow": {
    "color": "#FBEF12",
    "display_name": "黄色"
  },
  "green": {
    "color": "#76F720",
    "display_name": "绿色"
  },
  "tiffany": {
    "color": "#0BD4AC",
    "display_name": "蒂芙尼蓝"
  },
  "light-blue": {
    "color": "#169DE0",
    "display_name": "蓝色"
  },
  "blue": {
    "color": "#2C33C7",
    "display_name": "宝蓝色"
  },
  "brown": {
    "color": "#4F321F",
    "display_name": "褐色"
  },
  "pupple": {
    "color": "#863CB5",
    "display_name": "紫色"
  },
  "pink": {
    "color": "#F9CDDA",
    "display_name": "粉色"
  },
  "white": {
    "color": "#FFFFFF",
    "display_name": "白色"
  },
  "gray": {
    "color": "#7D7D7D",
    "display_name": "灰色"
  },
  "black": {
    "color": "#212121",
    "display_name": "黑色"
  },
  "texture-black": {
    "color": "#333333",
    "image": texture_black,
    "display_name": "压纹黑(+20R)"
  },
  "texture-1": {
    "color": "#8C7D5C",
    "image": texture1,
    "display_name": "迷彩黄"
  },
  "texture-2": {
    "color": "#B4B4B6",
    "image": texture2,
    "display_name": "迷彩灰"
  },
  "metal": {
    "color": "#666666",
    "display_name": "金属色"
  },
  "dark-green": {
    "color": "#205C50",
    "display_name": "深绿"
  },
  "dark-blue": {
    "color": "#253856",
    "display_name": "藏蓝"
  },
  "dark-gray": {
    "color": "#575757",
    "display_name": "深灰"
  },
  "rose-red": {
    "color": "#E34496",
    "display_name": "玫红"
  }
}

const ColorSelect = ({ setSelectedColor, defaultColor, ignore=["metal", "dark-gray"], description, textOverwrite={} }) => {
  
  const [open, setOpen] = React.useState(false);

  const handleSelectButtonClick = (color) => {
    setSelectedColor(color);
    setOpen(false);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <div>
      <Button variant="text" aria-label={textOverwrite[defaultColor] || colorMap[defaultColor].display_name} size="large" onClick={handleClickOpen} style={{ color: 'black' }} startIcon={
        colorMap[defaultColor].image?
        <img alt='color-block' src={colorMap[defaultColor].image} style={{ padding: '3px', height: '18px', width: '18px', border: '1px solid rgba(0, 0, 0, 0.12)'}}/>:
        <SquareIcon fontSize="inherit" style={{ color: colorMap[defaultColor].color, border: '1px solid rgba(0, 0, 0, 0.12)' }}/>
      }>
        <Typography variant="subtitle1">{textOverwrite[defaultColor] || colorMap[defaultColor].display_name}</Typography>
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>请选择颜色</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {colorList.filter(item => !ignore.includes(item)).map((colorItem) => (
              <IconButton key={colorItem} aria-label={textOverwrite[defaultColor] || colorMap[colorItem].display_name} size="large" onClick={() => handleSelectButtonClick(colorItem)}>
                {
                  colorMap[colorItem].image?
                  <img alt='color-block' src={colorMap[colorItem].image} style={{ padding: '3px', height: '22px', width: '22px', border: '1px solid rgba(0, 0, 0, 0.12)'}}/>:
                  <SquareIcon fontSize="inherit" style={{ color: colorMap[colorItem].color, border: '1px solid rgba(0, 0, 0, 0.12)' }}/>
                }
              </IconButton>
            ))}
          </Box>
        </DialogContent>
        {description ? <Typography variant="subtitle2" align="center">{description}</Typography> : <div/>}
        <DialogActions>
          <Button onClick={handleClose}>关闭</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ColorBlock = ({ desp, color, textOverwrite={"texture-black": "压纹黑"} }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', padding: '0px', direction: 'row', justifyContent: 'flex-start', width: '100%' }}>
        <div>{desp}&nbsp;:&nbsp;</div>
        <div>{textOverwrite[color] || colorMap[color].display_name}&nbsp;</div>
        {
          colorMap[color].image?
          <img alt='color-block' src={colorMap[color].image} style={{ padding: '3px', height: '18px', width: '18px', border: '1px solid rgba(0, 0, 0, 0.12)'}}/>:
          <SquareIcon fontSize="inherit" style={{ color: colorMap[color].color, border: '1px solid rgba(0, 0, 0, 0.12)' }}/>  
        }
      </div>)
}

export { ColorSelect, colorMap, ColorBlock };
