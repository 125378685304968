import React, { useState, useEffect } from 'react';
import { Button, Typography, Container, Divider, Drawer } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {ColorSelect, ColorBlock} from './ColorSelect'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Hood from './Hood'
import ItemPicker from './ItemPicker'
import qrcode from './qrcode.png'
import SaveLoadPopUp from './SaveLoadPopUp'
import {defaultConfig, decode, encode} from './ConfigCode'

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import LOGO from './logo.png'
import MuzzleDefault from './muzzle-default.png'
import MuzzleFur from './muzzle-fur.png'

import EyeDefault from './eye-default.png'
import EyeDou from './eye-dou.png'
import EyeFrame from './eye-frame.png'

import AddedMuzzleDefault from './added-muzzle-default.png'
import AddedMuzzleFur from './added-muzzle-fur.png'

import MuzzleAssoicateDefault from './muzzle-assoicate-default.png'
import MuzzleAssoicateMustache from './muzzle-assoicate-mustache.png'
import MuzzleAssoicateFur from './muzzle-assoicate-fur.png'

import FurMuzzleAssoicateDefault from './fur-muzzle-assoicate-default.png'
import FurMuzzleAssoicateMustache from './fur-muzzle-assoicate-mustache.png'
import FurMuzzleAssoicateFur from './fur-muzzle-assoicate-fur.png'

import SpikesStyleAFur from './spikes-style-a-fur.png'
import SpikesStyleBFur from './spikes-style-b-fur.png'
import SpikesStyleAMuzzle from './spikes-style-a-muzzle.png'
import SpikesStyleBMuzzle from './spikes-style-b-muzzle.png'

import Ear from './ear.png'

function App() {
  const queryParameters = new URLSearchParams(window.location.search)
  const code = queryParameters.get("code")

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [printMode, setPrintMode] = useState(false);
  const [config, setConfig] = useState(code ? decode(code) : defaultConfig);

  useEffect(() => {
    let lastPressTime = 0;

    const handleKeyDown = (event) => {
      if (event.key === 'p') {
        const currentTime = Date.now();
        if (currentTime - lastPressTime < 300) { // Double press within 300ms
          setPrintMode((prevMode) => !prevMode);
        }
        lastPressTime = currentTime;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleResetToDefault = () => {
    changeHistory(defaultConfig);
    setConfig(defaultConfig);
  };

  const handleSelectColor = (key, color) => {
    var newConfig = {...config}
    newConfig[key] = color
    changeHistory(newConfig);
    setConfig(newConfig);
  };

  const handleSizeChange = (event) => {
    var newConfig = {...config}
    newConfig['hood-size'] = event.target.value;
    changeHistory(newConfig);
    setConfig(newConfig);
  };

  const handleCheckChange = (key, checked) => {
    var newConfig = {...config}
    newConfig[key] = checked
    changeHistory(newConfig);
    setConfig(newConfig);
  };

  const handleEyeBrowStyleChange = (style) => {
    var newConfig = {...config}
    newConfig['eyebrow-style'] = style;
    changeHistory(newConfig);
    setConfig(newConfig);
  };

  const handleSpikesStyleChange = (style) => {
    var newConfig = {...config}
    newConfig['spikes-style'] = style;
    changeHistory(newConfig);
    setConfig(newConfig);
  }

  const handleMuzzleStyleChange = (style) => {
    var newConfig = {...config}
    if (newConfig['muzzle-style'].startsWith('added')) {
      newConfig['muzzle-style'] = 'added-' + style;
    } else {
      newConfig['muzzle-style'] = style;
    }
    changeHistory(newConfig);
    setConfig(newConfig);
  };

  const handleMuzzleAddedChange = (added) => {
    var newConfig = {...config}
    if (added) {
      newConfig['muzzle-style'] = 'added-' + newConfig['muzzle-style'];
    } else {
      newConfig['muzzle-style'] = newConfig['muzzle-style'].replace('added-', '');
    }
    changeHistory(newConfig);
    setConfig(newConfig);
  };

  const handleMuzzleAssoicateChange = (style) => {
    var newConfig = {...config}
    newConfig['muzzle-assoicate'] = style;
    changeHistory(newConfig);
    setConfig(newConfig);
  }

  const changeHistory = (config) => {
    queryParameters.set('code', encode(config));
    window.history.replaceState(null, '', "?" + queryParameters.toString());
  }

  if (printMode) {
    return (
      <Container style={{height: '822px', width: '595px', marginTop: '20px', overflow: 'hidden'}}>
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
          <Divider variant="middle">RULER定制款头套工艺单</Divider>
          </Grid2>
          <Grid2 xs={4}>
            客户姓名: {config['customer-name']}
          </Grid2>
          <Grid2 xs={3}>
            编号: {config['customer-id']}
          </Grid2>
          <Grid2 xs={2}>
            尺码: {config["hood-size"]}
          </Grid2>
          <Grid2 xs={3}>
            日期： {new Date().toLocaleDateString()}
          </Grid2>
          <Grid2 xs={5}>
            <Stack spacing={2}>
              <ColorBlock
                desp="主体面部"
                color={config["hood-base-color"]}
              />
              <ColorBlock
                desp="头盖"
                color={config["crown-color"]}
              />
              <ColorBlock
                desp="眉毛"
                color={config["brow-color"]}
              />
              <ColorBlock
                desp="鼻梁"
                color={config["bridge-color"]}
              />
              <ColorBlock
                desp="鼻头"
                color={config["nose-color"]}
              />
              <ColorBlock
                desp="脸部"
                color={config["muzzle-color"]}
              />
              <ColorBlock
                desp="嘴唇"
                color={config["muzzle-lip-color"]}
              />
              <ColorBlock
                desp="下巴"
                color={config["chin-color"]}
              />
              <ColorBlock
                desp="脸部A"
                color={config["muzzle-a-color"]}
              />
              <ColorBlock
                desp="脸部B"
                color={config["muzzle-b-color"]}
              />
              <ColorBlock
                desp="耳朵A"
                color={config["ear-a-color"]}
              />
              <ColorBlock
                desp="耳朵B"
                color={config["ear-b-color"]}
              />
              <ColorBlock
                desp="耳朵C"
                color={config["ear-c-color"]}
              />
              <ColorBlock
                desp="鼻梁纽扣"
                color={config["bridge-button"]}
              />
              <ColorBlock
                desp="脸颊纽扣"
                color={config["muzzle-button"]}
              />
              <div style={{ display: 'flex', alignItems: 'center', padding: '0px', direction: 'row', justifyContent: 'flex-start', width: '100%' }}>
                <div>加耳朵孔&nbsp;:&nbsp;</div><Checkbox checked={config["ear-hole"]} style={{padding:"0px"}}/>  
              </div>
              <div style={{ display: 'flex', alignItems: 'center', padding: '0px', direction: 'row', justifyContent: 'flex-start', width: '100%' }}>
                <div>加眼镜孔&nbsp;:&nbsp;</div><Checkbox checked={config["glass-hole"]} style={{padding:"0px"}}/>  
              </div>
            </Stack>
          </Grid2>
          <Grid2 xs={7}>
            <Hood config={config} height="500px"/>
          </Grid2>
        </Grid2>
      </Container>
    )
  }
  return (
    <Container style={{
      backgroundImage: `url(${LOGO})`,
      backgroundSize: '100% auto',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'Center' }}>
      {/* Menu Icon */}
      <Box component="section" sx={{
        position: 'absolute',
        top: 20,
        right: 20,
        textAlign: 'right'
      }}>
        尺码: {config["hood-size"]}
        <br />
        黑色材质: {config["black-type-light"]? "光皮": "布面"}
      </Box>
      <Fab size="large" color="primary" sx={{
        position: 'absolute',
        bottom: 20,
        right: 20
      }} aria-label="menu" onClick={handleDrawerOpen}>
        <EditIcon />
      </Fab>
      <Hood config={config}/>
      <Paper variant='outlined' style={{ height: 90, width: 90}} sx={{
        position: 'absolute',
        bottom: 20,
        left: 20
      }}>
        <img alt="qrcode" src={qrcode} style={{ height: '100%', width: '100%' }}/>
      </Paper>
      {/* Drawer */}

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        {/* Add your drawer content here */}
        <div style={{ width: '320px' }}>
          <Typography align="center" variant="h6" style={{ margin: '10px' }}>
            RULER
          </Typography>
          <Divider variant="middle">尺寸</Divider>
          <List>
            <ListItem>
            <Typography variant="subtitle1" style={{ margin: '10px', width: '80px' }}>
              尺寸
            </Typography>
            <FormControl fullWidth>
              <Select
                style={{width: '180px'}}
                value={config['hood-size']}
                onChange={handleSizeChange}
              >
                <MenuItem value="M">M (56-58cm)</MenuItem>
                <MenuItem value="L">L (58-60cm)</MenuItem>
                <MenuItem value="XL">XL (60-62cm)</MenuItem>
              </Select>
            </FormControl>
            </ListItem>
          </List>
          <Divider variant="middle">主体</Divider>
          <List>
            <ListItem>
            <Typography variant="subtitle1" style={{ margin: '10px' }}>
              头套主体面部
            </Typography>
            <ColorSelect
              defaultColor={config["hood-base-color"]}
              setSelectedColor={(color) => {
                handleSelectColor("hood-base-color", color)
              }}
              ignore={["texture-1", "texture-2", "metal", "dark-green", "rose-red"]}
              textOverwrite={{
                "red": "红色(+50R)",
                "orange": "橙色(+50R)",
                "yellow": "黄色(+50R)",
                "green": "绿色(+50R)",
                "tiffany": "蒂芙尼蓝(+50R)",
                "light-blue": "蓝色(+50R)",
                "blue": "宝蓝色(+50R)",
                "brown": "褐色(+50R)",
                "pupple": "紫色(+50R)",
                "pink": "粉色(+50R)",
                "white": "白色(+50R)",
                "gray": "灰色(+50R)",
                "dark-blue": "藏蓝(+50R)",
                "dark-gray": "深灰(+50R)"
              }}
            />
            </ListItem>
            <ListItem>
            <Typography variant="subtitle1" style={{ margin: '10px' }}>
              头盖
            </Typography>
            <ColorSelect
              defaultColor={config["crown-color"]}
              setSelectedColor={(color) => {
                handleSelectColor("crown-color", color)
              }}
              ignore={["texture-1", "texture-2", "metal", "dark-green", "rose-red"]}
            />
            </ListItem>
          </List>
          <Divider variant="middle">眉毛</Divider>
          <List>
            <ListItem>
              <Box sx={{ flexGrow: 1 }}>
                <Grid2 container spacing={2}>
                  <Grid2 xs={4}>
                    <ItemPicker text="双眉" selected={config['eyebrow-style'] === 'default'} image={EyeDefault} onClick={() => {handleEyeBrowStyleChange('default');}}/>
                  </Grid2>
                  <Grid2 xs={4}>
                    <ItemPicker text="豆豆(+40R)" selected={config['eyebrow-style'] === 'dou'} image={EyeDou} onClick={() => {handleEyeBrowStyleChange('dou')}}/>
                  </Grid2>
                  <Grid2 xs={4}>
                    <ItemPicker text="焰眉(+40R)" selected={config['eyebrow-style'] === 'frame'} image={EyeFrame} onClick={() => {handleEyeBrowStyleChange('frame')}}/>
                  </Grid2>
                </Grid2>
              </Box>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                眉毛
              </Typography>
              <ColorSelect
                defaultColor={config["brow-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("brow-color", color)
              }}/>
            </ListItem>
          </List>
          <Divider variant="middle">面部</Divider>
          <List>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                鼻梁
              </Typography>
              <ColorSelect
                defaultColor={config["bridge-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("bridge-color", color)
                }}/>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                鼻头
              </Typography>
              <ColorSelect
                defaultColor={config["nose-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("nose-color", color)
                }}
                textOverwrite={{"texture-black": "压纹黑(+10R)"}}
              />
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                脸部
              </Typography>
              <ColorSelect
                defaultColor={config["muzzle-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("muzzle-color", color)
              }}/>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                嘴唇
              </Typography>
              <ColorSelect
                defaultColor={config["muzzle-lip-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("muzzle-lip-color", color)
              }}/>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                下巴
              </Typography>
              <ColorSelect
                defaultColor={config["chin-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("chin-color", color)
                }}
                ignore={["texture-1", "texture-2", "metal", "rose-red"]}
              />
            </ListItem>
          </List>
          <Divider variant="middle">脸部细节</Divider>
          <List>
            <ListItem>
              <Box sx={{ flexGrow: 1 }}>
                <Grid2 container spacing={2}>
                  <Grid2 xs={4}>
                    <ItemPicker selected={config['muzzle-style'] === 'default' || config['muzzle-style'] === 'added-default'} text="圆脸" image={MuzzleDefault} onClick={() => {handleMuzzleStyleChange('default');}}/>
                  </Grid2>
                  <Grid2 xs={4}>
                    <ItemPicker selected={config['muzzle-style'] === 'fur' || config['muzzle-style'] === 'added-fur'} text="FUR(+40R)" image={MuzzleFur} onClick={() => {handleMuzzleStyleChange('fur');}}/>
                  </Grid2>
                </Grid2>
              </Box>
            </ListItem>
            <ListItem>
              <Box sx={{ flexGrow: 1 }}>
                <Grid2 container spacing={2}>
                  <Grid2 xs={4}>
                    <ItemPicker selected={config['muzzle-style'].startsWith('added')} text="脸颊(+40R)" image={config['muzzle-style'] === 'default' || config['muzzle-style'] === 'added-default'? AddedMuzzleDefault : AddedMuzzleFur } onClick={() => {handleMuzzleAddedChange(!config['muzzle-style'].startsWith('added'));}}/>
                  </Grid2>
                </Grid2>
              </Box>
            </ListItem>
            <ListItem>
              <Box sx={{ flexGrow: 1 }}>
                <Grid2 container spacing={2}>
                  <Grid2 xs={4}>
                    <ItemPicker selected={config['muzzle-assoicate'] === 'default'} text="三孔" image={config['muzzle-style'] === 'default' || config['muzzle-style'] === 'added-default'? MuzzleAssoicateDefault : FurMuzzleAssoicateDefault} onClick={() => {handleMuzzleAssoicateChange('default');}}/>
                  </Grid2>
                  <Grid2 xs={4}>
                    <ItemPicker selected={config['muzzle-assoicate'] === 'mustache'} text="胡须(+40R)" image={config['muzzle-style'] === 'default' || config['muzzle-style'] === 'added-default'? MuzzleAssoicateMustache : FurMuzzleAssoicateMustache} onClick={() => {handleMuzzleAssoicateChange('mustache');}}/>
                  </Grid2>
                  <Grid2 xs={4}>
                    <ItemPicker selected={config['muzzle-assoicate'] === 'fur'} text="皮毛(+40R)" image={config['muzzle-style'] === 'default' || config['muzzle-style'] === 'added-default'? MuzzleAssoicateFur : FurMuzzleAssoicateFur} onClick={() => {handleMuzzleAssoicateChange('fur');}}/>
                  </Grid2>
                </Grid2>
              </Box>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                A
              </Typography>
              <ColorSelect
                defaultColor={config["muzzle-a-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("muzzle-a-color", color)
              }}/>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                B
              </Typography>
              <ColorSelect
                defaultColor={config["muzzle-b-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("muzzle-b-color", color)
              }}/>
            </ListItem>
            {
              config['muzzle-assoicate'] === 'default' ?
            <ListItem>
              <Box sx={{ flexGrow: 1 }}>
                <Grid2 container spacing={2}>
                  <Grid2 xs={4}>
                    <ItemPicker selected={config["spikes-style"] === "spikes1"} text="柳丁A(+20R)" image={config['muzzle-style'] === 'default' || config['muzzle-style'] === 'added-default'? SpikesStyleAMuzzle : SpikesStyleAFur} onClick={() => {handleSpikesStyleChange(config["spikes-style"] === "spikes1" ? "default": "spikes1");}}/>
                  </Grid2>
                  <Grid2 xs={4}>
                    <ItemPicker selected={config["spikes-style"] === "spikes2"} text="柳丁B(+20R)" image={config['muzzle-style'] === 'default' || config['muzzle-style'] === 'added-default'? SpikesStyleBMuzzle : SpikesStyleBFur} onClick={() => {handleSpikesStyleChange(config["spikes-style"] === "spikes2" ? "default": "spikes2");}}/>
                  </Grid2>
                </Grid2>
              </Box>
            </ListItem> : <ListItem/>
          }
          </List>
          <Divider variant="middle">耳朵细节</Divider>
          <List>
            <ListItem>
              <Box sx={{ flexGrow: 1 }}>
                <Grid2 container spacing={2}>
                  <Grid2 xs={4}>
                    <ItemPicker selected text="耳朵细节" image={Ear}/>
                  </Grid2>
                </Grid2>
              </Box>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                A
              </Typography>
              <ColorSelect
                defaultColor={config["ear-a-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("ear-a-color", color)
              }}/>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                B
              </Typography>
              <ColorSelect
                defaultColor={config["ear-b-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("ear-b-color", color)
              }}/>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                C
              </Typography>
              <ColorSelect
                defaultColor={config["ear-c-color"]}
                setSelectedColor={(color) => {
                  handleSelectColor("ear-c-color", color)
              }}/>
            </ListItem>
          </List>
          <Divider variant="middle">其他</Divider>
          <List>
            <ListItem>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={config["ear-hole"]} onChange={(event) => {
                  handleCheckChange("ear-hole", event.target.checked);
              }} />} label="加耳朵孔(+10R)" />
              </FormGroup>
            </ListItem>
            <ListItem>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={config["glass-hole"]} onChange={(event) => {
                  handleCheckChange("glass-hole", event.target.checked);
              }} />} label="加眼镜孔(+10R)" />
              </FormGroup>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                鼻梁纽扣
              </Typography>
              <ColorSelect
                defaultColor={config["bridge-button"]}
                setSelectedColor={(color) => {
                  handleSelectColor("bridge-button", color)
              }}
                ignore={["texture-black", "texture-1", "texture-2", "tiffany", "brown", "dark-green", "dark-blue"]}
                description="彩色扣:+10R / 金属扣:+20R (金属扣只推荐布面)"
              />
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                脸颊纽扣
              </Typography>
              <ColorSelect
                defaultColor={config["muzzle-button"]}
                setSelectedColor={(color) => {
                  handleSelectColor("muzzle-button", color)
              }}
                ignore={["texture-black", "texture-1", "texture-2", "tiffany", "brown", "dark-green", "dark-blue"]}
                description="彩色扣:+10R / 金属扣:+20R (金属扣只推荐布面)"
              />
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" style={{ margin: '10px' }}>
                黑色材质
              </Typography>
              <FormGroup>
                  <FormControlLabel control={<Checkbox checked={config["black-type-light"]} onChange={(event) => {
                    handleCheckChange("black-type-light", event.target.checked);
                }} />} label="光皮" />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={!config["black-type-light"]} onChange={(event) => {
                    handleCheckChange("black-type-light", !event.target.checked);
                }} />} label="布面" />
              </FormGroup>
            </ListItem>
          </List>
        </div>
        <div style={{ width: '300px', display: 'flex', justifyContent: 'space-around', margin: '20px' }}>
          <Button variant="outlined" onClick={handleDrawerClose} startIcon={<CloseIcon />}>
            关闭
          </Button>
          <SaveLoadPopUp config={config} setConfig={setConfig} />
          <Button variant="outlined" onClick={handleResetToDefault} startIcon={<DeleteIcon />} color="error" style={{}}>
            重置
          </Button>
        </div>
        <div style={{ width: '300px', display: 'flex', justifyContent: 'space-around', margin: '20px' }}>
          <Typography variant="caption" style={{ margin: '10px', color: '#BBBBBB' }}>
            Developed by <a style={{color: '#BBBBBB'}} href='https://twitter.com/pup_bobo'>@pup_bobo</a>. All rights reserved.
          </Typography>
        </div>
      </Drawer>
    </Container>
  );
}

export default App;
